import * as React from 'react';
import {observer} from 'mobx-react';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {ManageScreenPresenter} from './manage_presenter';
import Header from '../components/header';
import {Edition} from '../../../../models/edition';
import Loading from '../../components/loading';
import {Grid, Select} from '@material-ui/core';
import Content from '../components/content';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TeamManage from './team_manage/team_manage';
import Button from '@material-ui/core/Button';
import {Heat} from '../../../../models/heat';
import {Link} from 'react-router-dom';
import {genders} from '../../../../models/participant';
import {TeamsManagementInteractor, TeamState} from '../../../business/manage/teams_management_interactor';

interface OwnProps {
    edition: Edition;
}

@observer
class ManageScreen extends React.Component<OwnProps & PresenterProps<ManageScreenPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        const teamsSorted = this.props.presenter.teamStates.sort(this.props.presenter.teamOrder);
        const teamPairs: Array<{before?: TeamState; team: TeamState}> = [];
        let previous: TeamState | undefined;
        teamsSorted.forEach(team => {
            if (previous !== undefined && previous.heat === team.heat) {
                teamPairs.push({before: previous, team: team});
            } else {
                teamPairs.push({before: undefined, team: team});
            }
            previous = team;
        });
        const teamTriples: Array<{before?: TeamState; team: TeamState; after?: TeamState}> = [];
        previous = undefined;
        teamPairs.reverse().forEach(value => {
            if (previous !== undefined && previous.heat === value.team.heat) {
                teamTriples.push({before: value.before, team: value.team, after: previous});
            } else {
                teamTriples.push({before: value.before, team: value.team, after: undefined});
            }
            previous = value.team;
        });
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Manage',
                            link: '/edition/manage',
                        },
                    ]}>
                    Assign heat and number
                </Header>
                <Content>
                    <Button
                        onClick={() => {
                            this.props.presenter.reset();
                        }}>
                        Reset
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.presenter.autoAssignHeat();
                        }}>
                        Auto-assign heat
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.presenter.autoAssignNumber();
                        }}>
                        Auto-assign number
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.presenter.saveLocalState();
                        }}>
                        Save
                    </Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="dense" />
                                <TableCell>Heat</TableCell>
                                <TableCell>Number</TableCell>
                                <TableCell>Club</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Info</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamTriples.reverse().map(triple => {
                                const {before, team, after} = triple;
                                return (
                                    <TeamManage
                                        key={team.team.id}
                                        team={team.team}
                                        edition={this.props.edition}
                                        localHeat={team.heat ? team.heat : null}
                                        localNumber={team.number}
                                        localTags={team.tags.map(tag => tag.name)}
                                        remarks={team.remarks}
                                        isFirstInHeat={before === undefined}
                                        isLastInHeat={after === undefined}
                                        firstParticipant={this.props.presenter.getFirstParticipant(team.team)}
                                        moveUp={() => {
                                            if (before !== undefined) {
                                                const tmp = before.number;
                                                before.number = team.number;
                                                team.number = tmp;
                                                this.props.presenter.runValidation();
                                            }
                                        }}
                                        moveDown={() => {
                                            if (after !== undefined) {
                                                const tmp = after.number;
                                                after.number = team.number;
                                                team.number = tmp;
                                                this.props.presenter.runValidation();
                                            }
                                        }}
                                        setHeat={heatId => {
                                            const heat: Heat | undefined = this.props.presenter.getHeatWithId(heatId);
                                            team.heat = heat === undefined ? null : heat;
                                            team.changed = true;
                                            this.props.presenter.runValidation();
                                        }}
                                        setNumber={number => {
                                            team.number = number;
                                            this.props.presenter.runValidation();
                                        }}
                                        setTags={tags => {
                                            team.tags = tags.map(tag => ({name: tag}));
                                            this.props.presenter.runValidation();
                                        }}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </Content>
                <Content>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Born</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Club</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <p>Totaal {this.props.presenter.participants.length} deelnemers</p>
                            {this.props.presenter.participants
                                .sort(this.props.presenter.participantOrder)
                                .map(participant => {
                                    return (
                                        <TableRow
                                            key={participant.id}
                                            style={{
                                                backgroundColor: participant.gender === 'Unknown' ? 'red' : 'white',
                                            }}>
                                            <TableCell>{participant.name}</TableCell>
                                            <TableCell>
                                                <Select
                                                    native
                                                    value={participant.gender}
                                                    onChange={(e: any) =>
                                                        this.props.presenter.onGenderChange(participant, e.target.value)
                                                    }>
                                                    {genders.map(gender => {
                                                        return (
                                                            <option key={gender} value={gender}>
                                                                {gender}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                            </TableCell>
                                            <TableCell>{participant.birthYear}</TableCell>
                                            <TableCell>{participant.membershipCode}</TableCell>
                                            <TableCell>{participant.clubCode}</TableCell>
                                            <TableCell>
                                                <Link to={`/edition/participant/${participant.id}`}>Edit</Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </Content>
            </Grid>
        );
    }
}

export default withPresenter<ManageScreenPresenter, OwnProps>(
    (props, component) =>
        new ManageScreenPresenter(
            props.edition,
            component.persistence.teamRepository,
            component.persistence.heatRepository,
            component.persistence.participantRepository,
            new TeamsManagementInteractor(props.edition.mode),
        ),
    ManageScreen,
);
