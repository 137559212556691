import * as React from 'react';
import {Button, Theme, WithStyles} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar/AppBar';
import withStyles from '@material-ui/core/styles/withStyles';
import {Heat} from '../../../../models/heat';
import {usePresenter} from '../../support/use_presenter';
import {RecordTimeRequest} from '../../../tracking/models/record_time_request';
import {RecordedAs} from '../../../../models/recorded_time';
import Typography from '@material-ui/core/Typography/Typography';
import {useObservable} from '../../support/hooks/use_observable';
import {distinctUntilChanged, map} from 'rxjs/operators';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';

interface OwnProps {
    heat: Heat;
}

const styles = (theme: Theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        height: 56,
    },
});

function LooseBarStyled({heat, classes}: OwnProps & WithStyles<'appBar'>) {
    const context: RecordTimeRequest = {
        recordedAs: RecordedAs.Loose,
        heatId: heat.id,
        teams: [],
    };

    const presenter = usePresenter(component => ({
        numRecordedTimes: component
            .trackingController(heat.id)
            .allTimes()
            .pipe(
                map(recordedTimes => recordedTimes.length),
                distinctUntilChanged(),
            ),
        recordTime: () => component.trackingController(heat.id).recordTime(context),
    }));

    const numRecordedTimes = useObservable<number | null>(presenter.numRecordedTimes, null);

    return (
        <>
            <AppBar position="fixed" color={'secondary'} className={classes.appBar}>
                <Toolbar style={{height: 56}}>
                    {numRecordedTimes !== null ? (
                        <Typography variant="overline" color="inherit" style={{flex: 0.8}}>
                            # recordings <em>{numRecordedTimes}</em>
                        </Typography>
                    ) : null}
                    <Button
                        variant="fab"
                        color="primary"
                        style={{
                            position: 'fixed',
                            bottom: 30,
                            right: 20,
                            zIndex: 9999,
                        }}
                        onClick={presenter.recordTime}>
                        <AddIcon />
                    </Button>
                </Toolbar>
            </AppBar>
        </>
    );
}

export const LooseBar = withStyles(styles)(LooseBarStyled);
