import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {ViewParticipantPresenter} from './participants_view_presenter';
import Header from '../../components/header';
import Grid from '@material-ui/core/Grid';
import Content from '../../components/content';
import {observer} from 'mobx-react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Loading from '../../../components/loading';
import {genders} from '../../../../../models/participant';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';

interface OwnProps {
    participantId: string;
}

@observer
class ViewParticipantScreen extends React.Component<OwnProps & PresenterProps<ViewParticipantPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        if (this.props.presenter.participant === null || this.props.presenter.participant === undefined) {
            //TODO view fancy 404
            return <div>Could not find participant</div>;
        }
        if (this.props.presenter.team === null || this.props.presenter.team === undefined) {
            //TODO view fancy 404
            return <div>Could not find team</div>;
        }
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Teams',
                            link: '/edition/teams',
                        },
                        {
                            name: 'Team: ' + this.props.presenter.team.name,
                            link: '/edition/team/' + this.props.presenter.team.id,
                        },
                        {
                            name: 'View participant',
                            link: '/edition/participant/' + this.props.presenter.participant.id,
                        },
                    ]}>
                    View participant
                </Header>
                <Content>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            id="name"
                            value={this.props.presenter.name}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="birth-year">Birth year</InputLabel>
                        <Input
                            id="birth-year"
                            value={this.props.presenter.birthYear}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onBirthYearChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="membership-code">Membership code</InputLabel>
                        <Input
                            id="membership-code"
                            value={this.props.presenter.membershipCode}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onMembershipCodeChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="club-code">Club code</InputLabel>
                        <Input
                            id="club-code"
                            value={this.props.presenter.clubCode}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onClubCodeChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="gender">Gender</InputLabel>
                        <Select
                            native
                            value={this.props.presenter.gender}
                            onChange={(e: any) => this.props.presenter.onGenderChange(e.target.value)}
                            input={<Input id="gender" />}>
                            {genders.map(gender => {
                                return (
                                    <option key={gender} value={gender}>
                                        {gender}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Grid justify="flex-end" container>
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={this.props.presenter.submitting}
                                style={{marginTop: 16}}
                                onClick={() => this.props.presenter.onSubmitClicked()}>
                                {this.props.presenter.submitting ? 'Saving..' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Content>
            </Grid>
        );
    }
}

export default withPresenter<ViewParticipantPresenter, OwnProps>(
    (props, component) =>
        new ViewParticipantPresenter(
            props.participantId,
            component.persistence.teamRepository,
            component.persistence.participantRepository,
        ),
    ViewParticipantScreen,
);
