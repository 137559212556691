import {Category} from '../../../models/team';

const handicaps = {
    '1x-': 1,
    '2-': 1.039,
    '2x-': 1.076,
    '4-': 1.122,
    '4+': 1.094,
    '4x+': 1.13,
    'C4x+': 1.076,
    '8+': 1.206,
    '8x+': 1.244,
    '2+': 0.985,
    '4x-': 1.164,
    'C4+': 1.035,
    'C2x+': 0.921,
    C1x: 0.9,
    'C2x-': 0.964,
};

export default function getBoatTypeHandicap(category: Category): number {
    switch (category) {
        case 'H 1x-':
        case 'D 1x-':
        case 'J18 1x-':
        case 'M18 1x-':
            return handicaps['1x-'];
        case 'H 2-':
        case 'D 2-':
        case 'MX 2-':
        case 'J18 2-':
        case 'M18 2-':
        case 'MX18 2-':
            return handicaps['2-'];
        case 'H 2x-':
        case 'D 2x-':
        case 'MX 2x-':
        case 'J18 2x-':
        case 'M18 2x-':
        case 'MX18 2x-':
        case 'J16 2x-':
        case 'M16 2x-':
            return handicaps['2x-'];
        case 'H 4x+':
        case 'D 4x+':
        case 'MX 4x+':
        case 'J18 4x+':
        case 'M18 4x+':
        case 'MX18 4x+':
        case 'J16 4x+':
        case 'M16 4x+':
            return handicaps['4x+'];
        case 'H 4+':
        case 'D 4+':
        case 'MX 4+':
        case 'J18 4+':
        case 'M18 4+':
        case 'MX18 4+':
            return handicaps['4-'];
        case 'H 4-':
        case 'D 4-':
        case 'MX 4-':
        case 'J18 4-':
        case 'M18 4-':
        case 'MX18 4-':
            return handicaps['4-'];
        case 'H 4x-':
        case 'D 4x-':
        case 'MX 4x-':
        case 'J18 4x-':
        case 'M18 4x-':
        case 'MX18 4x-':
            return handicaps['4x-'];
        case 'H 8x+':
        case 'D 8x+':
        case 'MX 8x+':
        case 'J18 8x+':
        case 'M18 8x+':
        case 'MX18 8x+':
            return handicaps['8x+'];
        case 'H 8+':
        case 'D 8+':
        case 'MX 8+':
        case 'J18 8+':
        case 'M18 8+':
        case 'MX18 8+':
            return handicaps['8+'];
        case 'H C2x+':
        case 'D C2x+':
        case 'MX C2x+':
        case 'J18 C2x+':
        case 'M18 C2x+':
        case 'MX18 C2x+':
            return handicaps['C2x+'];
        case 'H C2x-':
        case 'D C2x-':
        case 'MX C2x-':
        case 'J18 C2x-':
        case 'M18 C2x-':
        case 'MX18 C2x-':
            return handicaps['C2x-'];
        case 'H C4x+':
        case 'D C4x+':
        case 'MX C4x+':
        case 'J18 C4x+':
        case 'M18 C4x+':
        case 'MX18 C4x+':
            return handicaps['C4x+'];
        case 'H C4+':
        case 'D C4+':
        case 'MX C4+':
        case 'J18 C4+':
        case 'M18 C4+':
        case 'MX18 C4+':
            return handicaps['C4+'];
    }
}
