import {distinctUntilChanged, scan} from 'rxjs/operators';
import {ServerTime, ServerTimeInteractor} from './server_time_interactor';
import {Observable} from 'rxjs';

export class WeightedServerTimeInteractor implements ServerTimeInteractor {
    private _delegate: ServerTimeInteractor;

    constructor(delegate: ServerTimeInteractor) {
        this._delegate = delegate;
    }

    public serverTimes(): Observable<ServerTime> {
        return this._delegate.serverTimes().pipe(
            scan((bestOffset, newOffset) => {
                if (bestOffset.queryDurationMillis > newOffset.queryDurationMillis) {
                    return newOffset;
                } else if (bestOffset.queryDurationMillis === newOffset.queryDurationMillis) {
                    return {
                        offsetMillis:
                            (bestOffset.offsetMillis * bestOffset.weight + newOffset.offsetMillis * newOffset.weight) /
                            (bestOffset.weight + newOffset.weight),
                        queryDurationMillis: bestOffset.queryDurationMillis,
                        weight: bestOffset.weight + newOffset.weight,
                    } as ServerTime;
                } else {
                    return bestOffset;
                }
            }),
            distinctUntilChanged((a, b) => {
                return a.queryDurationMillis === b.queryDurationMillis && a.offsetMillis === b.offsetMillis;
            }),
        );
    }
}
