export enum RegattaMode {
    // Types
    Head = 'head', //Start in sequence
    Sprint = 'sprint', //Start side by side

    // Presets
    TWW = 'TWW',
    OIJR = 'OIJR',
}

export interface Edition {
    id: string;
    token: string;
    name: string;
    handicapsSystem: string;
    mode: RegattaMode;
}
