import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router';
import {Edition} from '../../../models/edition';
import Heats from './heats/heats/heats';
import Teams from './teams/teams/teams';
import AddHeatScreen from './heats/add/heats_add';
import AddTeamScreen from './teams/add/teams_add';
import AddParticipantScreen from './participants/add/participants_add';
import ViewHeatScreen from './heats/view/heats_view';
import EditHeatScreen from './heats/edit/heats_edit';
import ViewTeamScreen from './teams/view/teams_view';
import ViewParticipantScreen from './participants/view/participants_view';
import ImportScreen from './import/import';
import ManageTimesScreen from './times/manage_times';
import InschrijfTafelPrint from './prints/inschrijf_tafel/inschrijf_tafel';
import StartlijstPrint from './prints/startlijst/startlijst';
import InschrijvingenPrint from './prints/inschrijvingen/inschrijvingen';
import ControleStartPrint from './prints/controle_start/controle_start';
import ControleFinishPrint from './prints/controle_finish/controle_finish';
import ManageScreen from './manage/manage';
import {Results} from './results/results';
import {Layout} from './layout';
import {Narrowcasting} from './narrowcasting/narrowcasting';
import {Typography} from '@material-ui/core';
import HeatEditorScreen from './management/heat_editor/heat_editor';
import StyledAutoAssignScreen from './management/auto_assign/auto_assign';

interface OwnProps {
    edition: Edition;
}

export default class Secured extends React.Component<OwnProps> {
    public render() {
        return (
            <>
                <Route
                    exact
                    path="/edition/heats"
                    component={() => (
                        <Layout>
                            <Heats edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/heats/add"
                    component={() => (
                        <Layout>
                            <AddHeatScreen edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/heat/:id/edit"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <Layout>
                            <EditHeatScreen heatId={routeComponentProps.match.params.id} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/heat/:id"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <Layout>
                            <ViewHeatScreen heatId={routeComponentProps.match.params.id} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/heat/:id/recorded-times"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <Layout>
                            <ManageTimesScreen heatId={routeComponentProps.match.params.id} />
                        </Layout>
                    )}
                />

                <Route
                    exact
                    path="/edition/teams"
                    component={() => (
                        <Layout>
                            <Teams edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/teams/add"
                    component={() => (
                        <Layout>
                            <AddTeamScreen edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/team/:id"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <Layout>
                            <ViewTeamScreen teamId={routeComponentProps.match.params.id} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/participant/:id"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <Layout>
                            <ViewParticipantScreen participantId={routeComponentProps.match.params.id} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/team/:id/participants/add"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <Layout>
                            <AddParticipantScreen teamId={routeComponentProps.match.params.id} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/import"
                    component={() => (
                        <Layout>
                            <ImportScreen edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/manage"
                    component={() => (
                        <Layout>
                            <ManageScreen edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/heateditor"
                    component={() => (
                        <Layout wide>
                            <HeatEditorScreen edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/participants"
                    component={() => (
                        <Layout>
                            <Typography>TODO</Typography>
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/autoassign"
                    component={() => (
                        <Layout wide>
                            <StyledAutoAssignScreen edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/results"
                    component={() => (
                        <Layout wide>
                            <Results edition={this.props.edition} />
                        </Layout>
                    )}
                />
                <Route
                    exact
                    path="/edition/narrowcasting"
                    component={() => <Narrowcasting edition={this.props.edition} />}
                />
                <Route
                    exact
                    path="/edition/prints/startlijst"
                    component={() => <StartlijstPrint edition={this.props.edition} />}
                />
                <Route
                    exact
                    path="/edition/prints/inschrijftafel/opnummer"
                    component={() => <InschrijfTafelPrint edition={this.props.edition} sortByName={false} />}
                />
                <Route
                    exact
                    path="/edition/prints/inschrijftafel/opnaam"
                    component={() => <InschrijfTafelPrint edition={this.props.edition} sortByName={true} />}
                />
                <Route
                    exact
                    path="/edition/prints/inschrijvingen"
                    component={() => <InschrijvingenPrint edition={this.props.edition} />}
                />
                <Route
                    exact
                    path="/edition/prints/controle-start/:id"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <ControleStartPrint heatId={routeComponentProps.match.params.id} />
                    )}
                />
                <Route
                    exact
                    path="/edition/prints/controle-finish/:id"
                    component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                        <ControleFinishPrint heatId={routeComponentProps.match.params.id} />
                    )}
                />
            </>
        );
    }
}
