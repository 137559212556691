import * as React from 'react';
import Typography from '@material-ui/core/Typography';

interface OwnProps {}

export default class TabContainer extends React.Component<OwnProps> {
    public render() {
        return (
            <Typography component="div" style={{padding: 8 * 3}}>
                {this.props.children}
            </Typography>
        );
    }
}
