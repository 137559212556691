import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {Theme, WithStyles} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

interface OwnProps {}

const styles = (theme: Theme) => ({
    root: {
        padding: 24,
        margin: 12,
        [theme.breakpoints.down('sm')]: {
            margin: '0 -15px',
            padding: 12,
        },
    },
});

class Content extends React.Component<OwnProps & WithStyles<'root'>> {
    public render() {
        return <Paper className={this.props.classes.root}>{this.props.children}</Paper>;
    }
}

export default withStyles(styles)(Content);
