const clubs = new Map<string, string>();
clubs.set('Amycus', 'AMY');
clubs.set('Ank', 'ANK');
clubs.set('De Ank', 'ANK');
clubs.set('Aross', 'ARO');
clubs.set('Asopos de Vliet', 'ASO');
clubs.set('Compagnie', 'COM');
clubs.set('HRV de Compagnie', 'COM');
clubs.set('Daventria', 'DAV');
clubs.set('Diep', 'DIE');
clubs.set('Doorslag', 'DOO');
clubs.set('Euros', 'EUR');
clubs.set('Gorcumse', 'GOR');
clubs.set('Gouda', 'GOU');
clubs.set('Grift', 'GRI');
clubs.set('Hoop', 'HOO');
clubs.set('IJssel', 'IJS');
clubs.set('Jason', 'JAS');
clubs.set('Thyro', 'THY');
clubs.set('Triton', 'TRI');
clubs.set('Tubantia', 'TUB');
clubs.set('Vada', 'VAD');
clubs.set('Zwolsche', 'ZWO');
clubs.set('Willem III', 'WIL');
clubs.set('Leythe', 'LEY');
clubs.set('Ruder Verein Münster', 'RVM');
clubs.set('Thyro/Leyte', 'THYL');
clubs.set('Thyro/Willem III', 'THYW');
clubs.set('Onbekend', 'NN');
clubs.set('Amenophis', 'AME');
clubs.set('ARC', 'ARC');
clubs.set('DDP', 'DDP');
clubs.set('Hemus', 'HEM');
clubs.set('Naarden', 'NAA');
clubs.set('Poseidon', 'POS');
clubs.set('Sallandse', 'SAL');
clubs.set('Weesp', 'WEE');
clubs.set('Roosendaalse', 'ROO');
clubs.set('Daventria/Amycus', 'DAAM');
clubs.set('Daventria/Zwolsche', 'DAZW');
clubs.set('Isala', 'ISA');
clubs.set('Tromp', 'TRO');
clubs.set('Tubantia/Hunze', 'TUHU');
clubs.set('Tubantia/Zwolsche', 'TUZW');
clubs.set('Tubantia/Thyro', 'TUTY');
clubs.set('Njord', 'NJO');
clubs.set('De Hunze', 'HUN');
clubs.set('Beatrix', 'BEA');
clubs.set('Boreas', 'BOR');
clubs.set('De Waal', 'WAA');
clubs.set('De Waal', 'WAA');
clubs.set('Orca', 'ORC');
clubs.set('De Hertog', 'HER');
clubs.set('Munster', 'Mun');
clubs.set('Meppen', 'MEP');
clubs.set('Aengwirden', 'AEW');
clubs.set('Binnenmaas', 'BIN');
clubs.set('Dudok van Heel', 'DUD');
clubs.set('De Eem', 'EEM');
clubs.set('Honte', 'HON');
clubs.set('Leerdam', 'LEE');
clubs.set('Rijnland', 'RIJ');
clubs.set('Hertog', 'HER');
clubs.set('Kogge', 'KOG');

/**
 * Fetches the actual club name
 * @param {string} clubName
 * @returns {string}
 */
export function encodeClubName(clubName: string): string {
    if (clubName === '' || clubName === undefined) {
        return '?';
    }
    return clubs.get(clubName.trim()) || clubName;
}
