import * as React from 'react';
import Button from '@material-ui/core/Button/Button';

interface OwnProps {
    label: string;
    onClick: () => void;
}

export class TeamControlButton extends React.Component<OwnProps> {
    public shouldComponentUpdate(nextProps: Readonly<OwnProps>, nextState: Readonly<{}>, nextContext: any): boolean {
        return false;
    }

    public render() {
        return (
            <Button key="finish" disableRipple variant="contained" color="primary" onClick={this.props.onClick}>
                {this.props.label}
            </Button>
        );
    }
}
