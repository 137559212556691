export enum RecordedAs {
    Start = 'start',
    Finish = 'finish',
    Loose = 'loose',
}

export interface RecordedTimeContext {
    recordedAs: RecordedAs;
    teamId: string | null;
}

export interface RecordedTime {
    id: string;
    heatId: string;
    synced: boolean;
    timeMs: number;
    context: RecordedTimeContext;
}
