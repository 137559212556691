import {Gender} from '../../../models/participant';

export interface HandicapsRow {
    male: number;
    female: number;
}

export abstract class HandicapTable {
    protected handicaps: Map<number, HandicapsRow> = new Map<number, HandicapsRow>();
    private readonly minimumAge: number;
    private readonly maximumAge: number;
    public readonly name: string;

    protected abstract create(): void;

    public constructor(name: string) {
        this.create();
        this.name = name;
        this.minimumAge = Math.min(...this.handicaps.keys());
        this.maximumAge = Math.max(...this.handicaps.keys());
    }

    public getRowerHandicap(age: number, gender: Gender): number {
        if (age < this.minimumAge) {
            age = this.minimumAge;
        } else if (age > this.maximumAge) {
            age = this.maximumAge;
        }
        switch (gender) {
            case 'Female':
                const femaleHandicap = this.handicaps.get(age);
                return femaleHandicap ? femaleHandicap.female : 0.783;
            case 'Male':
                const defaultHandicap = this.handicaps.get(age);
                return defaultHandicap ? defaultHandicap.male : 0.87;
            case 'Unknown':
                return 0.0;
        }
    }
}
