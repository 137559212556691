/**
 * Format timestamp using UTC as a timezone
 * @param {number | null} timeMs
 * @returns {string}
 */
export function formatTimeMsUTC(timeMs: number | null, highPrecision = true): string {
    if (timeMs === null) {
        return '-:--';
    }

    const date = new Date(timeMs);

    return formatTime(date.getUTCHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds(), highPrecision);
}

/**
 * Format time in a lower precision
 * @param {number | null} timeMs
 * @returns {string}
 */
export function formatTimeMsUTCLowPrecision(timeMs: number | null) {
    return formatTimeMsUTC(timeMs, false);
}

/**
 * Format timestamp using client computer's timeezone
 * @param {number | null} timeMs
 * @returns {string}
 */
export function formatTimeMsClientTime(timeMs: number | null, highPrecision = true): string {
    if (timeMs === null) {
        return '-:--';
    }

    const date = new Date(timeMs);

    return formatTime(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds(), highPrecision);
}

/**
 * Format timestamp using client computer's timeezone in low precision
 * @param {number | null} timeMs
 * @returns {string}
 */
export function formatTimeMsClientTimeLowPreciesion(timeMs: number | null) {
    return formatTimeMsClientTime(timeMs, false);
}

/**
 * Internal function to actually format a time
 * @param {number} hours
 * @param {number} minutes
 * @param {number} seconds
 * @param {number} milliseconds
 * @param {number} highPrecision
 * @returns {string}
 */
function formatTime(hours: number, minutes: number, seconds: number, milliseconds: number, highPrecision: boolean) {
    let s = '';
    if (hours > 0) {
        s += hours + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
    } else {
        s += minutes + ':' + ('0' + seconds).slice(-2);
    }
    if (highPrecision) {
        s += ':' + ('000' + milliseconds).slice(-3);
    }
    return s;
}
