import {ResultRequest} from '../../../models/result_request';
import {ResultGroup} from './models/resultGroup';
import {RecordedTimeRepository} from '../../persistence/recorded_time/recorded_time_repository';
import {TeamRepository} from '../../persistence/team/team_repository';
import {ResultBuilder} from './result_builder';
import {ParticipantRepository} from '../../persistence/participant/participant_repository';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {HeatRepository} from '../../persistence/heat/heat_repository';
import {combineLatest, Observable} from 'rxjs';
import {Heat} from '../../../models/heat';
import {RecordedTime} from '../../../models/recorded_time';
import {Team} from '../../../models/team';
import {Participant} from '../../../models/participant';

export interface ResultProvider {
    build(editionId: string, resultRequest: ResultRequest): Observable<ResultGroup[]>;
}

export class DefaultResultProvider implements ResultProvider {
    constructor(
        private resultBuiler: ResultBuilder,
        private teamRepository: TeamRepository,
        private heatRepository: HeatRepository,
        private recordedTimeRepository: RecordedTimeRepository,
        private participantRepository: ParticipantRepository,
    ) {}

    public build(editionId: string, resultRequest: ResultRequest): Observable<ResultGroup[]> {
        return combineLatest(
            this.teamRepository.findByEditionId(editionId),
            this.heatRepository.findByEditionId(editionId).pipe(
                switchMap((heats: Heat[]) =>
                    combineLatest(...heats.map(heat => this.recordedTimeRepository.findByHeatId(heat.id))),
                ),
                map((recordedTimes: RecordedTime[][]) => recordedTimes.reduce((p, c) => [...p, ...c], [])),
            ),
            this.participantRepository.findByEditionId(editionId),
        ).pipe(
            flatMap(([teams, recordedTimes, participants]: [Team[], RecordedTime[], Participant[]]) =>
                this.resultBuiler.build(resultRequest, teams, recordedTimes, participants),
            ),
        );
    }
}
