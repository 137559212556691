import {RecordedTimeRepository} from './recorded_time_repository';
import {RecordedTimeRepository as TrackingRecordedTimeRepository} from '../../../client/tracking/repositories/recorded_time_repository';
import {RecordedAs, RecordedTime, RecordedTimeContext} from '../../../models/recorded_time';
import {v4 as uuid} from 'uuid';
import FirebaseDriver from '../drivers/firebase_driver';
import {Observable, Observer} from 'rxjs';

interface FirebaseRecordedTime {
    id: string;
    heat_id: string;
    time_ms: number;
    context: {
        recorded_as: RecordedAs;
        team_id: string | null;
    };
}

export class FirebaseRecordedTimeRepository implements RecordedTimeRepository, TrackingRecordedTimeRepository {
    private _ref = 'recorded_times';
    private _firebaseDriver: FirebaseDriver;

    constructor(firebaseDriver: FirebaseDriver) {
        this._firebaseDriver = firebaseDriver;
    }

    public async sync(recordedTime: RecordedTime): Promise<RecordedTime> {
        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(recordedTime.id)
            .set(FirebaseRecordedTimeRepository.toFirebaseRecordedTime(recordedTime));

        return {
            ...recordedTime,
            synced: true,
        };
    }

    public async create(heatId: string, timeMs: number, context: RecordedTimeContext): Promise<RecordedTime> {
        const recordedTime: RecordedTime = {
            id: uuid(),
            heatId: heatId,
            synced: true,
            timeMs: timeMs,
            context: {
                recordedAs: context.recordedAs,
                teamId: context.teamId,
            },
        };

        return this.sync(recordedTime);
    }

    public async createWithId(
        id: string,
        heatId: string,
        timeMs: number,
        context: RecordedTimeContext,
    ): Promise<RecordedTime> {
        const recordedTime: RecordedTime = {
            id: id,
            heatId: heatId,
            synced: true,
            timeMs: timeMs,
            context: {
                recordedAs: context.recordedAs,
                teamId: context.teamId,
            },
        };

        return this.sync(recordedTime);
    }

    public findByHeatId(heatId: string): Observable<RecordedTime[]> {
        return new Observable((observer: Observer<RecordedTime[]>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('heat_id')
                .equalTo(heatId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseRecordedTime} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const heats = Object.keys(result)
                            .map(key => result[key])
                            .map(heat => FirebaseRecordedTimeRepository.fromFirebaseRecordedTime(heat));
                        observer.next(heats);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    private static toFirebaseRecordedTime(recordedTime: RecordedTime): FirebaseRecordedTime {
        return {
            id: recordedTime.id,
            heat_id: recordedTime.heatId,
            time_ms: recordedTime.timeMs,
            context: {
                recorded_as: recordedTime.context.recordedAs,
                team_id: recordedTime.context.teamId,
            },
        };
    }

    private static fromFirebaseRecordedTime(firebaseRecordedTime: FirebaseRecordedTime): RecordedTime {
        return {
            id: firebaseRecordedTime.id,
            heatId: firebaseRecordedTime.heat_id,
            synced: true,
            timeMs: firebaseRecordedTime.time_ms,
            context: {
                recordedAs: firebaseRecordedTime.context.recorded_as,
                teamId: firebaseRecordedTime.context.team_id,
            },
        };
    }
}
