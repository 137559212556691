import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Presenter} from '../../../../support/with_presenter';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Team} from '../../../../../models/team';
import {computed, observable} from 'mobx';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {ParticipantRepository} from '../../../../persistence/participant/participant_repository';
import {Heat} from '../../../../../models/heat';
import {Participant} from '../../../../../models/participant';
import {RecordedTimeRepository} from '../../../../persistence/recorded_time/recorded_time_repository';
import {CompositeSubscription} from '../../../../support/composit_subscription';
import {RecordedTime} from '../../../../../models/recorded_time';

export class ControleFinishPresenter implements Presenter {
    @observable private loadingTeams: boolean = true;
    @observable private loadingHeat: boolean = true;
    @observable private loadingParticipants: boolean = true;
    @observable private loadingRecordedTimes: boolean = true;

    @computed
    public get loading() {
        const loading = this.loadingTeams || this.loadingHeat || this.loadingParticipants || this.loadingRecordedTimes;
        if (!loading) {
            /* We have to wait one tick for the render to finish based on this result, otherwise we're printing the loader */
            setTimeout(() => {
                window.print();
            }, 0);
        }
        return loading;
    }

    @observable public teams: Team[] = [];
    @observable public heat: Heat | null = null;
    @observable public participants: Participant[] = [];
    @observable public recordedTimes: RecordedTime[] = [];
    private _heatId: string;
    private _teamRepository: TeamRepository;
    private _subscriptions = new CompositeSubscription();
    private _heatRepository: HeatRepository;
    private _participantRepository: ParticipantRepository;
    private _recordedTimeRepository: RecordedTimeRepository;

    constructor(
        heatId: string,
        teamRepository: TeamRepository,
        heatRepository: HeatRepository,
        participantRepository: ParticipantRepository,
        recordedTimeRepository: RecordedTimeRepository,
    ) {
        this._heatId = heatId;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
        this._participantRepository = participantRepository;
        this._recordedTimeRepository = recordedTimeRepository;
    }

    public mount(): void {
        this._subscriptions.add(
            this._teamRepository.findByHeatId(this._heatId).subscribe(teams => {
                this.teams = teams;
                this.loadingTeams = false;
            }),
        );
        this._subscriptions.add(
            this._heatRepository.findById(this._heatId).subscribe(heat => {
                this.loadingHeat = false;
                this.heat = heat;
            }),
        );

        this._subscriptions.add(
            this._heatRepository
                .findById(this._heatId)
                .pipe(
                    switchMap(heat => {
                        if (heat === null) {
                            return of([]);
                        }
                        return this._participantRepository.findByEditionId(heat.editionId);
                    }),
                )
                .subscribe((participants: Participant[]) => {
                    this.participants = participants;
                    this.loadingParticipants = false;
                }),
        );

        this._subscriptions.add(
            this._recordedTimeRepository.findByHeatId(this._heatId).subscribe(recordedTimes => {
                this.recordedTimes = recordedTimes;
                this.loadingRecordedTimes = false;
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
