import {Presenter} from '../../../../support/with_presenter';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Team} from '../../../../../models/team';
import {observable} from 'mobx';
import {CompositeSubscription} from '../../../../support/composit_subscription';
import {ParticipantRepository} from '../../../../persistence/participant/participant_repository';
import {Participant} from '../../../../../models/participant';

export class ViewTeamScreenPresenter implements Presenter {
    @observable public loading = true;
    @observable public team: Team | null = null;
    @observable public participants: Participant[] | null = [];
    private _teamId: string;
    private _teamRepository: TeamRepository;
    private _subscription = new CompositeSubscription();
    private _participantRepository: ParticipantRepository;

    constructor(teamId: string, teamRepository: TeamRepository, participantRepository: ParticipantRepository) {
        this._teamId = teamId;
        this._teamRepository = teamRepository;
        this._participantRepository = participantRepository;
    }

    public mount(): void {
        this._subscription.add(
            this._teamRepository.findById(this._teamId).subscribe((team: Team | null) => {
                this.loading = false;
                this.team = team;
            }),
        );
        this._subscription.add(
            this._participantRepository.findByTeamId(this._teamId).subscribe((participants: Participant[]) => {
                this.participants = participants;
            }),
        );
    }

    public unmount(): void {
        this._subscription.clear();
    }
}
