import {TeamWithTimes} from './teams_with_times_combiner';
import {Result} from '../models/result';
import {formatTimeMsUTC} from '../../../support/format_time_ms';
import {ResultRequest} from '../../../../models/result_request';
import {ResultMode} from '../result_mode';

export class ResultFormatter {
    public getTimeTitle(resultRequest: ResultRequest) {
        switch (resultRequest.mode) {
            case ResultMode.Long2019:
            case ResultMode.Short2019:
            case ResultMode.Long2023:
            case ResultMode.Short2023:
                return 'Tijd (gecorrigeerd)';
            case ResultMode.Absolute:
            case ResultMode.Rugnummer:
                return 'Tijd (absoluut)';
        }
    }

    /**
     * Will create the final formatted result list
     * @param teamsWithTimes
     * @return Result[]
     */
    public format(teamsWithTimes: TeamWithTimes[]): Result[] {
        return teamsWithTimes.map((teamWithTimes, index) => {
            return {
                team: teamWithTimes.team,
                participants: teamWithTimes.participants,
                position: this.shouldHavePositionAssigned(teamWithTimes) ? index + 1 : null,
                time: teamWithTimes.absoluteTimeMs,
                displayTime: teamWithTimes.finish === null ? 'DNF' : formatTimeMsUTC(teamWithTimes.displayTimeMs),
            };
        });
    }

    private shouldHavePositionAssigned(teamWithTimes: TeamWithTimes) {
        return (
            teamWithTimes.team.isQualified &&
            teamWithTimes.team.isInCompetition &&
            teamWithTimes.start !== null &&
            teamWithTimes.finish !== null
        );
    }
}
