import * as React from 'react';
import {observer} from 'mobx-react';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {NarrowcastingPresenter} from './narrowcasting_presenter';
import {Edition} from '../../../../models/edition';
import {DefaultNarrowcastableResultGroupsProvider} from './internal/default_narrowcastable_result_groups_provider';
import Grid from '@material-ui/core/Grid';
import {ResultGroupTable} from './components/result_group';
import {Theme, WithStyles} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import {Progress} from './components/progress';

interface OwnProps {
    edition: Edition;
}

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 30,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    tabs: {
        flexGrow: 1,
        maxWidth: 1200,
        margin: '0 auto',
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
    },
});

type Styles = WithStyles<'root' | 'tabs' | 'appBar'>;

@observer
class NarrowcastingComponent extends React.Component<OwnProps & Styles & PresenterProps<NarrowcastingPresenter>> {
    private getWelcomeMessage() {
        return (
            <Grid item xs={12} style={{textAlign: 'center', padding: 50}}>
                <h1>Welkom bij {this.props.edition.name}!</h1>
                <h3 style={{fontWeight: 100}}>Houd dit scherm in de gaten voor de meest recente resultaten.</h3>
            </Grid>
        );
    }

    public render() {
        if (this.props.presenter.showPlaceholder) {
            return (
                <div className={this.props.classes.root}>
                    <Grid container spacing={8}>
                        {this.getWelcomeMessage()}
                    </Grid>
                </div>
            );
        }

        return (
            <div className={this.props.classes.root}>
                <Grid container spacing={8}>
                    {this.getWelcomeMessage()}
                    <Grid item xs={12}>
                        {this.props.presenter.resultForResultRequests.map(resultForResultRequest => {
                            return (
                                <ResultGroupTable
                                    key={resultForResultRequest.resultRequest.id}
                                    resultRequest={resultForResultRequest.resultRequest}
                                    results={resultForResultRequest.results}
                                />
                            );
                        })}
                    </Grid>
                </Grid>
                <Progress progress={this.props.presenter.progress} />
            </div>
        );
    }
}

export const Narrowcasting = withPresenter<NarrowcastingPresenter, OwnProps>(
    (props, component) =>
        new NarrowcastingPresenter(
            new DefaultNarrowcastableResultGroupsProvider(
                props.edition.id,
                component.persistence.resultRequestRepository,
                component.business.resultProvider,
                component.persistence.heatRepository,
            ),
        ),
    withStyles(styles)(NarrowcastingComponent),
);
