import * as React from 'react';
import {StartlijstPresenter} from './startlijst_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import Loading from '../../../components/loading';
import {Edition, RegattaMode} from '../../../../../models/edition';
import {observer} from 'mobx-react';
import PrintPage from '../../../components/print_page';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {Team} from '../../../../../models/team';
import {decodeClubCode} from '../../../../support/club_code_decoder';
import Grid from '@material-ui/core/Grid/Grid';
import {createStyles, TableHead, withStyles, WithStyles} from '@material-ui/core';
import {Heat} from '../../../../../models/heat';

const styles = createStyles({
    tableCell: {
        paddingLeft: 6,
        paddingRight: 6,
    },
});

interface OwnProps extends WithStyles<typeof styles> {
    edition: Edition;
}

@observer
class StartlijstPrint extends React.Component<OwnProps & PresenterProps<StartlijstPresenter>> {
    private sortTeams(a: Team, b: Team): number {
        if (a.number === null && b.number === null) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
        if (a.number === null) {
            return -1;
        }
        if (b.number === null) {
            return 1;
        }
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }
        return 0;
    }

    public render() {
        if (this.props.presenter.loading) {
            return (
                <Grid container>
                    <Loading />
                </Grid>
            );
        }
        const {classes} = this.props;

        if (this.props.edition.mode === RegattaMode.Sprint) {
            return (
                <PrintPage>
                    {this.props.presenter.heats
                        .filter(heat => heat.number > 0)
                        .map(heat => this.renderHeat(heat, classes))}
                </PrintPage>
            );
        }

        return (
            <>
                {this.props.presenter.heats
                    .filter(heat => heat.number > 0)
                    .map(heat => {
                        return <PrintPage key={heat.id}>{this.renderHeat(heat, classes)}</PrintPage>;
                    })}
            </>
        );
    }

    private renderHeat(heat: Heat, classes: any) {
        return (
            <>
                <h2>
                    {this.props.edition.name}: {heat.name}
                </h2>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Rug</TableCell>
                            <TableCell className={classes.tableCell}>Categorie</TableCell>
                            <TableCell className={classes.tableCell}>Boot</TableCell>
                            <TableCell className={classes.tableCell}>Verenigingscode</TableCell>
                            <TableCell className={classes.tableCell}>Roeier 1</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.presenter.teams
                            .sort(this.sortTeams)
                            .filter(team => team.heatId === heat.id)
                            .filter(team => team.isAttending)
                            .map(team => {
                                return (
                                    <TableRow>
                                        <TableCell>{team.number ? team.number : '?'}</TableCell>
                                        <TableCell>
                                            <span style={{whiteSpace: 'nowrap'}}>{team.category}</span>
                                        </TableCell>
                                        <TableCell>{team.boat}</TableCell>
                                        <TableCell>{decodeClubCode(team.clubCode)}</TableCell>
                                        <TableCell>{this.rower1(team)}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </>
        );
    }

    private rower1(team: Team): string {
        const rower1 = this.props.presenter.participants.find(participant => participant.id === team.rower1Id);

        return rower1 ? rower1.name : 'Eerste roeier onbekend';
    }
}

export default withStyles(styles)(
    withPresenter<StartlijstPresenter, OwnProps>(
        (props, component) =>
            new StartlijstPresenter(
                props.edition,
                component.persistence.teamRepository,
                component.persistence.heatRepository,
                component.persistence.participantRepository,
            ),
        StartlijstPrint,
    ),
);
