import {BoatType, ParticipantGroup} from '../boat_type';
import {Category} from '../../../models/team';

const boatTypes = new Map<string, BoatType>();
// Skiff
boatTypes.set('H 1x', new BoatType('H 1x-', 1, false, ParticipantGroup.Men));
boatTypes.set('HO 1x', new BoatType('H 1x-', 1, false, ParticipantGroup.Men));
boatTypes.set('D 1x', new BoatType('D 1x-', 1, false, ParticipantGroup.Women));
boatTypes.set('DO 1x', new BoatType('D 1x-', 1, false, ParticipantGroup.Women));
boatTypes.set('J18 1x', new BoatType('J18 1x-', 1, false, ParticipantGroup.Boys));
boatTypes.set('M18 1x', new BoatType('M18 1x-', 1, false, ParticipantGroup.Girls));

// Twee-zonder
boatTypes.set('H 2-', new BoatType('H 2-', 2, false, ParticipantGroup.Men));
boatTypes.set('HO 2-', new BoatType('H 2-', 2, false, ParticipantGroup.Men));
boatTypes.set('D 2-', new BoatType('D 2-', 2, false, ParticipantGroup.Women));
boatTypes.set('DO 2-', new BoatType('D 2-', 2, false, ParticipantGroup.Women));
boatTypes.set('Mix 2-', new BoatType('MX 2-', 2, false, ParticipantGroup.Mixed));
boatTypes.set('J18 2-', new BoatType('J18 2-', 2, false, ParticipantGroup.Boys));
boatTypes.set('M18 2-', new BoatType('M18 2-', 2, false, ParticipantGroup.Girls));
boatTypes.set('Mix18 2-', new BoatType('MX18 2-', 2, false, ParticipantGroup.Mixed18));

// Dubbeltwee
boatTypes.set('H 2x', new BoatType('H 2x-', 2, false, ParticipantGroup.Men));
boatTypes.set('HO 2x', new BoatType('H 2x-', 2, false, ParticipantGroup.Men));
boatTypes.set('D 2x', new BoatType('D 2x-', 2, false, ParticipantGroup.Women));
boatTypes.set('DO 2x', new BoatType('D 2x-', 2, false, ParticipantGroup.Women));
boatTypes.set('Mix 2x', new BoatType('MX 2x-', 2, false, ParticipantGroup.Mixed));
boatTypes.set('MixO 2x', new BoatType('MX 2x-', 2, false, ParticipantGroup.Mixed));
boatTypes.set('J18 2x', new BoatType('J18 2x-', 2, false, ParticipantGroup.Boys));
boatTypes.set('M18 2x', new BoatType('M18 2x-', 2, false, ParticipantGroup.Girls));
boatTypes.set('Mix18 2x', new BoatType('MX18 2x-', 2, false, ParticipantGroup.Mixed18));
boatTypes.set('J16 2x', new BoatType('J16 2x-', 2, false, ParticipantGroup.Boys));
boatTypes.set('M16 2x', new BoatType('M16 2x-', 2, false, ParticipantGroup.Girls));

// Dubbelvier-met
boatTypes.set('H 4*', new BoatType('H 4x+', 4, true, ParticipantGroup.Men));
boatTypes.set('D 4*', new BoatType('D 4x+', 4, true, ParticipantGroup.Women));
boatTypes.set('Mix 4*', new BoatType('MX 4x+', 4, true, ParticipantGroup.Mixed));
boatTypes.set('J18 4*', new BoatType('J18 4x+', 4, true, ParticipantGroup.Boys));
boatTypes.set('M18 4*', new BoatType('M18 4x+', 4, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 4*', new BoatType('MX18 4x+', 4, true, ParticipantGroup.Mixed18));
boatTypes.set('J16 4*', new BoatType('J16 4x+', 4, true, ParticipantGroup.Boys));
boatTypes.set('M16 4*', new BoatType('M16 4x+', 4, true, ParticipantGroup.Girls));

// Vier-met
boatTypes.set('H 4+', new BoatType('H 4+', 4, true, ParticipantGroup.Men));
boatTypes.set('D 4+', new BoatType('D 4+', 4, true, ParticipantGroup.Women));
boatTypes.set('Mix 4+', new BoatType('MX 4+', 4, true, ParticipantGroup.Mixed));
boatTypes.set('J18 4+', new BoatType('J18 4+', 4, true, ParticipantGroup.Boys));
boatTypes.set('M18 4+', new BoatType('M18 4+', 4, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 4+', new BoatType('MX18 4+', 4, true, ParticipantGroup.Mixed18));

// Vier-zonder
boatTypes.set('H 4-', new BoatType('H 4-', 4, false, ParticipantGroup.Men));
boatTypes.set('D 4-', new BoatType('D 4-', 4, false, ParticipantGroup.Women));
boatTypes.set('Mix 4-', new BoatType('MX 4-', 4, false, ParticipantGroup.Mixed));
boatTypes.set('J18 4-', new BoatType('J18 4-', 4, false, ParticipantGroup.Boys));
boatTypes.set('M18 4-', new BoatType('M18 4-', 4, false, ParticipantGroup.Girls));
boatTypes.set('Mix18 4-', new BoatType('MX18 4-', 4, false, ParticipantGroup.Mixed18));

// Dubbelvier-zonder
boatTypes.set('H 4x', new BoatType('H 4x-', 4, false, ParticipantGroup.Men));
boatTypes.set('D 4x', new BoatType('D 4x-', 4, false, ParticipantGroup.Women));
boatTypes.set('Mix 4x', new BoatType('MX 4x-', 4, false, ParticipantGroup.Mixed));
boatTypes.set('J18 4x', new BoatType('J18 4x-', 4, false, ParticipantGroup.Boys));
boatTypes.set('M18 4x', new BoatType('M18 4x-', 4, false, ParticipantGroup.Girls));
boatTypes.set('Mix18 4x', new BoatType('MX18 4x-', 4, false, ParticipantGroup.Mixed18));

// Dubbelacht
boatTypes.set('H 8*', new BoatType('H 8x+', 8, true, ParticipantGroup.Men));
boatTypes.set('D 8*', new BoatType('D 8x+', 8, true, ParticipantGroup.Women));
boatTypes.set('Mix 8*', new BoatType('MX 8x+', 8, true, ParticipantGroup.Mixed));
boatTypes.set('J18 8*', new BoatType('J18 8x+', 8, true, ParticipantGroup.Boys));
boatTypes.set('M18 8*', new BoatType('M18 8x+', 8, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 8*', new BoatType('MX18 8x+', 8, true, ParticipantGroup.Mixed18));

// Acht
boatTypes.set('H 8+', new BoatType('H 8+', 8, true, ParticipantGroup.Men));
boatTypes.set('H 8', new BoatType('H 8+', 8, true, ParticipantGroup.Men));
boatTypes.set('D 8+', new BoatType('D 8+', 8, true, ParticipantGroup.Women));
boatTypes.set('D 8', new BoatType('D 8+', 8, true, ParticipantGroup.Women));
boatTypes.set('Mix 8+', new BoatType('MX 8+', 8, true, ParticipantGroup.Mixed));
boatTypes.set('Mix 8', new BoatType('MX 8+', 8, true, ParticipantGroup.Mixed));
boatTypes.set('J18 8+', new BoatType('J18 8+', 8, true, ParticipantGroup.Boys));
boatTypes.set('J18 8', new BoatType('J18 8+', 8, true, ParticipantGroup.Boys));
boatTypes.set('M18 8+', new BoatType('M18 8+', 8, true, ParticipantGroup.Girls));
boatTypes.set('M18 8', new BoatType('M18 8+', 8, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 8+', new BoatType('MX18 8+', 8, true, ParticipantGroup.Mixed18));
boatTypes.set('Mix18 8', new BoatType('MX18 8+', 8, true, ParticipantGroup.Mixed18));

// C-dubbeltwee
boatTypes.set('H C2*', new BoatType('H C2x+', 2, true, ParticipantGroup.Men));
boatTypes.set('D C2*', new BoatType('D C2x+', 2, true, ParticipantGroup.Women));
boatTypes.set('Mix C2*', new BoatType('MX C2x+', 2, true, ParticipantGroup.Mixed));
boatTypes.set('J18 C2*', new BoatType('J18 C2x+', 2, true, ParticipantGroup.Boys));
boatTypes.set('M18 C2*', new BoatType('M18 C2x+', 2, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 C2*', new BoatType('MX18 C2x+', 2, true, ParticipantGroup.Mixed18));

// C-dubbeltwee-zonder
boatTypes.set('H C2x', new BoatType('H C2x-', 2, false, ParticipantGroup.Men));
boatTypes.set('D C2x', new BoatType('D C2x-', 2, false, ParticipantGroup.Women));
boatTypes.set('Mix C2x', new BoatType('MX C2x-', 2, false, ParticipantGroup.Mixed));
boatTypes.set('J18 C2x', new BoatType('J18 C2x-', 2, false, ParticipantGroup.Boys));
boatTypes.set('M18 C2x', new BoatType('M18 C2x-', 2, false, ParticipantGroup.Girls));
boatTypes.set('Mix18 C2x', new BoatType('MX18 C2x-', 2, false, ParticipantGroup.Mixed18));

// C-dubbelvier
boatTypes.set('H C4*', new BoatType('H C4x+', 4, true, ParticipantGroup.Men));
boatTypes.set('D C4*', new BoatType('D C4x+', 4, true, ParticipantGroup.Women));
boatTypes.set('Mix C4*', new BoatType('MX C4x+', 4, true, ParticipantGroup.Mixed));
boatTypes.set('J18 C4*', new BoatType('J18 C4x+', 4, true, ParticipantGroup.Boys));
boatTypes.set('M18 C4*', new BoatType('M18 C4x+', 4, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 C4*', new BoatType('MX18 C4x+', 4, true, ParticipantGroup.Mixed18));

// C-vier
boatTypes.set('H C4+', new BoatType('H C4+', 4, true, ParticipantGroup.Men));
boatTypes.set('D C4+', new BoatType('D C4+', 4, true, ParticipantGroup.Women));
boatTypes.set('Mix C4+', new BoatType('MX C4+', 4, true, ParticipantGroup.Mixed));
boatTypes.set('J18 C4+', new BoatType('J18 C4+', 4, true, ParticipantGroup.Boys));
boatTypes.set('M18 C4+', new BoatType('M18 C4+', 4, true, ParticipantGroup.Girls));
boatTypes.set('Mix18 C4+', new BoatType('MX18 C4+', 4, true, ParticipantGroup.Mixed18));

const categoryTable = new Map<Category, BoatType>();
boatTypes.forEach(value => {
    categoryTable.set(value.category, value);
});

const aliasTable = new Map<string, Category>();
aliasTable.set('D18 2-', 'M18 2-');
aliasTable.set('D18 2x', 'M18 2x-');
aliasTable.set('D18 4*', 'M18 4x+');
aliasTable.set('D18 4+', 'M18 4+');
aliasTable.set('D18 8+', 'M18 8+');
aliasTable.set('D18 C4*', 'M18 C4x+');
aliasTable.set('D18 C4+', 'M18 C4+');
aliasTable.set('D2-', 'D 2-');
aliasTable.set('D2x', 'D 2x-');
aliasTable.set('D4*', 'D 4x+');
aliasTable.set('D4+', 'D 4+');
aliasTable.set('D4-', 'D 4-');
aliasTable.set('D4x', 'D 4x-');
aliasTable.set('D8*', 'D 8x+');
aliasTable.set('D8+', 'D 8+');
aliasTable.set('DC4*', 'D C4x+');
aliasTable.set('DC4+', 'D C4+');
aliasTable.set('H18 2-', 'J18 2-');
aliasTable.set('H18 2x', 'J18 2x-');
aliasTable.set('H18 4*', 'J18 4x+');
aliasTable.set('H18 4+', 'J18 4+');
aliasTable.set('H18 8+', 'J18 8+');
aliasTable.set('H18 C4*', 'J18 C4x+');
aliasTable.set('H18 C4+', 'J18 C4+');
aliasTable.set('H2-', 'H 2-');
aliasTable.set('H2x', 'H 2x-');
aliasTable.set('H4*', 'H 4x+');
aliasTable.set('H4+', 'H 4+');
aliasTable.set('H4-', 'H 4-');
aliasTable.set('H4x', 'H 4x-');
aliasTable.set('H8*', 'H 8x+');
aliasTable.set('H8+', 'H 8+');
aliasTable.set('HC4*', 'H C4x+');
aliasTable.set('HC4+', 'H C4+');
aliasTable.set('J 2x', 'J18 2x-');
aliasTable.set('J 4*', 'J18 4x+');
aliasTable.set('J16 2x', 'J16 2x-');
aliasTable.set('J16 4*', 'J16 4x+');
aliasTable.set('J182x', 'J18 2x-');
aliasTable.set('M 2x', 'M18 2x-');
aliasTable.set('M16 2x', 'M16 2x-');
aliasTable.set('M16 4*', 'M16 4x+');
aliasTable.set('Mix 4x-', 'MX 4x-');
aliasTable.set('Mix C4x+', 'MX C4x+');
aliasTable.set('Mix18 2-', 'MX18 2-');
aliasTable.set('Mix18 2x', 'MX18 2x-');
aliasTable.set('Mix18 4*', 'MX18 4x+');
aliasTable.set('Mix18 4+', 'MX18 4+');
aliasTable.set('Mix18 8+', 'MX18 8+');
aliasTable.set('Mix18 C4*', 'MX18 C4x+');
aliasTable.set('Mix18 C4+', 'MX18 C4+');
aliasTable.set('Mix2-', 'MX 2-');
aliasTable.set('Mix2x', 'MX 2x-');
aliasTable.set('Mix4*', 'MX 4x+');
aliasTable.set('Mix4+', 'MX 4+');
aliasTable.set('Mix4x', 'MX 4x-');
aliasTable.set('Mix8*', 'MX 8x+');
aliasTable.set('Mix8+', 'MX 8+');
aliasTable.set('MixC2*', 'MX C2x+');
aliasTable.set('MixC2x', 'MX C2x-');
aliasTable.set('MixC4*', 'MX C4x+');
aliasTable.set('MixC4+', 'MX C4+');
aliasTable.set('OC4*', 'MX C4x+');
aliasTable.set('O18 4*', 'MX18 4x+');

export function toBoatType(wedstrijdCode: string): BoatType {
    const cleaned = wedstrijdCode.trim();
    let boat = boatTypes.get(cleaned);
    if (boat === undefined) {
        // Emit warning?
        const alias = aliasTable.get(cleaned);
        if (alias !== undefined) {
            boat = categoryTable.get(alias);
            if (boat !== undefined) {
                return boat;
            }
        }
        throw new Error('Invalid wedstrijdcode: "' + wedstrijdCode + '"');
    } else {
        return boat;
    }
}

export function toParticipantGroup(category: Category): ParticipantGroup {
    const boat = categoryTable.get(category);
    if (boat !== undefined) {
        return boat.participantGroup;
    } else {
        throw new Error('Invalid category: ' + category);
    }
}

export function getStrokePosition(category: Category): number {
    const boat = categoryTable.get(category);
    if (boat !== undefined) {
        return boat.numberOfRowers;
    } else {
        throw new Error('Invalid category: ' + category);
    }
}
