import * as React from 'react';

export const Site = () => {
    return (
        <>
            <link rel="stylesheet" href="/assets/css/main.css" />
            <div id="wrapper">
                <section className="intro">
                    <header>
                        <h1>WedstrijdKlok</h1>
                        <p>Tijdsregistratie voor uw regatta, eenvoudig en doelgericht.</p>
                        <ul className="actions">
                            <li>
                                <a href="#first" className="arrow scrolly">
                                    <span className="label">Verder</span>
                                </a>
                            </li>
                        </ul>
                    </header>
                    <div className="content">
                        <span className="image fill" data-position="center">
                            <img src="images/pic01.jpg" alt="" />
                        </span>
                    </div>
                </section>
                <section id="first">
                    <header>
                        <h2>Door roeiers voor roeiers</h2>
                    </header>
                    <div className="content">
                        <p>
                            <strong>WedstrijdKlok</strong> is opgestart door Klaas en Joris met eenvoud en precisie als
                            uitgangspunt. Op basis van meer dan een decennium aan fine-tuning aan prototype systemen is
                            in 2015 <em>WedstrijdKlok</em> tot stand gekomen. De combinatie van een gebruiksvriendelijke
                            omgeving met de precisie van door FISA erkend tijdssynchronisatie-algoritme te besturen met
                            een kleine hoeveelheid vrijwilligers maakt <em>WedstrijdKlok</em> ideaal voor kleine
                            regatta's.
                        </p>
                    </div>
                </section>
                <section>
                    <header>
                        <h2>Mobiel, eenvoudig en compleet</h2>
                    </header>
                    <div className="content">
                        <p>
                            We kunnen u voorzien van de verschillende ingredienten die leiden tot een succesvolle
                            tijds-registratie bij uw regatta.
                        </p>
                        <ul className="feature-icons">
                            <li className="icon fa-laptop">5 vrijwilligers instrueren wij</li>
                            <li className="icon fa-signal">Uitslagen</li>
                            <li className="icon fa-bolt">KNRB inschrijvingen import</li>
                            <li className="icon fa-gear">FISA erkend tijds-algoritme</li>
                            <li className="icon fa-map-marker">Overal inzetbaar</li>
                            <li className="icon fa-code">Online beheer</li>
                        </ul>
                    </div>
                </section>
                <section>
                    <header>
                        <h2>Contact</h2>
                    </header>
                    <div className="content">
                        <p>Bent u geinterreseerd? Neem dan op tijd contact met ons op!</p>
                    </div>
                    <footer>
                        <ul className="items">
                            <li>
                                <h3>Email</h3>
                                <a href="mailto:joris@movelab.studio">joris@movelab.studio</a>
                            </li>
                        </ul>
                    </footer>
                </section>

                <div className="copyright">
                    &copy; Wedstrijdklok.nl. All rights reserved. Design: <a href="https://html5up.net">HTML5 UP</a>.
                </div>
                <script src="/assets/js/jquery.min.js" />
                <script src="/assets/js/jquery.scrolly.min.js" />
                <script src="/assets/js/browser.min.js" />
                <script src="/assets/js/breakpoints.min.js" />
                <script src="/assets/js/util.js" />
                <script src="/assets/js/main.js" />
            </div>
        </>
    );
};
