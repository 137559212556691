import {TeamWithTimes} from './teams_with_times_combiner';
import {ResultRequest} from '../../../../models/result_request';
import {ResultMode} from '../result_mode';

export class TeamWithTimesSorter {
    public sort(resultRequest: ResultRequest, teamWithTimes: TeamWithTimes[]) {
        if (resultRequest.mode === ResultMode.Rugnummer) {
            return teamWithTimes.sort(this.sortByNumber.bind(this));
        }
        return teamWithTimes.sort(this.sortByCompetingTime.bind(this));
    }

    private sortByNumber(a: TeamWithTimes, b: TeamWithTimes): -1 | 0 | 1 {
        if (a.team.number === null && b.team.number === null) {
            return 0;
        }
        if (a.team.number === null) {
            return -1;
        }
        if (b.team.number === null) {
            return 1;
        }
        if (a.team.number === b.team.number) {
            return 0;
        }
        if (a.team.number < b.team.number) {
            return -1;
        }
        return 1;
    }

    private sortByCompetingTime(a: TeamWithTimes, b: TeamWithTimes): -1 | 0 | 1 {
        if (!a.team.isQualified && !b.team.isQualified) {
            return 0;
        }
        if (!a.team.isQualified && b.team.isQualified) {
            return 1;
        }
        if (a.team.isQualified && !b.team.isQualified) {
            return -1;
        }
        if (!a.team.isInCompetition && !b.team.isInCompetition) {
            return 0;
        }
        if (!a.team.isInCompetition && b.team.isInCompetition) {
            return 1;
        }
        if (a.team.isInCompetition && !b.team.isInCompetition) {
            return -1;
        }
        if (a.competingTimeMs === null && b.competingTimeMs === null) {
            return 0;
        }
        if (a.competingTimeMs === null) {
            return 1;
        }
        if (b.competingTimeMs === null) {
            return -1;
        }
        if (a.competingTimeMs < b.competingTimeMs) {
            return -1;
        }
        if (a.competingTimeMs > b.competingTimeMs) {
            return 1;
        }

        return 0;
    }
}
