import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Edition} from '../../../../../models/edition';

export class AddHeatPresenter implements Presenter {
    @observable public loading: boolean = false;
    @observable public name: string = '';
    @observable public number: number = 0;
    private _heatRepository: HeatRepository;
    private _edition: Edition;

    constructor(edition: Edition, heatRepository: HeatRepository) {
        this._edition = edition;
        this._heatRepository = heatRepository;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onNumberChange(number: string) {
        this.number = parseInt(number, 10);
    }

    public async onSubmitClicked() {
        this.loading = true;
        try {
            await this._heatRepository.create(this._edition.id, this.name, this.number);
            window.location.href = '/edition/heats';
        } finally {
            this.loading = false;
        }
    }
}
