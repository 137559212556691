import * as React from 'react';
import {BrowserRouter as Router, RouteComponentProps} from 'react-router-dom';
import RequiresAuthentication from './administration/screens/middleware/requires_authentication';
import Secured from './administration/screens/secured/secured';
import Login from './administration/screens/login/login';
import {Route} from 'react-router';
import {Edition} from './models/edition';
import {Client} from './client/ui/client';
import {Site} from './site/site';
import {EditionHeats} from './client/ui/edition_heats';

export default class Routes extends React.Component<{}, {}> {
    public render() {
        return (
            <Router>
                <>
                    <Route exact path="/" component={Site} />
                    <Route exact path="/login" component={Login} />
                    <Route
                        exact
                        path="/tracking/edition/:id"
                        component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                            <EditionHeats editionId={routeComponentProps.match.params.id} />
                        )}
                    />
                    <Route
                        exact
                        path="/tracking/heat/:id"
                        component={(routeComponentProps: RouteComponentProps<{id: string}>) => (
                            <Client id={routeComponentProps.match.params.id} />
                        )}
                    />
                    <Route
                        path="/edition"
                        render={() => (
                            <RequiresAuthentication renders={(edition: Edition) => <Secured edition={edition} />} />
                        )}
                    />
                </>
            </Router>
        );
    }
}
