import {AuthProvider} from './business/auth/auth_provider';
import {BusinessComponent, DefaultBusinessComponent} from './business/business_component';
import {DefaultPersistenceComponent, PersistenceComponent} from './persistence/persistence_component';
import SessionAuthProvider from './business/auth/session_auth_provider';

export interface Component {
    authProvider: AuthProvider;
    business: BusinessComponent;
    persistence: PersistenceComponent;
}

class DefaultComponent implements Component {
    private _business: BusinessComponent | undefined;
    private _persistence: PersistenceComponent | undefined;
    private _authenticationProvider: AuthProvider | undefined;

    get business(): BusinessComponent {
        if (this._business === undefined) {
            this._business = new DefaultBusinessComponent(this.persistence);
        }
        return this._business;
    }

    get persistence(): PersistenceComponent {
        if (this._persistence === undefined) {
            this._persistence = new DefaultPersistenceComponent();
        }
        return this._persistence;
    }

    get authProvider(): AuthProvider {
        if (this._authenticationProvider === undefined) {
            this._authenticationProvider = new SessionAuthProvider(
                this.persistence.editionRepository,
                this.persistence.sessionRepository,
            );
        }
        return this._authenticationProvider;
    }
}

let instance: Component;

export function component(): Component {
    if (instance === undefined) {
        instance = new DefaultComponent();
    }

    return instance;
}
