import {Presenter} from '../../support/with_presenter';
import {observable} from 'mobx';
import {EditionInteractor} from '../../business/edition/edition_interactor';
import {AuthProvider} from '../../business/auth/auth_provider';
import {RegattaMode} from '../../../models/edition';

export class LoginPresenter implements Presenter {
    @observable public loading = true;
    @observable public showCreateNewEdition = false;
    @observable public showWrongKey = false;
    @observable public name: string = '';
    @observable public handicapsSystem: string = '2019_Short';
    @observable public mode: RegattaMode = RegattaMode.Head;
    @observable public token: string = '';
    private _editionInteractor: EditionInteractor;
    private _authProvider: AuthProvider;

    constructor(editionInteractor: EditionInteractor, authProvider: AuthProvider) {
        this._editionInteractor = editionInteractor;
        this._authProvider = authProvider;
    }

    public async mount(): Promise<void> {
        try {
            const edition = await this._authProvider.edition();
            if (edition !== null) {
                location.href = '/edition/heats';
            } else {
                this.loading = false;
            }
        } catch (e) {
            this.loading = false;
        }
    }

    public unmount(): void {
        /* Noop */
    }

    public onCreateEditionClick() {
        this.showCreateNewEdition = true;
    }

    public onBackClick() {
        this.showCreateNewEdition = false;
    }

    public onTokenChange(token: string) {
        this.token = token.toUpperCase();
    }

    public async onEnterClick(e: any) {
        e.preventDefault();
        const token = this.token;
        await this.authenticate(token);
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onModeChange(mode: RegattaMode) {
        this.mode = mode;
        switch (mode) {
            case RegattaMode.OIJR:
                this.handicapsSystem = '2019_Long';
                break;
            case RegattaMode.Head:
            case RegattaMode.Sprint:
            case RegattaMode.TWW:
            default:
                this.handicapsSystem = '2019_Short';
                break;
        }
    }

    public async onCreateEditionSubmit() {
        try {
            const edition = await this._editionInteractor.create(this.name, this.handicapsSystem, this.mode);
            await this.authenticate(edition.token);
        } finally {
            this.loading = false;
        }
    }

    private async authenticate(token: string) {
        if ((await this._authProvider.login(token)).error === false) {
            window.location.href = '/edition/heats';
        } else {
            this.showWrongKey = true;
        }
    }
}
