import * as React from 'react';
import {Heat} from '../../../models/heat';
import {useObservable} from '../support/hooks/use_observable';
import {usePresenter} from '../support/use_presenter';
import {formatTimeMsUTCLowPrecision} from '../../../administration/support/format_time_ms';
import {distinctUntilChanged, map} from 'rxjs/operators';

interface OwnProps {
    heat: Heat;
}

export const Timer = ({heat}: OwnProps) => {
    const presenter = usePresenter(component => ({
        times: component
            .trackingController(heat.id)
            .times()
            .pipe(map(formatTimeMsUTCLowPrecision), distinctUntilChanged()),
    }));

    const time = useObservable(presenter.times, null);

    return <>{time}</>;
};
