import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography/Typography';
import {Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import {Heat} from '../../../../../../models/heat';
import {toAbsoluteUrl} from '../../../../../../support/url';

const QRCode = require('qrcode.react');

interface OwnProps {
    heat: Heat;
    open: boolean;
    onClose: () => void;
}

const styles = (theme: Theme) => ({
    paper: {
        position: 'absolute' as any,
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
});

class UnstyledTrackTimesModal extends React.Component<OwnProps & WithStyles<'paper'>> {
    public render() {
        const url = toAbsoluteUrl('/tracking/heat/' + this.props.heat.id);
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={this.props.onClose}>
                <div className={this.props.classes.paper}>
                    <Typography variant="h6">Open tracking screen</Typography>
                    <Typography variant="subtitle1">
                        Send this link to you mobile device to start tracking times for this heat
                    </Typography>
                    <Typography variant="body1">
                        <QRCode value={url} />
                    </Typography>
                    <Typography variant="body1">
                        <a href={url} target="_blank">
                            {url}
                        </a>
                    </Typography>
                    <Button
                        style={{float: 'right'}}
                        component={(props: any) => <a onClick={() => this.props.onClose()} {...props} />}>
                        Close
                    </Button>
                </div>
            </Modal>
        );
    }
}

export const TrackTimesModal = withStyles(styles)(UnstyledTrackTimesModal);
