import {HandicapTable} from '../handicap_table';

export class Handicap2023Short extends HandicapTable {
    protected create(): void {
        this.handicaps.set(13, {male: 0.876, female: 0.788});
        this.handicaps.set(14, {male: 0.912, female: 0.82});
        this.handicaps.set(15, {male: 0.936, female: 0.842});
        this.handicaps.set(16, {male: 0.952, female: 0.857});
        this.handicaps.set(17, {male: 0.963, female: 0.867});
        this.handicaps.set(18, {male: 0.972, female: 0.875});
        this.handicaps.set(19, {male: 0.979, female: 0.881});
        this.handicaps.set(20, {male: 0.986, female: 0.887});
        this.handicaps.set(21, {male: 0.992, female: 0.893});
        this.handicaps.set(22, {male: 0.997, female: 0.898});
        this.handicaps.set(23, {male: 1, female: 0.9});
        this.handicaps.set(24, {male: 1, female: 0.9});
        this.handicaps.set(25, {male: 1, female: 0.9});
        this.handicaps.set(26, {male: 1, female: 0.9});
        this.handicaps.set(27, {male: 0.997, female: 0.897});
        this.handicaps.set(28, {male: 0.994, female: 0.895});
        this.handicaps.set(29, {male: 0.992, female: 0.893});
        this.handicaps.set(30, {male: 0.99, female: 0.891});
        this.handicaps.set(31, {male: 0.989, female: 0.89});
        this.handicaps.set(32, {male: 0.987, female: 0.888});
        this.handicaps.set(33, {male: 0.986, female: 0.887});
        this.handicaps.set(34, {male: 0.984, female: 0.886});
        this.handicaps.set(35, {male: 0.982, female: 0.884});
        this.handicaps.set(36, {male: 0.98, female: 0.882});
        this.handicaps.set(37, {male: 0.978, female: 0.88});
        this.handicaps.set(38, {male: 0.976, female: 0.878});
        this.handicaps.set(39, {male: 0.973, female: 0.876});
        this.handicaps.set(40, {male: 0.97, female: 0.873});
        this.handicaps.set(41, {male: 0.967, female: 0.871});
        this.handicaps.set(42, {male: 0.964, female: 0.868});
        this.handicaps.set(43, {male: 0.961, female: 0.865});
        this.handicaps.set(44, {male: 0.957, female: 0.862});
        this.handicaps.set(45, {male: 0.954, female: 0.858});
        this.handicaps.set(46, {male: 0.95, female: 0.855});
        this.handicaps.set(47, {male: 0.946, female: 0.852});
        this.handicaps.set(48, {male: 0.942, female: 0.848});
        this.handicaps.set(49, {male: 0.939, female: 0.845});
        this.handicaps.set(50, {male: 0.935, female: 0.841});
        this.handicaps.set(51, {male: 0.931, female: 0.838});
        this.handicaps.set(52, {male: 0.927, female: 0.834});
        this.handicaps.set(53, {male: 0.923, female: 0.831});
        this.handicaps.set(54, {male: 0.919, female: 0.827});
        this.handicaps.set(55, {male: 0.916, female: 0.824});
        this.handicaps.set(56, {male: 0.912, female: 0.821});
        this.handicaps.set(57, {male: 0.908, female: 0.817});
        this.handicaps.set(58, {male: 0.904, female: 0.814});
        this.handicaps.set(59, {male: 0.9, female: 0.81});
        this.handicaps.set(60, {male: 0.896, female: 0.806});
        this.handicaps.set(61, {male: 0.892, female: 0.802});
        this.handicaps.set(62, {male: 0.887, female: 0.798});
        this.handicaps.set(63, {male: 0.883, female: 0.794});
        this.handicaps.set(64, {male: 0.878, female: 0.79});
        this.handicaps.set(65, {male: 0.872, female: 0.785});
        this.handicaps.set(66, {male: 0.867, female: 0.78});
        this.handicaps.set(67, {male: 0.861, female: 0.775});
        this.handicaps.set(68, {male: 0.854, female: 0.769});
        this.handicaps.set(69, {male: 0.847, female: 0.763});
        this.handicaps.set(70, {male: 0.84, female: 0.756});
        this.handicaps.set(71, {male: 0.832, female: 0.748});
        this.handicaps.set(72, {male: 0.823, female: 0.74});
        this.handicaps.set(73, {male: 0.813, female: 0.732});
        this.handicaps.set(74, {male: 0.803, female: 0.722});
        this.handicaps.set(75, {male: 0.791, female: 0.712});
        this.handicaps.set(76, {male: 0.779, female: 0.701});
        this.handicaps.set(77, {male: 0.767, female: 0.69});
        this.handicaps.set(78, {male: 0.753, female: 0.678});
        this.handicaps.set(79, {male: 0.739, female: 0.665});
        this.handicaps.set(80, {male: 0.723, female: 0.651});
        this.handicaps.set(81, {male: 0.707, female: 0.637});
        this.handicaps.set(82, {male: 0.691, female: 0.622});
        this.handicaps.set(83, {male: 0.674, female: 0.606});
        this.handicaps.set(84, {male: 0.656, female: 0.59});
        this.handicaps.set(85, {male: 0.638, female: 0.574});
        this.handicaps.set(86, {male: 0.62, female: 0.558});
        this.handicaps.set(87, {male: 0.602, female: 0.542});
        this.handicaps.set(88, {male: 0.584, female: 0.526});
        this.handicaps.set(89, {male: 0.567, female: 0.511});
        this.handicaps.set(90, {male: 0.551, female: 0.496});
        this.handicaps.set(91, {male: 0.536, female: 0.482});
    }
}
