import {ServerTimeProvider} from './server_time_provider';
import {Observable} from 'rxjs';

export class SharedServerTimeProvider implements ServerTimeProvider {
    private _serverTimeProvider: ServerTimeProvider;
    private _observable: Observable<number> | undefined;

    constructor(serverTimeProvider: ServerTimeProvider) {
        this._serverTimeProvider = serverTimeProvider;
    }

    public getTime(): Observable<number> {
        if (this._observable === undefined) {
            this._observable = this._serverTimeProvider.getTime();
        }

        return this._observable;
    }
}
