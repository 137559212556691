import {usePresenterHookFactory} from './hooks/use_presenter_hook_factory';
import {ClientComponent, DefaultClientComponent} from '../../main/client_component';
import {DefaultPersistenceComponent} from '../../../administration/persistence/persistence_component';

/**
 * usePresenter hook to initialise presenters
 */
export const usePresenter = usePresenterHookFactory<ClientComponent>(
    new DefaultClientComponent(new DefaultPersistenceComponent()),
);
