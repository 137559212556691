import {useEffect, useState} from 'react';

export interface Presenter {
    //Meh https://blog.mariusschulz.com/2017/12/01/typescript-2-4-weak-type-detection#workarounds-for-weak-types
    [key: string]: any;

    mount?(): any;

    unmount?(): any;
}

export function usePresenterHookFactory<C>(component: C) {
    return function usePresenter<P extends Presenter>(createPresenterCallback: (component: C) => P): P {
        const [presenter, _] = useState<P>(() => createPresenterCallback(component));

        useEffect(
            () => {
                if (presenter.mount) {
                    presenter.mount();
                }
                return () => {
                    if (presenter.unmount) {
                        presenter.unmount();
                    }
                };
            },
            [presenter],
        );

        return presenter;
    };
}
