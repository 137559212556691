import {Team} from '../../../../models/team';
import {RecordedTime} from '../../../../models/recorded_time';
import {Participant} from '../../../../models/participant';
import {CorrectedTimeCalculator} from '../../../support/corrected_time_calculator';
import {YearProvider} from '../../../support/year_provider';
import {buildHandicapTable} from '../../../support/corrected_time_calculator/rower_handicap';
import {ResultMode} from '../result_mode';

export class TeamsWithTimesCombiner {
    constructor(private yearProvider: YearProvider) {}

    public combine(
        mode: ResultMode,
        teams: Team[],
        recordedTimes: RecordedTime[],
        participants: Participant[],
    ): TeamWithTimes[] {
        const correctedTimeCalculator = new CorrectedTimeCalculator(
            this.yearProvider.getYear(),
            buildHandicapTable(mode),
        );

        return this.getTeamsWithTimes(teams, recordedTimes).map(teamWithTimes => {
            const participantsForTeam = this.getParticipantsForTeam(teamWithTimes.team, participants);

            if (teamWithTimes.finish === null || teamWithTimes.start === null) {
                return {
                    ...teamWithTimes,
                    participants: participantsForTeam,
                    absoluteTimeMs: null,
                    displayTimeMs: null,
                    competingTimeMs: null,
                };
            }
            const absoluteTimeMs = teamWithTimes.finish.timeMs - teamWithTimes.start.timeMs;
            return {
                ...teamWithTimes,
                participants: participantsForTeam,
                absoluteTimeMs: absoluteTimeMs,
                displayTimeMs:
                    mode === ResultMode.Absolute || mode === ResultMode.Rugnummer
                        ? absoluteTimeMs
                        : correctedTimeCalculator.calculate(
                              absoluteTimeMs,
                              teamWithTimes.team.category,
                              participantsForTeam,
                          ),
                competingTimeMs:
                    mode === ResultMode.Absolute || mode === ResultMode.Rugnummer
                        ? absoluteTimeMs //TODO add punishment
                        : correctedTimeCalculator.calculate(
                              absoluteTimeMs, //TODO add punishment
                              teamWithTimes.team.category,
                              this.getParticipantsForTeam(teamWithTimes.team, participants),
                          ),
            };
        });
    }

    private getTeamsWithTimes(teams: Team[], recordedTimes: RecordedTime[]) {
        return teams.map(team => {
            return {
                team: team,
                start: recordedTimes.find(recordedTime => recordedTime.id === team.recordedStartTimeId) || null,
                finish: recordedTimes.find(recordedTime => recordedTime.id === team.recordedFinishTimeId) || null,
            };
        });
    }

    private getParticipantsForTeam(team: Team, participants: Participant[]) {
        const rowerIds = [
            team.rower1Id,
            team.rower2Id,
            team.rower3Id,
            team.rower4Id,
            team.rower5Id,
            team.rower6Id,
            team.rower7Id,
            team.rower8Id,
        ];
        return participants.filter(participant => rowerIds.indexOf(participant.id) !== -1);
    }
}

export interface TeamWithTimes {
    team: Team;
    participants: Participant[];
    start: RecordedTime | null;
    finish: RecordedTime | null;
    //Absolute time recorded
    absoluteTimeMs: number | null;
    //Time to be displayed to the public
    displayTimeMs: number | null;
    //Time that should be used to calculate position
    competingTimeMs: number | null;
}
