import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Content from '../../components/content';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {Link} from 'react-router-dom';
import Header from '../../components/header';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {HeatsPresenter} from './heats_presenter';
import {Edition} from '../../../../../models/edition';
import {observer} from 'mobx-react';
import {formatTimeMsClientTimeLowPreciesion} from '../../../../support/format_time_ms';
import {ViewIcon} from '../../../components/icons/view_icon';

interface OwnProps {
    edition: Edition;
}

@observer
class Heats extends React.Component<OwnProps & PresenterProps<HeatsPresenter>> {
    public render() {
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Heats',
                            link: '/edition/heats',
                        },
                    ]}>
                    Heats
                </Header>
                <Content>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Published</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.presenter.heats.map(heat => {
                                return (
                                    <TableRow key={heat.id}>
                                        <TableCell>{heat.number}</TableCell>
                                        <TableCell>
                                            {heat.published ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </TableCell>
                                        <TableCell>{heat.name}</TableCell>
                                        <TableCell>
                                            {formatTimeMsClientTimeLowPreciesion(heat.targetStartTimeMs)}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/edition/heat/${heat.id}`}>
                                                <ViewIcon />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Content>
                <Button
                    variant="fab"
                    color="primary"
                    aria-label="add"
                    style={{float: 'right', margin: 20}}
                    component={props => <Link to="/edition/heats/add" {...props as any} />}>
                    <AddIcon />
                </Button>
            </Grid>
        );
    }
}

export default withPresenter<HeatsPresenter, OwnProps>(
    (props, component) => new HeatsPresenter(props.edition, component.persistence.heatRepository),
    Heats,
);
