import {ResultRequest} from '../../../models/result_request';
import {Team} from '../../../models/team';
import {RecordedTime} from '../../../models/recorded_time';
import {ResultGroup} from './models/resultGroup';
import {Participant} from '../../../models/participant';
import {DefaultResultGrouper} from './internal/team_grouper';
import {Result} from './models/result';
import {TeamsWithTimesCombiner, TeamWithTimes} from './internal/teams_with_times_combiner';
import {YearProvider} from '../../support/year_provider';
import {TeamWithTimesSorter} from './internal/team_with_times_sorter';
import {ResultFormatter} from './internal/result_formatter';
import {HeatRepository} from '../../persistence/heat/heat_repository';
import {Heat} from '../../../models/heat';
import {first} from 'rxjs/operators';

export interface ResultBuilder {
    build(
        resultRequest: ResultRequest,
        teams: Team[],
        recordedTimes: RecordedTime[],
        participants: Participant[],
    ): Promise<ResultGroup[]>;
}

export interface ResultGrouper {
    group(resultRequest: ResultRequest, teams: Team[]): Promise<Array<{name: string; teams: Team[]}>>;
}

export class DefaultResultBuilder implements ResultBuilder {
    private grouper = new DefaultResultGrouper(this.heatRepository);
    private timesCombiner = new TeamsWithTimesCombiner(this.yearProvider);
    private teamWithTimesSorter = new TeamWithTimesSorter();
    private resultFormatter = new ResultFormatter();

    constructor(private heatRepository: HeatRepository, private yearProvider: YearProvider) {}

    public async build(
        resultRequest: ResultRequest,
        teams: Team[],
        recordedTimes: RecordedTime[],
        participants: Participant[],
    ): Promise<ResultGroup[]> {
        const groups = await this.grouper.group(resultRequest, teams);
        const heats = await this.heatRepository
            .findByEditionId(resultRequest.editionId)
            .pipe(first())
            .toPromise();

        return groups.map(group => {
            return {
                name: group.name,
                timeTitle: this.resultFormatter.getTimeTitle(resultRequest),
                results: this.resultsForTeams(resultRequest, heats, group.teams, participants, recordedTimes),
            };
        });
    }

    private resultsForTeams(
        resultRequest: ResultRequest,
        heats: Heat[],
        teams: Team[],
        participants: Participant[],
        recordedTimes: RecordedTime[],
    ): Result[] {
        const teamsWithTimes = this.timesCombiner
            .combine(resultRequest.mode, teams, recordedTimes, participants)
            .filter(teamWithTime => teamWithTime.finish !== null || this.heatIsFinished(heats, teamWithTime));

        return this.resultFormatter.format(this.teamWithTimesSorter.sort(resultRequest, teamsWithTimes));
    }

    private heatIsFinished(heats: Heat[], teamWithTime: TeamWithTimes) {
        const heat = heats.find(h => teamWithTime.team.heatId === h.id);

        return heat !== undefined && heat.endTimeMs !== null;
    }
}
