import * as React from 'react';
import styled from 'styled-components';

const Unprintable = styled.span`
    @media print {
        display: none;
    }
`;

export default Unprintable;
