import * as React from 'react';
import {ManageTimesScreenPresenter} from './manage_times_presenter';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {observer} from 'mobx-react';
import Header from '../components/header';
import Grid from '@material-ui/core/Grid';
import Content from '../components/content';
import ContentTitle from '../components/content_title';
import HeatTimer from '../components/heat_timer/heat_timer';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import ManagerRecordedTime from './manage_recorded_time/manage_recorded_time';
import Loading from '../../components/loading';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import AddIcon from '@material-ui/icons/Add';
import {Button} from '@material-ui/core';
import {AddTimeModal} from './add_time_modal';
import {Heat} from '../../../../models/heat';
import {StartPerformance} from './components/start_performance';
import {RecordedTime} from '../../../../models/recorded_time';

interface OwnProps {
    heatId: string;
}

@observer
class ManageTimesScreen extends React.Component<OwnProps & PresenterProps<ManageTimesScreenPresenter>> {
    private renderTimes(heat: Heat) {
        const recordedTimes: RecordedTime[] = this.props.presenter.recordedTimes.sort((a, b) => a.timeMs - b.timeMs);
        return (
            <Hidden xsDown>
                <Content>
                    <ContentTitle>Statistics</ContentTitle>
                    <StartPerformance recordedTimes={recordedTimes} teams={this.props.presenter.teams} />
                </Content>
                <Content>
                    <ContentTitle>Recorded times</ContentTitle>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Time (absolute)</TableCell>
                                <TableCell>Time (stopwatch)</TableCell>
                                <TableCell>Team</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recordedTimes.map(recordedTime => (
                                <ManagerRecordedTime
                                    key={recordedTime.id}
                                    heat={heat}
                                    teams={this.props.presenter.teams!!}
                                    recordedTime={recordedTime}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </Content>
            </Hidden>
        );
    }

    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        if (this.props.presenter.heat === null) {
            //TODO add fancy 404
            return <div>Could not find heat</div>;
        }
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Heats',
                            link: '/edition/heats',
                        },
                        {
                            name: this.props.presenter.heat.name,
                            link: '/edition/heat/' + this.props.presenter.heat.id,
                        },
                        {
                            name: 'Manage times',
                            link: '/edition/heat/' + this.props.presenter.heat.id + '/recorded-times',
                        },
                    ]}>
                    Manage tracked times for heat: {this.props.presenter.heat.name}
                    <div style={{float: 'right'}}>
                        <HeatTimer heatId={this.props.heatId} />
                    </div>
                </Header>

                {this.renderTimes(this.props.presenter.heat)}

                {this.props.presenter.addTimeModalVisible ? (
                    <AddTimeModal
                        heat={this.props.presenter.heat}
                        open={this.props.presenter.addTimeModalVisible}
                        onClose={() => this.props.presenter.onAddTimeModalClose()}
                        onAdd={time => this.props.presenter.onTimeAdd(time)}
                    />
                ) : null}
                <Button
                    variant="fab"
                    color="primary"
                    aria-label="add"
                    style={{float: 'right', margin: 20}}
                    component={props => <a {...props} onClick={() => this.props.presenter.showAddTimeModal()} />}>
                    <AddIcon />
                </Button>
            </Grid>
        );
    }
}

export default withPresenter<ManageTimesScreenPresenter, OwnProps>(
    (props, component) =>
        new ManageTimesScreenPresenter(
            props.heatId,
            component.persistence.heatRepository,
            component.persistence.teamRepository,
            component.business.heatInteractor,
            component.business.recordedTimeInteractor,
        ),
    ManageTimesScreen,
);
