import * as React from 'react';
import {RecordedTimePair} from '../../../tracking/models/recorded_time_pair';
import {RunningTimer} from './team_timer/running';
import {formatTimeMsUTC} from '../../../../administration/support/format_time_ms';

interface OwnProps {
    recordedTimePair: RecordedTimePair;
}

export const TeamTimer = ({recordedTimePair}: OwnProps) => {
    if (recordedTimePair.start !== null && recordedTimePair.finish === null) {
        return (
            <span style={{color: recordedTimePair.start.synced ? '#000' : '#a5c3c9'}}>
                {<RunningTimer recordedTime={recordedTimePair.start} />}
            </span>
        );
    }
    if (recordedTimePair.start !== null && recordedTimePair.finish !== null) {
        return (
            <span style={{color: recordedTimePair.finish.synced ? '#000' : '#a5c3c9'}}>
                {formatTimeMsUTC(recordedTimePair.finish.timeMs - recordedTimePair.start.timeMs)}
            </span>
        );
    }

    return <>{'-:--'}</>;
};
