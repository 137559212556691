export const genders = ['Female', 'Male', 'Unknown'];

export type Gender = 'Female' | 'Male' | 'Unknown';

export interface Participant {
    id: string;
    editionId: string;
    teamId: string;
    name: string;
    birthYear: number;
    membershipCode: string; //Normally membership code from Dutch Rowing association, but can be empty if unkown
    clubCode: string; //Abbreviation of the rowing club of the participant
    gender: Gender;
}
