import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {EditHeatPresenter} from './heats_edit_presenter';
import Header from '../../components/header';
import Grid from '@material-ui/core/Grid';
import Content from '../../components/content';
import {observer} from 'mobx-react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Loading from '../../../components/loading';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface OwnProps {
    heatId: string;
}

@observer
class EditHeatScreen extends React.Component<OwnProps & PresenterProps<EditHeatPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }

        return (
            <Grid item xs={12}>
                <Header>Edit heat</Header>
                <Content>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            id="name"
                            value={this.props.presenter.name}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="number">Number</InputLabel>
                        <Input
                            id="number"
                            value={this.props.presenter.number}
                            type="number"
                            onChange={(e: any) => this.props.presenter.onNumberChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="number">Start time</InputLabel>
                        <Input
                            id="number"
                            value={this.props.presenter.startTimeMs || ''}
                            type="number"
                            onChange={(e: any) => this.props.presenter.onStartTimeChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="number">Target start date & time</InputLabel>
                        <Input
                            id="number"
                            value={this.formatTargetStartTimeMsToDate(this.props.presenter.targetStartTimeMs)}
                            type="date"
                            onChange={(e: any) => this.props.presenter.onTargetStartDateChange(e.target.value)}
                        />
                        <Input
                            id="number"
                            value={this.formatTargetStartTimeMsToTime(this.props.presenter.targetStartTimeMs)}
                            type="time"
                            onChange={(e: any) => this.props.presenter.onTargetStartTimeChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <FormControlLabel
                            label="Published"
                            control={
                                <Checkbox
                                    checked={this.props.presenter.published}
                                    onChange={(e: any, checked: boolean) =>
                                        this.props.presenter.onPublishedChange(checked)
                                    }
                                />
                            }
                        />
                    </FormControl>
                    <Grid justify="flex-end" container>
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={this.props.presenter.loading}
                                style={{marginTop: 16}}
                                onClick={() => this.props.presenter.onSubmitClicked()}>
                                {this.props.presenter.loading ? 'Saving..' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Content>
            </Grid>
        );
    }

    private formatTargetStartTimeMsToDate(targetStartTimeMs: number | null) {
        const date = targetStartTimeMs === null ? new Date() : new Date(targetStartTimeMs);

        const ten = function(i: number) {
            return (i < 10 ? '0' : '') + i;
        };
        const YYYY = date.getFullYear();
        const MM = ten(date.getMonth() + 1);
        const DD = ten(date.getDate());

        return YYYY + '-' + MM + '-' + DD;
    }

    private formatTargetStartTimeMsToTime(targetStartTimeMs: number | null) {
        const date = targetStartTimeMs === null ? new Date() : new Date(targetStartTimeMs);

        const ten = function(i: number) {
            return (i < 10 ? '0' : '') + i;
        };
        const HH = ten(date.getHours());
        const II = ten(date.getMinutes());

        return HH + ':' + II;
    }
}

export default withPresenter<EditHeatPresenter, OwnProps>(
    (props, component) => new EditHeatPresenter(props.heatId, component.persistence.heatRepository),
    EditHeatScreen,
);
