import * as React from 'react';
import styled from 'styled-components';

const PrintPage = styled.span`
    display: block;
    break-after: page !important;
    margin: 5mm;
    @media print {
        margin: 5mm 10mm;
    }
`;

export default PrintPage;
