import {filter, map, scan} from 'rxjs/operators';
import {ServerTime, ServerTimeInteractor} from './server_time_interactor';
import {Observable} from 'rxjs';

interface ScanResult {
    value: ServerTime;
    isNew: boolean;
}

export class BestServerTimeInteractor implements ServerTimeInteractor {
    private _worst: ServerTime = {
        offsetMillis: Infinity,
        queryDurationMillis: Infinity,
        weight: 0,
    };
    private _delegate: ServerTimeInteractor;

    constructor(delegate: ServerTimeInteractor) {
        this._delegate = delegate;
    }

    public serverTimes(): Observable<ServerTime> {
        return this._delegate.serverTimes().pipe(
            scan(
                (currentBest: ScanResult, newOffset: ServerTime) => {
                    if (currentBest.value.queryDurationMillis >= newOffset.queryDurationMillis) {
                        return {
                            value: newOffset,
                            isNew: true,
                        } as State;
                    }
                    return {
                        value: currentBest.value,
                        isNew: false,
                    } as State;
                },
                {value: this._worst, isNew: false},
            ),
            filter(state => state.isNew),
            map(state => state.value),
        );
    }
}

interface State {
    value: ServerTime;
    isNew: boolean;
}
