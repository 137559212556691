import {Presenter} from '../../../../support/with_presenter';
import {computed, observable} from 'mobx';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Edition} from '../../../../../models/edition';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Heat} from '../../../../../models/heat';
import {Subscription} from 'rxjs';
import {categories, Category} from '../../../../../models/team';

export class AddTeamPresenter implements Presenter {
    @observable public loading: boolean = true;
    @observable public heats: Heat[] = [];
    @observable public name: string = '';
    @observable public boat: string = '';
    @observable public clubCode: string = '';
    @observable public category: Category = categories[0];
    @observable public tags: string[] = [];
    @observable public heatId: string = '';
    private _teamRepository: TeamRepository;
    private _edition: Edition;
    private _heatRepository: HeatRepository;
    private _subscription: Subscription | undefined;

    @computed
    get submitIsEnabled() {
        return (
            !this.loading &&
            this.heats !== undefined &&
            this.heatId !== '' &&
            this.name !== '' &&
            this.boat !== '' &&
            this.clubCode !== ''
        );
    }

    constructor(edition: Edition, teamRepository: TeamRepository, heatRepository: HeatRepository) {
        this._edition = edition;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
    }

    public mount(): void {
        this._subscription = this._heatRepository.findByEditionId(this._edition.id).subscribe((heats: Heat[]) => {
            this.loading = false;
            this.heats = heats;
        });
    }

    public unmount(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onClubCodeChange(clubCode: string) {
        this.clubCode = clubCode;
    }

    public onBoatChange(boat: string) {
        this.boat = boat;
    }

    public onCategoryChange(category: Category) {
        this.category = category;
    }

    public onTagsChange(tags: string[]) {
        this.tags = tags;
    }

    public onHeatIdChange(heatId: string) {
        this.heatId = heatId;
    }

    public async onSubmitClicked() {
        this.loading = true;
        try {
            await this._teamRepository.create(
                this._edition.id,
                this.heatId,
                this.name,
                this.boat,
                this.category,
                this.tags.map(tag => ({name: tag})),
                this.clubCode,
                '',
            );
            window.location.href = '/edition/teams';
        } finally {
            this.loading = false;
        }
    }
}
