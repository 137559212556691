import {RecordedTime} from '../../models/recorded_time';
import {LocalStorageDriver} from '../../administration/persistence/drivers/local_storage_driver';
import {Observable} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {map, switchMap} from 'rxjs/operators';

export interface TemporaryRecordedTimesManager {
    store(recordedTimes: RecordedTime[]): Promise<RecordedTime[]>;

    destroy(synchronizedRecordedTime: RecordedTime): Promise<void>;

    byHeatId(heatId: string): Observable<RecordedTime[]>;
}

export class DefaultTemporaryRecordedTimesManager implements TemporaryRecordedTimesManager {
    /**
     * Map of local storage drivers, one for every heat we're tracking
     */
    private localStorageDrivers = new Map<string, LocalStorageDriver<{[id: string]: RecordedTime}>>();

    /**
     * Local storage driver that keeps track of all the heats we have in memory
     */
    private registryLocalstorageDriver = new LocalStorageDriver<string[]>('temporary-recorded-times-registry');

    public byHeatId(heatId: string): Observable<RecordedTime[]> {
        return fromPromise(this.getDriver(heatId)).pipe(
            switchMap(driver => driver.stream()),
            map(recordedTimes => (recordedTimes === null ? [] : Object.values(recordedTimes))),
        );
    }

    public async store(recordedTimes: RecordedTime[]): Promise<RecordedTime[]> {
        const driver = await this.getDriver(recordedTimes[0].heatId);

        let times = await driver.get();
        if (times === null) {
            times = {};
        }

        recordedTimes.forEach(recordedTime => {
            // @ts-ignore
            times[recordedTime.id] = recordedTime;
        });

        await driver.set(times);

        return recordedTimes;
    }

    public async destroy(recordedTime: RecordedTime): Promise<void> {
        const driver = await this.getDriver(recordedTime.heatId);

        let times = await driver.get();
        if (times === null) {
            times = {};
        }

        delete times[recordedTime.id];

        await driver.set(times);
    }

    private async getDriver(heatId: string): Promise<LocalStorageDriver<{[id: string]: RecordedTime}>> {
        if (!this.localStorageDrivers.has(heatId)) {
            const driver = new LocalStorageDriver<{[id: string]: RecordedTime}>('temporary-recorded-times-' + heatId);
            this.localStorageDrivers.set(heatId, driver);

            await this.register(heatId);
        }

        return this.localStorageDrivers.get(heatId)!;
    }

    private async register(heatId: string) {
        let registry = await this.registryLocalstorageDriver.get();
        if (registry === null) {
            registry = [];
        }
        registry.push(heatId);
        await this.registryLocalstorageDriver.set(registry);
    }
}
