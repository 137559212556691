export const Blue = {
    50: '#e1e6ed',
    100: '#b4c0d3',
    200: '#8297b6',
    300: '#506d98',
    400: '#2b4d82',
    500: '#052e6c',
    600: '#042964',
    700: '#042359',
    800: '#031d4f',
    900: '#01123d',
    A100: '#738cff',
    A200: '#4062ff',
    A400: '#0d38ff',
    A700: '#002bf2',
    contrastDefaultColor: 'light',
};
