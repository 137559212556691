import {Heat} from '../../../models/heat';
import {HeatRepository} from '../../persistence/heat/heat_repository';
import {ServerTimeProvider} from '../../../server_time/server_time_provider';
import {first} from 'rxjs/internal/operators/first';

export class HeatInteractor {
    private _heatRepository: HeatRepository;
    private _serverTimeProvider: ServerTimeProvider;

    constructor(heatRepository: HeatRepository, serverTimeProvider: ServerTimeProvider) {
        this._heatRepository = heatRepository;
        this._serverTimeProvider = serverTimeProvider;
    }

    public async start(heat: Heat): Promise<Heat> {
        return this._heatRepository.update({
            ...heat,
            startTimeMs: await this._serverTimeProvider
                .getTime()
                .pipe(first())
                .toPromise(),
        });
    }

    public async stop(heat: Heat): Promise<Heat> {
        return this._heatRepository.update({
            ...heat,
            endTimeMs: await this._serverTimeProvider
                .getTime()
                .pipe(first())
                .toPromise(),
        });
    }

    public resume(heat: Heat): Promise<Heat> {
        return this._heatRepository.update({
            ...heat,
            endTimeMs: null,
        });
    }
}
