import * as React from 'react';
import Content from '../../components/content';
import {ResultGroup} from '../../../../business/results/models/resultGroup';
import {Table} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import {ResultRequest} from '../../../../../models/result_request';
import {ResultMode} from '../../../../business/results/result_mode';

interface OwnProps {
    resultRequest: ResultRequest;
    results: ResultGroup[];
}

export class ResultGroupTable extends React.PureComponent<OwnProps> {
    public render() {
        return (
            <>
                {this.props.results.map(result => {
                    return (
                        <Content key={result.name}>
                            <h2 style={{position: 'sticky', top: 0, backgroundColor: '#FFFFFF', width: '100%'}}>
                                {result.name}
                            </h2>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {this.props.resultRequest.mode !== ResultMode.Rugnummer ? (
                                            <TableCell>#</TableCell>
                                        ) : null}
                                        <TableCell>Rug</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>{result.timeTitle}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {result.results.map(item => (
                                        <TableRow key={item.team.id}>
                                            {this.props.resultRequest.mode !== ResultMode.Rugnummer ? (
                                                <TableCell>{item.position}</TableCell>
                                            ) : null}
                                            <TableCell>{item.team.number}</TableCell>
                                            <TableCell>{item.team.name}</TableCell>
                                            <TableCell>{item.displayTime}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Content>
                    );
                })}
            </>
        );
    }
}
