import {Observable, of} from 'rxjs';
import {ServerTimeProvider} from '../../server_time/server_time_provider';
import {HeatRepository} from './repositories/heat_repository';
import {map, switchMap} from 'rxjs/operators';
import {Heat} from '../../models/heat';
import {animationFrames} from '../support/observables/animation_frames';

export interface HeatTimeProvider {
    time(): Observable<number | null>;
}

export class DefaultHeatTimeProvider implements HeatTimeProvider {
    private _heatId: string;
    private _serverTimeProvider: ServerTimeProvider;
    private _heatRepository: HeatRepository;

    constructor(heatId: string, serverTimeProvider: ServerTimeProvider, heatRepository: HeatRepository) {
        this._heatId = heatId;
        this._serverTimeProvider = serverTimeProvider;
        this._heatRepository = heatRepository;
    }

    public time(): Observable<number | null> {
        return this._heatRepository.findByIdMaybe(this._heatId).pipe(
            switchMap(heatMaybe =>
                heatMaybe.caseOf({
                    nothing: () => of(null),
                    just: heat => this.toObservable(heat),
                }),
            ),
        );
    }

    private toObservable(heat: Heat) {
        if (heat.startTimeMs === null) {
            return of(null);
        } else if (heat.endTimeMs !== null) {
            return of(heat.endTimeMs - heat.startTimeMs);
        } else {
            const startTimeMs = heat.startTimeMs;
            return animationFrames().pipe(
                switchMap(() => this._serverTimeProvider.getTime()),
                map(time => time - startTimeMs),
            );
        }
    }
}
