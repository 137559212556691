import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {TopBarPresenter} from './top_bar_presenter';
import {observer} from 'mobx-react';
import Hidden from '@material-ui/core/Hidden';
import {Clock} from './clock/clock';

interface OwnProps {}

@observer
class TopBar extends React.Component<OwnProps & PresenterProps<TopBarPresenter>> {
    public render() {
        return (
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="title" color="inherit" style={{flex: 1}}>
                        WedstrijdKlok{' '}
                        {this.props.presenter.edition !== null ? (
                            <Hidden mdDown> - {this.props.presenter.edition.name}</Hidden>
                        ) : null}
                    </Typography>
                    <Clock />
                    {this.props.presenter.edition !== null ? (
                        <Hidden mdDown>
                            <Typography>{this.props.presenter.edition.token}</Typography>
                        </Hidden>
                    ) : null}

                    <Button color="secondary" onClick={() => this.props.presenter.onLogoutClick()}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withPresenter<TopBarPresenter, OwnProps>(
    (props, component) => new TopBarPresenter(component.authProvider),
    TopBar,
);
