import * as React from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

interface OwnProps {
    value: string[];
    onChange: (tags: string[]) => void;
}

export class TagsInputWrapper extends React.Component<OwnProps> {
    public render() {
        return <TagsInput {...this.props} value={this.props.value} onChange={tags => this.props.onChange(tags)} />;
    }
}
