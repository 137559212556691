import {Blue} from './colors/blue';
import {ThemeOptions} from '@material-ui/core/styles/createMuiTheme';

const themeConfig: ThemeOptions = {
    palette: {
        primary: Blue,
        secondary: {
            light: '#7986cb',
            main: '#6981b5',
            dark: '#303f9f',
            contrastText: '#fff',
        },
    },
};
export default themeConfig;
