import {Presenter} from '../../../../support/with_presenter';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Edition} from '../../../../../models/edition';
import {Team} from '../../../../../models/team';
import {computed, observable} from 'mobx';
import {Subscription} from 'rxjs';

export class TeamsPresenter implements Presenter {
    @observable public loading: boolean = true;
    @observable
    public activeFilterValue: string = '-1';

    @computed
    public get teams(): Team[] {
        return this._teams.filter(team => {
            switch (this.activeFilterValue) {
                case 'not-attending':
                    return !team.isAttending;
                default:
                    return true;
            }
        });
    }

    @observable private _teams: Team[] = [];
    private _edition: Edition;
    private _teamRepository: TeamRepository;
    private _subscription: Subscription | undefined;

    constructor(edition: Edition, teamRepository: TeamRepository) {
        this._edition = edition;
        this._teamRepository = teamRepository;
    }

    public mount(): void {
        this._subscription = this._teamRepository.findByEditionId(this._edition.id).subscribe(teams => {
            this._teams = teams;
            this.loading = false;
        });
    }

    public unmount(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    public onFilterChange(e: any) {
        this.activeFilterValue = e.target.value;
    }
}
