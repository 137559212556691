import * as React from 'react';
import {ViewTeamScreenPresenter} from './teams_view_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import Content from '../../components/content';
import Header from '../../components/header';
import Grid from '@material-ui/core/Grid';
import {observer} from 'mobx-react';
import AddIcon from '@material-ui/icons/Add';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Team} from '../../../../../models/team';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Loading from '../../../components/loading';
import EditTeamScreen from './edit/teams_edit';
import {EditIcon} from '../../../components/icons/edit_icon';

interface OwnProps {
    teamId: string;
}

@observer
class ViewTeamScreen extends React.Component<OwnProps & PresenterProps<ViewTeamScreenPresenter>> {
    private renderParticipants() {
        if (this.props.presenter.participants === null) {
            return <Loading />;
        }
        return (
            <Content>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Birth year</TableCell>
                            <TableCell>Membership code</TableCell>
                            <TableCell>Club code</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.presenter.participants.map(participant => {
                            return (
                                <TableRow key={participant.id}>
                                    <TableCell>{participant.name}</TableCell>
                                    <TableCell>{participant.birthYear}</TableCell>
                                    <TableCell>{participant.membershipCode}</TableCell>
                                    <TableCell>{participant.clubCode}</TableCell>
                                    <TableCell>
                                        <Link to={`/edition/participant/${participant.id}`}>
                                            <EditIcon />
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Content>
        );
    }

    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        if (this.props.presenter.team === null) {
            //TODO add fancy 404
            return <div>Could not find team</div>;
        }
        const team: Team = this.props.presenter.team;
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Teams',
                            link: '/edition/teams',
                        },
                        {
                            name: 'Team: ' + team.name,
                            link: '/edition/team/' + team.id,
                        },
                    ]}>
                    Team: {team.name}
                </Header>
                <EditTeamScreen team={team} participants={this.props.presenter.participants!!} />
                {this.renderParticipants()}
                <Button
                    variant="fab"
                    color="primary"
                    aria-label="add"
                    style={{float: 'right', margin: 20}}
                    component={props => <Link to={`/edition/team/${team.id}/participants/add`} {...props as any} />}>
                    <AddIcon />
                </Button>
            </Grid>
        );
    }
}

export default withPresenter<ViewTeamScreenPresenter, OwnProps>(
    (props, component) =>
        new ViewTeamScreenPresenter(
            props.teamId,
            component.persistence.teamRepository,
            component.persistence.participantRepository,
        ),
    ViewTeamScreen,
);
