import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import {observer} from 'mobx-react';
import {LoginPresenter} from './login_presenter';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import Loading from '../components/loading';
import {Grid} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {RegattaMode} from '../../../models/edition';

@observer
class Login extends React.Component<PresenterProps<LoginPresenter>> {
    private renderLogin() {
        return (
            <form onSubmit={e => this.props.presenter.onEnterClick(e)}>
                <Paper key="login" style={{margin: '30px auto', padding: '60px', width: '200px', height: '200px'}}>
                    <Typography variant="title" color="inherit">
                        Access edition
                    </Typography>
                    <TextField
                        placeholder="Enter edition key"
                        style={{width: '100%', marginTop: '20px'}}
                        value={this.props.presenter.token}
                        onChange={(e: any) => this.props.presenter.onTokenChange(e.target.value)}
                    />
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{margin: '20px 0'}}
                        onClick={e => this.props.presenter.onEnterClick(e)}>
                        Enter
                    </Button>
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{margin: '0 auto'}}
                        onClick={() => this.props.presenter.onCreateEditionClick()}>
                        Create new edition
                    </Button>
                </Paper>
            </form>
        );
    }

    private renderCreateEdition() {
        return (
            <Paper key="create" style={{margin: '30px auto', padding: '60px', width: '200px', height: '200px'}}>
                <Typography variant="title" color="inherit">
                    Create edition
                </Typography>
                <FormControl fullWidth>
                    <TextField
                        placeholder="Enter name of edition"
                        style={{width: '100%', marginTop: '20px'}}
                        value={this.props.presenter.name}
                        onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <Select
                        value={this.props.presenter.mode}
                        onChange={(e: any) => this.props.presenter.onModeChange(e.target.value)}
                        color="primary">
                        {Object.values(RegattaMode).map(mode => {
                            let name = mode.toLocaleUpperCase();
                            if (mode === RegattaMode.TWW) {
                                name = 'Twentse Winter Wedstrijden';
                            }
                            if (mode === RegattaMode.OIJR) {
                                name = 'Oude IJsselrace';
                            }
                            return (
                                <MenuItem key={mode} value={mode}>
                                    {name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '20px 0', float: 'right'}}
                    onClick={() => this.props.presenter.onCreateEditionSubmit()}>
                    Create
                </Button>
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '20px 0', float: 'left'}}
                    onClick={() => this.props.presenter.onBackClick()}>
                    Back
                </Button>
            </Paper>
        );
    }

    public render() {
        return (
            <div>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="title" color="inherit">
                            WedstrijdKlok
                        </Typography>
                    </Toolbar>
                </AppBar>
                {this.props.presenter.loading ? (
                    <Grid container spacing={8}>
                        <span style={{margin: '30px auto'}}>
                            <Loading />
                        </span>
                    </Grid>
                ) : (
                    this.renderScreen()
                )}
            </div>
        );
    }

    private renderScreen() {
        return this.props.presenter.showCreateNewEdition ? this.renderCreateEdition() : this.renderLogin();
    }
}

export default withPresenter(
    (props, component) => new LoginPresenter(component.business.editionInteractor, component.authProvider),
    Login,
);
