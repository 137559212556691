import * as React from 'react';
import styled from 'styled-components';

const PrintOnly = styled.div`
    display: none;
    @media print {
        display: inherit;
    }
`;

export default PrintOnly;
