import {first} from 'rxjs/operators';
import {Presenter} from '../../../../../support/with_presenter';
import {computed, observable} from 'mobx';
import {TeamRepository} from '../../../../../persistence/team/team_repository';
import {HeatRepository} from '../../../../../persistence/heat/heat_repository';
import {Heat} from '../../../../../../models/heat';
import {categories, Category, Team} from '../../../../../../models/team';
import {CompositeSubscription} from '../../../../../support/composit_subscription';

export class EditTeamPresenter implements Presenter {
    @observable public loading: boolean = true;
    @observable public saving: boolean = false;
    @observable public heats: Heat[] = [];
    @observable public number: string = '';
    @observable public name: string = '';
    @observable public isAttending: boolean = true;
    @observable public isInCompetition: boolean = true;
    @observable public notes: string = '';
    @observable public boat: string = '';
    @observable public clubCode: string = '';
    @observable public category: Category = categories[0];
    @observable public tags: string[] = [];
    @observable public heatId: string = '';
    @observable public coxswainId: string = '';
    @observable public rower1Id: string = '';
    @observable public rower2Id: string = '';
    @observable public rower3Id: string = '';
    @observable public rower4Id: string = '';
    @observable public rower5Id: string = '';
    @observable public rower6Id: string = '';
    @observable public rower7Id: string = '';
    @observable public rower8Id: string = '';
    @observable public isQualified: boolean = true;
    @observable private teams: Team[] | undefined = undefined;

    @computed
    public get numberHelperText(): string {
        if (this.teams === undefined) {
            return '';
        }

        const s = [];
        if (this.number !== '') {
            const collision = this.teams.find(team => parseInt(this.number, 10) === team.number);
            if (collision !== undefined && collision.id !== this._team.id) {
                s.push('is the already in use');
            }
        }
        const numbersInHeat = this.teams
            .filter(team => this.heatId === team.heatId)
            .map(team => team.number)
            .filter(number => number !== null) as number[];
        if (numbersInHeat.length > 0) {
            const maxNumberInHeat = Math.max(...numbersInHeat);
            s.push('next up in this heat is ' + (maxNumberInHeat + 1));
        }

        const allUsedNumbers = this.teams.map(team => team.number).filter(number => number !== null) as number[];
        if (allUsedNumbers.length > 0) {
            const maxNumberOverall = Math.max(...allUsedNumbers);
            s.push('next up overall is ' + (maxNumberOverall + 1));
        }

        const helperText = s.join(', ');
        return helperText.charAt(0).toUpperCase() + helperText.slice(1);
    }

    @computed
    get submitIsEnabled() {
        return (
            !this.loading &&
            this.heats !== undefined &&
            this.teams !== undefined &&
            this.heatId !== '' &&
            this.name !== '' &&
            this.boat !== '' &&
            this.clubCode !== ''
        );
    }

    private _teamRepository: TeamRepository;
    private _heatRepository: HeatRepository;
    private _subscriptions = new CompositeSubscription();
    private _team: Team;

    constructor(team: Team, teamRepository: TeamRepository, heatRepository: HeatRepository) {
        this._team = team;
        this.heatId = team.heatId || '-1';
        this.number = team.number === null ? '' : '' + team.number;
        this.name = team.name;
        this.isAttending = team.isAttending;
        this.isInCompetition = team.isInCompetition;
        this.notes = team.notes;
        this.boat = team.boat;
        this.clubCode = team.clubCode;
        this.category = team.category;
        this.tags = team.tags.map(tag => tag.name);
        this.coxswainId = team.coxswainId || '';
        this.rower1Id = team.rower1Id || '';
        this.rower2Id = team.rower2Id || '';
        this.rower3Id = team.rower3Id || '';
        this.rower4Id = team.rower4Id || '';
        this.rower5Id = team.rower5Id || '';
        this.rower6Id = team.rower6Id || '';
        this.rower7Id = team.rower7Id || '';
        this.rower8Id = team.rower8Id || '';
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
    }

    public mount(): void {
        this._subscriptions.add(
            this._heatRepository.findByEditionId(this._team.editionId).subscribe((heats: Heat[]) => {
                this.loading = false;
                this.heats = heats;
            }),
        );
        this._subscriptions.add(
            this._teamRepository.findByEditionId(this._team.editionId).subscribe(teams => (this.teams = teams)),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    public onNumberChange(number: string) {
        this.number = number;
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onIsAttendingChange(isAttending: boolean) {
        this.isAttending = isAttending;
    }

    public onIsInCompetitionChange(isInCompetition: boolean) {
        this.isInCompetition = isInCompetition;
    }

    public onNotesChange(notes: string) {
        this.notes = notes;
    }

    public onClubCodeChange(clubCode: string) {
        this.clubCode = clubCode;
    }

    public onBoatChange(boat: string) {
        this.boat = boat;
    }

    public onCategoryChange(category: Category) {
        this.category = category;
    }

    public onTagsChange(tags: string[]) {
        this.tags = tags;
    }

    public onHeatIdChange(heatId: string) {
        this.heatId = heatId;
    }

    public onCoxswainIdChange(coxswainId: string) {
        this.coxswainId = coxswainId;
    }

    public onRower1IdChange(rower1Id: string) {
        this.rower1Id = rower1Id;
    }

    public onRower2IdChange(rower2Id: string) {
        this.rower2Id = rower2Id;
    }

    public onRower3IdChange(rower3Id: string) {
        this.rower3Id = rower3Id;
    }

    public onRower4IdChange(rower4Id: string) {
        this.rower4Id = rower4Id;
    }

    public onRower5IdChange(rower5Id: string) {
        this.rower5Id = rower5Id;
    }

    public onRower6IdChange(rower6Id: string) {
        this.rower6Id = rower6Id;
    }

    public onRower7IdChange(rower7Id: string) {
        this.rower7Id = rower7Id;
    }

    public onRower8IdChange(rower8Id: string) {
        this.rower8Id = rower8Id;
    }

    public onIsQualifiedChange(isQualified: boolean) {
        this.isQualified = isQualified;
    }

    public async onSubmitClicked() {
        this.saving = true;
        try {
            const team = await this._teamRepository
                .findById(this._team.id)
                .pipe(first())
                .toPromise();
            if (team !== null) {
                this._team = await this._teamRepository.update({
                    ...team,
                    heatId: this.heatId === '-1' ? null : this.heatId,
                    number: this.number === '' ? null : parseInt(this.number, 10),
                    name: this.name,
                    isAttending: this.isAttending,
                    isInCompetition: this.isInCompetition,
                    notes: this.notes,
                    clubCode: this.clubCode,
                    boat: this.boat,
                    category: this.category,
                    tags: this.tags.map(tag => ({name: tag})),
                    coxswainId: this.coxswainId !== '' ? this.coxswainId : null,
                    rower1Id: this.rower1Id !== '' ? this.rower1Id : null,
                    rower2Id: this.rower2Id !== '' ? this.rower2Id : null,
                    rower3Id: this.rower3Id !== '' ? this.rower3Id : null,
                    rower4Id: this.rower4Id !== '' ? this.rower4Id : null,
                    rower5Id: this.rower5Id !== '' ? this.rower5Id : null,
                    rower6Id: this.rower6Id !== '' ? this.rower6Id : null,
                    rower7Id: this.rower7Id !== '' ? this.rower7Id : null,
                    rower8Id: this.rower8Id !== '' ? this.rower8Id : null,
                });
            }
        } finally {
            this.saving = false;
        }
    }
}
