import * as React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Dialog from '@material-ui/core/Dialog/Dialog';
import {RecordedAs} from '../../../models/recorded_time';

interface OwnProps {
    recordedAs: RecordedAs | null;
    onChoose: (recordedAs: RecordedAs) => void;
}

export const RecordedAsDialog = ({recordedAs, onChoose}: OwnProps) => {
    return (
        <Dialog open={recordedAs === null}>
            <DialogTitle id="alert-dialog-title">{'What is your role?'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Select your role while tracking times, if you want to change this later on, just refresh the page.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onChoose(RecordedAs.Start)} color="primary">
                    Start
                </Button>
                <Button onClick={() => onChoose(RecordedAs.Finish)} color="primary" autoFocus>
                    Finish
                </Button>
            </DialogActions>
        </Dialog>
    );
};
