import {HeatRepository} from './heat_repository';
import {HeatRepository as TrackingHeatRepository} from '../../../client/tracking/repositories/heat_repository';
import {Heat} from '../../../models/heat';
import {v4 as uuid} from 'uuid';
import FirebaseDriver from '../drivers/firebase_driver';
import {Observable, Observer} from 'rxjs';
import {Maybe} from 'tsmonad';

interface FirebaseHeat {
    id: string;
    edition_id: string;
    name: string;
    number: number | null;
    published: boolean;
    start_time_ms: number | null;
    end_time_ms: number | null;
    target_time_ms: number | null;
}

export class FirebaseHeatRepository implements HeatRepository, TrackingHeatRepository {
    private _ref = 'heats';
    private _firebaseDriver: FirebaseDriver;

    constructor(firebaseDriver: FirebaseDriver) {
        this._firebaseDriver = firebaseDriver;
    }

    public async create(editionId: string, name: string, number: number): Promise<Heat> {
        const heat: Heat = {
            id: uuid(),
            editionId: editionId,
            name: name,
            number: number,
            published: false,
            startTimeMs: null,
            endTimeMs: null,
            targetStartTimeMs: null,
        };

        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(heat.id)
            .set(FirebaseHeatRepository.toFirebaseHeat(heat));

        return heat;
    }

    public async update(heat: Heat): Promise<Heat> {
        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(heat.id)
            .set(FirebaseHeatRepository.toFirebaseHeat(heat));

        return heat;
    }

    public findById(id: string): Observable<Heat | null> {
        return new Observable((observer: Observer<Heat | null>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('id')
                .equalTo(id);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseHeat} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const heat = result[Object.keys(result)[0]];
                        observer.next(FirebaseHeatRepository.fromFirebaseHeat(heat));
                    } else {
                        observer.next(null);
                    }
                }
            };

            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    public findByIdMaybe(id: string): Observable<Maybe<Heat>> {
        return new Observable((observer: Observer<Maybe<Heat>>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('id')
                .equalTo(id);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseHeat} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const heat = result[Object.keys(result)[0]];
                        observer.next(Maybe.just(FirebaseHeatRepository.fromFirebaseHeat(heat)));
                    } else {
                        observer.next(Maybe.nothing());
                    }
                }
            };

            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    public findByEditionId(editionId: string): Observable<Heat[]> {
        return new Observable((observer: Observer<Heat[]>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('edition_id')
                .equalTo(editionId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseHeat} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const heats = Object.keys(result)
                            .map(key => result[key])
                            .map(heat => FirebaseHeatRepository.fromFirebaseHeat(heat))
                            .sort(FirebaseHeatRepository.compareHeat);
                        observer.next(heats);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    private static toFirebaseHeat(heat: Heat): FirebaseHeat {
        return {
            id: heat.id,
            edition_id: heat.editionId,
            name: heat.name,
            number: heat.number,
            published: heat.published,
            start_time_ms: heat.startTimeMs,
            end_time_ms: heat.endTimeMs,
            target_time_ms: heat.targetStartTimeMs,
        };
    }

    private static fromFirebaseHeat(heat: FirebaseHeat): Heat {
        return {
            id: heat.id,
            editionId: heat.edition_id,
            name: heat.name,
            number: heat.number === null ? 0 : heat.number,
            published: heat.published === undefined ? false : heat.published,
            startTimeMs: heat.start_time_ms || null, //Make sure if undefined it will be null
            endTimeMs: heat.end_time_ms || null, //Make sure if undefined it will be null
            targetStartTimeMs: heat.target_time_ms || null, //Make sure if undefined it will be null
        };
    }

    private static compareHeat(a: Heat, b: Heat): number {
        if (a.number !== null && b.number !== null) {
            if (a.number < b.number) {
                return -1;
            } else if (a.number === b.number) {
                return 0;
            } else {
                return 1;
            }
        } else if (a.number === null) {
            return 1;
        } else {
            return -1;
        }
    }
}
