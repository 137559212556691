import {Observable, Subscriber} from 'rxjs';
import {share} from 'rxjs/operators';

const observable = new Observable((subscriber: Subscriber<any>) => {
    function next() {
        window.requestAnimationFrame(() => {
            subscriber.next();
            next();
        });
    }

    next();
}).pipe(share());

export const animationFrames = () => {
    return observable;
};
