import {EditionRepository} from './edition/edition_repository';
import {FirebaseEditionRepository} from './edition/firebase_edition_repository';
import FirebaseDriver from './drivers/firebase_driver';
import {HeatRepository} from './heat/heat_repository';
import {FirebaseHeatRepository} from './heat/firebase_heat_repository';
import {TeamRepository} from './team/team_repository';
import {FirebaseTeamRepository} from './team/firebase_team_repository';
import {ParticipantRepository} from './participant/participant_repository';
import {FirebaseParticipantRepository} from './participant/firebase_participant_repository';
import {RecordedTimeRepository} from './recorded_time/recorded_time_repository';
import {FirebaseRecordedTimeRepository} from './recorded_time/firebase_recorded_time_repository';
import {DefaultSessionRepository, SessionRepository} from './session/session_repository';
import {LocalStorageDriver} from './drivers/local_storage_driver';
import {ResultRequestRepository} from './result_request/result_request_repository';
import {lazy} from '../../client/support/lazy';
import {FirebaseResultRequestRepository} from './result_request/firebase_participant_repository';

export interface PersistenceComponent {
    editionRepository: EditionRepository;
    heatRepository: HeatRepository;
    teamRepository: TeamRepository;
    participantRepository: ParticipantRepository;
    recordedTimeRepository: RecordedTimeRepository;
    sessionRepository: SessionRepository;
    resultRequestRepository: ResultRequestRepository;
}

export class DefaultPersistenceComponent implements PersistenceComponent {
    @lazy()
    get editionRepository(): EditionRepository {
        return new FirebaseEditionRepository(this.firebaseDriver);
    }

    @lazy()
    get heatRepository(): HeatRepository {
        return new FirebaseHeatRepository(this.firebaseDriver);
    }

    @lazy()
    get teamRepository(): TeamRepository {
        return new FirebaseTeamRepository(this.firebaseDriver);
    }

    @lazy()
    get participantRepository(): ParticipantRepository {
        return new FirebaseParticipantRepository(this.firebaseDriver);
    }

    @lazy()
    get sessionRepository(): SessionRepository {
        return new DefaultSessionRepository(new LocalStorageDriver('sessions'));
    }

    @lazy()
    get recordedTimeRepository(): RecordedTimeRepository {
        return this.firebaseRecordedTimeRepository;
    }

    @lazy()
    public get firebaseRecordedTimeRepository(): FirebaseRecordedTimeRepository {
        return new FirebaseRecordedTimeRepository(this.firebaseDriver);
    }

    @lazy()
    public get resultRequestRepository(): ResultRequestRepository {
        return new FirebaseResultRequestRepository(this.firebaseDriver);
    }

    @lazy()
    private get firebaseDriver(): FirebaseDriver {
        return new FirebaseDriver();
    }
}
