import {TeamRepository} from './team_repository';
import {TeamRepository as TrackingTeamRepository} from '../../../client/tracking/repositories/team_repository';
import {Category, Team} from '../../../models/team';
import {v4 as uuid} from 'uuid';
import FirebaseDriver from '../drivers/firebase_driver';
import {Observable, Observer} from 'rxjs';
import * as firebase from 'firebase';
import {shareReplay} from 'rxjs/operators';
import {Tag} from '../../../models/tag';

interface FirebaseTeam {
    id: string;
    edition_id: string;
    heat_id: string | null;
    is_attending: number;
    is_in_competition: number;
    notes: string;
    recorded_start_time_id: string | null;
    recorded_finish_time_id: string | null;
    number: number | null;
    name: string;
    club_code: string;
    boat: string;
    category: Category;
    tags: string;
    coxswain_id: string | null;
    rower_1_id: string | null;
    rower_2_id: string | null;
    rower_3_id: string | null;
    rower_4_id: string | null;
    rower_5_id: string | null;
    rower_6_id: string | null;
    rower_7_id: string | null;
    rower_8_id: string | null;
    isQualified: boolean | null;
}

export class FirebaseTeamRepository implements TeamRepository, TrackingTeamRepository {
    private _ref = 'teams';
    private _firebaseDriver: FirebaseDriver;
    private _refInstance: firebase.database.Reference | undefined;

    constructor(firebaseDriver: FirebaseDriver) {
        this._firebaseDriver = firebaseDriver;
    }

    public async create(
        editionId: string,
        heatId: string,
        name: string,
        boat: string,
        category: Category,
        tags: Tag[],
        clubCode: string,
        notes: string,
    ): Promise<Team> {
        const team: Team = {
            id: uuid(),
            editionId: editionId,
            heatId: heatId,
            notes: notes,
            isAttending: true,
            isInCompetition: true,
            recordedStartTimeId: null,
            recordedFinishTimeId: null,
            number: null,
            name: name,
            clubCode: clubCode,
            boat: boat,
            category: category,
            tags: tags,
            coxswainId: null,
            rower1Id: null,
            rower2Id: null,
            rower3Id: null,
            rower4Id: null,
            rower5Id: null,
            rower6Id: null,
            rower7Id: null,
            rower8Id: null,
            isQualified: true,
        };

        await this.getRef()
            .child(team.id)
            .set(FirebaseTeamRepository.toFirebaseTeam(team));

        return team;
    }

    public async update(team: Team): Promise<Team> {
        await this.getRef()
            .child(team.id)
            .set(FirebaseTeamRepository.toFirebaseTeam(team));

        return team;
    }

    public findById(id: string): Observable<Team | null> {
        return new Observable<Team | null>((observer: Observer<Team | null>) => {
            const query = this.getRef()
                .orderByChild('id')
                .equalTo(id);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseTeam} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const team = result[Object.keys(result)[0]];
                        observer.next(FirebaseTeamRepository.fromFirebaseTeam(team));
                    } else {
                        observer.next(null);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        }).pipe(shareReplay(1));
    }

    public findByHeatId(heatId: string): Observable<Team[]> {
        return new Observable<Team[]>((observer: Observer<Team[]>) => {
            const query = this.getRef()
                .orderByChild('heat_id')
                .equalTo(heatId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseTeam} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const teams = Object.keys(result)
                            .map(key => result[key])
                            .map(team => FirebaseTeamRepository.fromFirebaseTeam(team));
                        observer.next(teams);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        }).pipe(shareReplay(1));
    }

    public findByEditionId(editionId: string): Observable<Team[]> {
        return new Observable((observer: Observer<Team[]>) => {
            const query = this.getRef()
                .orderByChild('edition_id')
                .equalTo(editionId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseTeam} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const teams = Object.keys(result)
                            .map(key => result[key])
                            .map(team => FirebaseTeamRepository.fromFirebaseTeam(team));
                        observer.next(teams);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    private getRef(): firebase.database.Reference {
        if (this._refInstance === undefined) {
            this._refInstance = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref);
        }
        return this._refInstance;
    }

    private static toFirebaseTeam(team: Team): FirebaseTeam {
        return {
            id: team.id,
            edition_id: team.editionId,
            heat_id: team.heatId,
            is_attending: team.isAttending === true ? 1 : 0,
            is_in_competition: team.isInCompetition === true ? 1 : 0,
            notes: team.notes,
            recorded_start_time_id: team.recordedStartTimeId,
            recorded_finish_time_id: team.recordedFinishTimeId,
            number: team.number,
            name: team.name,
            club_code: team.clubCode,
            boat: team.boat,
            category: team.category,
            tags: team.tags.map(tag => tag.name).join(','),
            coxswain_id: team.coxswainId,
            rower_1_id: team.rower1Id,
            rower_2_id: team.rower2Id,
            rower_3_id: team.rower3Id,
            rower_4_id: team.rower4Id,
            rower_5_id: team.rower5Id,
            rower_6_id: team.rower6Id,
            rower_7_id: team.rower7Id,
            rower_8_id: team.rower8Id,
            isQualified: team.isQualified || null,
        };
    }

    private static fromFirebaseTeam(team: FirebaseTeam): Team {
        return {
            id: team.id,
            editionId: team.edition_id,
            heatId: team.heat_id || null,
            isAttending: team.is_attending !== 0, //When undefined defaults to "true"
            isInCompetition: team.is_in_competition !== 0, //When undefined defaults to "true"
            notes: team.notes || '',
            recordedStartTimeId: team.recorded_start_time_id || null,
            recordedFinishTimeId: team.recorded_finish_time_id || null,
            number: team.number || null,
            name: team.name,
            clubCode: team.club_code || '',
            boat: team.boat,
            category: team.category,
            tags: team.tags ? team.tags.split(',').map(tag => ({name: tag})) : [],
            coxswainId: team.coxswain_id || null,
            rower1Id: team.rower_1_id || null,
            rower2Id: team.rower_2_id || null,
            rower3Id: team.rower_3_id || null,
            rower4Id: team.rower_4_id || null,
            rower5Id: team.rower_5_id || null,
            rower6Id: team.rower_6_id || null,
            rower7Id: team.rower_7_id || null,
            rower8Id: team.rower_8_id || null,
            isQualified: team.isQualified === null || team.isQualified === undefined ? true : team.isQualified,
        };
    }
}
