import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {TeamTimePresenter} from './team_time_presenter';
import {observer} from 'mobx-react';

interface OwnProps {
    teamId: string;
    start?: boolean;
    finish?: boolean;
}

@observer
class TeamTime extends React.Component<OwnProps & PresenterProps<TeamTimePresenter>> {
    public render() {
        return <span>{this.props.presenter.formattedTime}</span>;
    }
}

export default withPresenter<TeamTimePresenter, OwnProps>(
    (props, component) =>
        new TeamTimePresenter(
            props.teamId,
            props.start,
            props.finish,
            component.business.recordedTimeInteractor,
            component.business.serverTimeProvider,
        ),
    TeamTime,
);
