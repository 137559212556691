import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {formatTimeMsUTC} from '../../../../support/format_time_ms';
import {Team} from '../../../../../models/team';
import {RecordedTime} from '../../../../../models/recorded_time';
import {observer} from 'mobx-react';
import {ManageRecordedTimePresenter} from './manage_recorded_time_presenter';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Select from '@material-ui/core/Select';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import {Heat} from '../../../../../models/heat';
import {EditIcon} from '../../../components/icons/edit_icon';

interface OwnProps {
    recordedTime: RecordedTime;
    heat: Heat;
    teams: Team[];
}

@observer
class ManageRecordedTime extends React.Component<OwnProps & PresenterProps<ManageRecordedTimePresenter>> {
    private sortTeams(a: Team, b: Team) {
        if (a.number !== null && b.number !== null) {
            return a.number > b.number ? 1 : -1;
        }
        if (a.number !== null) {
            return -1;
        }
        if (b.number !== null) {
            return 1;
        }

        return a.name > b.name ? 1 : -1;
    }

    public render() {
        const startTeam = this.props.teams.find(team => team.recordedStartTimeId === this.props.recordedTime.id);
        const finishTeam = this.props.teams.find(team => team.recordedFinishTimeId === this.props.recordedTime.id);
        return (
            <TableRow key={this.props.recordedTime.id}>
                <TableCell>{formatTimeMsUTC(this.props.recordedTime.timeMs)}</TableCell>
                <TableCell>
                    {this.props.heat.startTimeMs === null
                        ? '--:--'
                        : formatTimeMsUTC(this.props.recordedTime.timeMs - this.props.heat.startTimeMs)}
                </TableCell>
                <TableCell>
                    {startTeam
                        ? startTeam.name + ' (' + (startTeam.number || startTeam.name) + ') as start time'
                        : null}
                    {finishTeam
                        ? finishTeam.name + ' (' + (finishTeam.number || finishTeam.name) + ') as finish time'
                        : null}
                    {!finishTeam && !startTeam ? '-' : null}
                </TableCell>
                <TableCell>
                    <Button onClick={() => this.props.presenter.onEditClick()}>
                        <EditIcon />
                    </Button>

                    <Dialog
                        open={this.props.presenter.editDialogVisible}
                        onClose={() => this.props.presenter.closeDialog()}
                        aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Edit recorded time</DialogTitle>
                        <DialogContent>
                            <DialogContentText>Assign the recorded time to a team</DialogContentText>
                            <FormControl style={{minWidth: 240, margin: 20}}>
                                <InputLabel htmlFor="age-simple">Team</InputLabel>
                                <Select
                                    value={this.props.presenter.selectedTeamId}
                                    disabled={this.props.presenter.saving}
                                    onChange={(e: any) => this.props.presenter.onSelectedTeamChange(e.target.value)}>
                                    <MenuItem key="-1" value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {this.props.teams.sort(this.sortTeams).map(team => {
                                        return (
                                            <MenuItem key={team.id} value={team.id}>
                                                {team.number ? team.number + ': ' + team.clubCode : team.clubCode}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={this.props.presenter.saving}
                                onClick={() => this.props.presenter.closeDialog()}
                                color="primary">
                                Cancel
                            </Button>
                            <Button
                                disabled={this.props.presenter.saving || !this.props.presenter.validates}
                                onClick={() => this.props.presenter.onSetStartClicked()}
                                color="primary">
                                Save as start
                            </Button>
                            <Button
                                disabled={this.props.presenter.saving || !this.props.presenter.validates}
                                onClick={() => this.props.presenter.onSetFinishClicked()}
                                color="primary">
                                Save as finish
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TableCell>
            </TableRow>
        );
    }
}

export default withPresenter<ManageRecordedTimePresenter, OwnProps>(
    (props, component) =>
        new ManageRecordedTimePresenter(props.recordedTime, component.business.recordedTimeInteractor),
    ManageRecordedTime,
);
