import * as React from 'react';
import {observer} from 'mobx-react';
import {AutoAssignPresenter} from './auto_assign_presenter';
import {Edition, RegattaMode} from '../../../../../models/edition';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {Button, Grid} from '@material-ui/core';
import {Theme, WithStyles} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import {DragDropContext, DropResult, ResponderProvided} from 'react-beautiful-dnd';
import {CategoriesLane} from './categories_lane';
import Header from '../../components/header';
import {Content} from '../../../../../client/ui/components/content';

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
});

type Styles = WithStyles<'root'>;

interface OwnProps {
    edition: Edition;
}

@observer
class StyledAutoAssignScreen extends React.Component<OwnProps & Styles & PresenterProps<AutoAssignPresenter>> {
    public render() {
        return (
            <DragDropContext onDragEnd={(a, b) => this.onDragEnd(a, b)}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Batch assign heat',
                            link: '/edition/autoassign',
                        },
                    ]}>
                    Batch assign heat
                </Header>
                <Content>
                    <Grid container spacing={8}>
                        <CategoriesLane
                            laneId="unassigned"
                            title="Unassigned"
                            categories={this.props.presenter.data.unassigned}
                            showStartNumberInput={false}
                            startNumber={0}
                            startNumberValid={true}
                            setStartNumber={number => this.props.presenter.setHeatStartNumber(-1, number)}
                        />
                        {this.props.presenter.data.heats.map((heat, index) => (
                            <CategoriesLane
                                key={heat.heat.id}
                                laneId={heat.heat.id}
                                title={heat.heat.name}
                                categories={heat.categories}
                                showStartNumberInput={true}
                                startNumber={heat.startNumber}
                                startNumberValid={heat.startNumberValid}
                                setStartNumber={number => this.props.presenter.setHeatStartNumber(index, number)}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            style={{marginRight: 16}}
                            onClick={() => this.props.presenter.save()}>
                            Save
                        </Button>
                        {this.props.edition.mode === RegattaMode.TWW && (
                            <Button variant="contained" onClick={() => this.props.presenter.presetTWW()}>
                                TWW preset
                            </Button>
                        )}
                    </Grid>
                </Content>
            </DragDropContext>
        );
    }

    private onDragEnd(result: DropResult, provided: ResponderProvided) {
        if (result.destination === undefined || result.destination === null) {
            return;
        }
        const heatIds = this.props.presenter.data.heats.map(heat => heat.heat.id);
        const targetId = result.destination.droppableId;
        const sourceHeat = heatIds.findIndex(value => value === result.source.droppableId);
        const targetHeat = heatIds.findIndex(value => value === targetId);
        const sourceIndex = result.source.index;
        const targetIndex = result.destination.index;
        this.props.presenter.swapCategories(sourceHeat, targetHeat, sourceIndex, targetIndex);
    }
}

export default withPresenter<AutoAssignPresenter, OwnProps>(
    (props, component) =>
        new AutoAssignPresenter(
            props.edition,
            component.persistence.teamRepository,
            component.persistence.heatRepository,
        ),
    withStyles(styles)(StyledAutoAssignScreen),
);
