import * as React from 'react';
import {useState} from 'react';
import {usePresenter} from '../support/use_presenter';
import {useObservable} from '../support/hooks/use_observable';
import {Team} from '../../../models/team';
import {HeatPresenter} from './heat_presenter';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import {RecordedAs} from '../../../models/recorded_time';
import {Tracking} from './tracking';
import {RecordedAsDialog} from './recorded_as_dialog';
import {HeatWithEdition} from '../../tracking/tracking_controller';

interface OwnProps {
    heat: HeatWithEdition;
}

export const Heat = ({heat}: OwnProps) => {
    const presenter = usePresenter(
        component => new HeatPresenter(component.trackingController(heat.id), component.networkStatusProvider),
    );

    const [recordedAs, setRecordedAs] = useState<RecordedAs | null>(null);

    const isSyncing = useObservable(presenter.isSyncing, false);
    const teams: Team[] = useObservable(presenter.teams, []);

    return (
        <>
            {isSyncing ? <LinearProgress /> : null}
            {recordedAs === null ? (
                <RecordedAsDialog recordedAs={recordedAs} onChoose={setRecordedAs} />
            ) : (
                <Tracking heat={heat} teams={teams} recordedAs={recordedAs} />
            )}
        </>
    );
};
