import * as React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {Theme} from '@material-ui/core';
import {usePresenter} from './support/use_presenter';
import {useObservable} from './support/hooks/use_observable';
import {Heat as HeatComponent} from './heat/heat';
import Loading from './components/loading';
import {Timer} from './timer/timer';
import {Maybe} from 'tsmonad';
import {HeatWithEdition} from '../tracking/tracking_controller';
import {RegattaMode} from '../../models/edition';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {formatTimeMsClientTimeLowPreciesion} from '../../administration/support/format_time_ms';
import {Link} from 'react-router-dom';
import {ViewIcon} from '../../administration/screens/components/icons/view_icon';
import Table from '@material-ui/core/Table';
import {Content} from './components/content';

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 0,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
});

interface OwnProps {
    editionId: string;
}

function EditionHeatsComponent({editionId, classes}: OwnProps & WithStyles<'root'>) {
    const presenter = usePresenter(component => ({
        heats: component.heatsForEdition(editionId),
    }));
    const heats = useObservable(presenter.heats, null);

    if (heats === null) {
        return (
            <div>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 0.8}}>
                            WedstrijdKlok
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Loading />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    return (
        <div>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="title" color="inherit" style={{flex: 0.8}}>
                        WedstrijdKlok
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <Content>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {heats.map(heat => {
                                        return (
                                            <TableRow key={heat.id}>
                                                <TableCell>{heat.number}</TableCell>
                                                <TableCell>{heat.name}</TableCell>
                                                <TableCell>
                                                    <Link to={`/tracking/heat/${heat.id}`}>
                                                        <ViewIcon />
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Content>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export const EditionHeats = withStyles(styles)(EditionHeatsComponent);
