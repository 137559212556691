import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {HeatTimerPresenter} from './heat_timer_presenter';
import {observer} from 'mobx-react';
import {formatTimeMsUTC} from '../../../../support/format_time_ms';

interface OwnProps {
    heatId: string;
}

@observer
class HeatTimer extends React.Component<OwnProps & PresenterProps<HeatTimerPresenter>> {
    public render() {
        return <span>{formatTimeMsUTC(this.props.presenter.timeMs)}</span>;
    }
}

export default withPresenter<HeatTimerPresenter, OwnProps>(
    (props, component) =>
        new HeatTimerPresenter(
            props.heatId,
            component.persistence.heatRepository,
            component.business.serverTimeProvider,
        ),
    HeatTimer,
);
