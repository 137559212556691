import {Observable, Observer} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

export interface FetchResult<T> {
    response: Response | null;
    data: T | null;
    duration: number;
    error: Error | null;
}

export interface SuccessFetchResult<T> extends FetchResult<T> {
    response: Response;
    data: T;
    duration: number;
    error: null;
}

export function rxFetch<T>(input: RequestInfo, init?: RequestInit): Observable<FetchResult<T>> {
    return new Observable((observer: Observer<FetchResult<T>>) => {
        const promise = new Promise<FetchResult<T>>(resolve => {
            const start = window.performance.now();

            const onfulfilled = async (response: Response) => {
                const duration = window.performance.now() - start;
                try {
                    const data = await response.json();
                    const isSuccessful = response.status >= 200 && response.status < 400;
                    resolve({
                        response: response,
                        data: isSuccessful ? data : null,
                        duration: duration,
                        error: isSuccessful ? null : Error('Error status code: ' + response.status),
                    });
                } catch (error) {
                    resolve({
                        response: null,
                        data: null,
                        duration: duration,
                        error: error,
                    });
                }
            };

            window
                .fetch(input, init)
                .then(onfulfilled)
                .catch(error => {
                    const duration = window.performance.now() - start;
                    resolve({
                        response: null,
                        data: null,
                        duration: duration,
                        error: error,
                    });
                });
        });

        fromPromise<FetchResult<T>>(promise).subscribe(result => {
            observer.next(result);
            observer.complete();
        });
    });
}
