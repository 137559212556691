import {ResultRequestRepository} from './result_request_repository';
import FirebaseDriver from '../drivers/firebase_driver';
import {Observable, Observer} from 'rxjs';
import {ResultMode} from '../../business/results/result_mode';
import {Grouping} from '../../business/results/grouping';
import {ResultRequest} from '../../../models/result_request';
import {Category} from '../../../models/team';

interface FirebaseResultRequest {
    id: string;
    edition_id: string;
    include_in_narrowcasting: boolean;
    name: string;
    mode: ResultMode;
    grouping: Grouping;
    tags: string;
    categories: string;
    heat_ids: string;
}

export class FirebaseResultRequestRepository implements ResultRequestRepository {
    private _ref = 'result_requests';
    private _firebaseDriver: FirebaseDriver;

    constructor(firebaseDriver: FirebaseDriver) {
        this._firebaseDriver = firebaseDriver;
    }

    public async createOrUpdate(resultRequest: ResultRequest): Promise<ResultRequest> {
        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(resultRequest.id)
            .set(FirebaseResultRequestRepository.toFirebaseResultRequest(resultRequest));

        return resultRequest;
    }

    public async destroy(resultRequest: ResultRequest): Promise<void> {
        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(resultRequest.id)
            .remove();
    }

    public findByEditionId(editionId: string): Observable<ResultRequest[]> {
        return new Observable((observer: Observer<ResultRequest[]>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('edition_id')
                .equalTo(editionId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseResultRequest} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const resultRequests = Object.keys(result)
                            .map(key => result[key])
                            .map(resultRequest =>
                                FirebaseResultRequestRepository.fromFirebaseResultRequest(resultRequest),
                            );
                        observer.next(resultRequests);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    private static toFirebaseResultRequest(resultRequest: ResultRequest): FirebaseResultRequest {
        return {
            id: resultRequest.id,
            edition_id: resultRequest.editionId,
            include_in_narrowcasting: resultRequest.includeInNarrowcasting,
            name: resultRequest.name,
            mode: resultRequest.mode,
            grouping: resultRequest.grouping,
            tags: resultRequest.tags === undefined ? '' : resultRequest.tags.map(tag => tag.name).join('|'),
            categories: resultRequest.categories === undefined ? '' : resultRequest.categories.join('|'),
            heat_ids: resultRequest.heatIds === undefined ? '' : resultRequest.heatIds.join('|'),
        };
    }

    private static fromFirebaseResultRequest(resultRequest: FirebaseResultRequest): ResultRequest {
        return {
            id: resultRequest.id,
            editionId: resultRequest.edition_id,
            includeInNarrowcasting: resultRequest.include_in_narrowcasting || false,
            name: resultRequest.name,
            mode: resultRequest.mode,
            grouping: resultRequest.grouping,
            tags: resultRequest.tags
                .split('|')
                .filter(name => name !== '')
                .map(name => ({
                    name: name,
                })),
            categories: resultRequest.categories.split('|') as Category[],
            heatIds: resultRequest.heat_ids === undefined ? [] : resultRequest.heat_ids.split('|'),
        };
    }
}
