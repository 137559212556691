import {first} from 'rxjs/operators';
import {Presenter} from '../../../../support/with_presenter';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Heat} from '../../../../../models/heat';
import {Subscription} from 'rxjs';
import {observable} from 'mobx';
import {ServerTimeProvider} from '../../../../../server_time/server_time_provider';

export class HeatTimerPresenter implements Presenter {
    @observable public timeMs: number | null = null;
    private _heatRepository: HeatRepository;
    private _heatId: string;
    private _subscription: Subscription | undefined;
    private _heat: Heat | null = null;

    private _mounted = false;
    private _serverTimeProvider: ServerTimeProvider;

    constructor(heatId: string, heatRepository: HeatRepository, serverTimeProvider: ServerTimeProvider) {
        this._heatId = heatId;
        this._heatRepository = heatRepository;
        this._serverTimeProvider = serverTimeProvider;
    }

    public mount(): void {
        this._subscription = this._heatRepository.findById(this._heatId).subscribe((heat: Heat | null) => {
            this._heat = heat;
        });

        this._mounted = true;
        this.start();
    }

    public unmount(): void {
        this._mounted = false;
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    private start() {
        if (this._mounted) {
            window.requestAnimationFrame(async () => {
                if (this._heat === null) {
                    this.timeMs = null;
                } else {
                    if (this._heat.startTimeMs === null) {
                        this.timeMs = null;
                    } else if (this._heat.endTimeMs === null) {
                        const serverTime = await this._serverTimeProvider
                            .getTime()
                            .pipe(first())
                            .toPromise();
                        this.timeMs = serverTime - this._heat.startTimeMs;
                    } else {
                        this.timeMs = this._heat.endTimeMs - this._heat.startTimeMs;
                    }
                }
                this.start();
            });
        }
    }
}
