import {ParticipantRepository} from './participant_repository';
import {Gender, Participant} from '../../../models/participant';
import {v4 as uuid} from 'uuid';
import FirebaseDriver from '../drivers/firebase_driver';
import {Observable, Observer} from 'rxjs';

interface FirebaseParticipant {
    id: string;
    edition_id: string;
    team_id: string;
    name: string;
    birth_year: number;
    membership_code: string;
    club_code: string;
    gender: Gender;
}

export class FirebaseParticipantRepository implements ParticipantRepository {
    private _ref = 'participants';
    private _firebaseDriver: FirebaseDriver;

    constructor(firebaseDriver: FirebaseDriver) {
        this._firebaseDriver = firebaseDriver;
    }

    public async create(
        editionId: string,
        teamId: string,
        name: string,
        birthYear: number,
        membershipCode: string,
        clubCode: string,
        gender: Gender,
    ): Promise<Participant> {
        const participant: Participant = {
            id: uuid(),
            editionId: editionId,
            teamId: teamId,
            name: name,
            birthYear: birthYear,
            membershipCode: membershipCode,
            clubCode: clubCode,
            gender: gender,
        };

        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(participant.id)
            .set(FirebaseParticipantRepository.toFirebaseParticipant(participant));

        return participant;
    }

    public async update(participant: Participant): Promise<Participant> {
        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(participant.id)
            .set(FirebaseParticipantRepository.toFirebaseParticipant(participant));

        return participant;
    }

    public findById(id: string): Observable<Participant | null> {
        return new Observable((observer: Observer<Participant | null>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('id')
                .equalTo(id);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseParticipant} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const participant = result[Object.keys(result)[0]];
                        observer.next(FirebaseParticipantRepository.fromFirebaseParticipant(participant));
                    } else {
                        observer.next(null);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    public findByTeamId(editionId: string): Observable<Participant[]> {
        return new Observable((observer: Observer<Participant[]>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('team_id')
                .equalTo(editionId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseParticipant} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const participants = Object.keys(result)
                            .map(key => result[key])
                            .map(participant => FirebaseParticipantRepository.fromFirebaseParticipant(participant));
                        observer.next(participants);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    public findByEditionId(editionId: string): Observable<Participant[]> {
        return new Observable((observer: Observer<Participant[]>) => {
            const query = this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('edition_id')
                .equalTo(editionId);

            const callback = (snapshot: firebase.database.DataSnapshot | null) => {
                //TODO extract this to something more generic!
                if (snapshot === null) {
                    throw new Error('Empty snapshot received');
                } else {
                    const result: {[key: string]: FirebaseParticipant} | null = snapshot.val();
                    if (result !== null && Object.keys(result).length > 0) {
                        const participants = Object.keys(result)
                            .map(key => result[key])
                            .map(participant => FirebaseParticipantRepository.fromFirebaseParticipant(participant));
                        observer.next(participants);
                    } else {
                        observer.next([]);
                    }
                }
            };
            query.on('value', callback);

            return () => {
                query.off('value', callback);
            };
        });
    }

    private static toFirebaseParticipant(participant: Participant): FirebaseParticipant {
        return {
            id: participant.id,
            edition_id: participant.editionId,
            team_id: participant.teamId,
            name: participant.name,
            birth_year: participant.birthYear,
            membership_code: participant.membershipCode,
            club_code: participant.clubCode,
            gender: participant.gender,
        };
    }

    private static fromFirebaseParticipant(participant: FirebaseParticipant): Participant {
        return {
            id: participant.id,
            editionId: participant.edition_id,
            teamId: participant.team_id,
            name: participant.name,
            birthYear: participant.birth_year,
            membershipCode: participant.membership_code,
            clubCode: participant.club_code,
            gender: participant.gender,
        };
    }
}
