import {firebaseConfig} from '../../../config';
import * as firebase from 'firebase';

export default class FirebaseDriver {
    private _instance: firebase.app.App | undefined;

    public getInstance(): firebase.app.App {
        if (this._instance === undefined) {
            this._instance = firebase.initializeApp(firebaseConfig);
        }
        return this._instance;
    }
}
