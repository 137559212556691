import {Handicap2019Long} from './__support__/handicap_2019_long';
import {Handicap2019Short} from './__support__/handicap_2019_short';
import {Handicap2023Long} from './__support__/handicap_2023_long';
import {Handicap2023Short} from './__support__/handicap_2023_short';
import {HandicapTable} from './handicap_table';

export function buildHandicapTable(identifier: string): HandicapTable {
    switch (identifier) {
        case '2019_Long':
            return new Handicap2019Long('2019Long');
        case '2019_Short':
            return new Handicap2019Short('2019Short');
        case '2023_Long':
            return new Handicap2023Long('2023Long');
        case '2023_Short':
        default:
            return new Handicap2023Short('2023Short');
    }
}
