import * as React from 'react';
import {RecordedTime} from '../../../../../models/recorded_time';
import {usePresenter} from '../../../support/use_presenter';
import {animationFrames} from '../../../../support/observables/animation_frames';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {useObservable} from '../../../support/hooks/use_observable';
import {formatTimeMsUTCLowPrecision} from '../../../../../administration/support/format_time_ms';

interface OwnProps {
    recordedTime: RecordedTime;
}

export const RunningTimer = ({recordedTime}: OwnProps) => {
    const presenter = usePresenter(component => ({
        times: animationFrames().pipe(
            switchMap(() => component.serverTime.serverTimeProvider.getTime()),
            map(t => t - recordedTime.timeMs),
            map(formatTimeMsUTCLowPrecision),
            distinctUntilChanged(),
        ),
    }));

    const time = useObservable(presenter.times, null);

    return <>{time}</>;
};
