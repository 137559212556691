import {Presenter} from '../../../../support/with_presenter';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Edition} from '../../../../../models/edition';
import {Team} from '../../../../../models/team';
import {computed, observable} from 'mobx';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {ParticipantRepository} from '../../../../persistence/participant/participant_repository';
import {Heat} from '../../../../../models/heat';
import {Participant} from '../../../../../models/participant';
import {CompositeSubscription} from '../../../../support/composit_subscription';

export class InschrijfTafelPresenter implements Presenter {
    @observable private loadingTeams: boolean = true;
    @observable private loadingHeats: boolean = true;
    @observable private loadingParticipants: boolean = true;

    @computed
    public get loading() {
        const loading = this.loadingTeams || this.loadingHeats || this.loadingParticipants;
        if (!loading) {
            /* We have to wait one tick for the render to finish based on this result, otherwise we're printing the loader */
            setTimeout(() => {
                window.print();
            }, 0);
        }
        return loading;
    }

    @observable public teams: Team[] = [];
    @observable public heats: Heat[] = [];
    @observable public participants: Participant[] = [];
    private _edition: Edition;
    private _teamRepository: TeamRepository;
    private _subscriptions = new CompositeSubscription();
    private _heatRepository: HeatRepository;
    private _participantRepository: ParticipantRepository;

    constructor(
        edition: Edition,
        teamRepository: TeamRepository,
        heatRepository: HeatRepository,
        participantRepository: ParticipantRepository,
    ) {
        this._edition = edition;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
        this._participantRepository = participantRepository;
    }

    public mount(): void {
        this._subscriptions.add(
            this._teamRepository.findByEditionId(this._edition.id).subscribe(teams => {
                this.teams = teams;
                this.loadingTeams = false;
            }),
        );
        this._subscriptions.add(
            this._heatRepository.findByEditionId(this._edition.id).subscribe(heats => {
                this.heats = heats;
                this.loadingHeats = false;
            }),
        );
        this._subscriptions.add(
            this._participantRepository.findByEditionId(this._edition.id).subscribe(participants => {
                this.participants = participants;
                this.loadingParticipants = false;
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }
}
