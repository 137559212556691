import * as React from 'react';
import {FunctionComponent} from 'react';
import {HeatWithEdition} from '../../../../tracking/tracking_controller';
import Button from '@material-ui/core/Button/Button';
import {usePresenter} from '../../../support/use_presenter';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {RecordTimeRequest} from '../../../../tracking/models/record_time_request';
import {RecordedAs} from '../../../../../models/recorded_time';
import {useObservable} from '../../../support/hooks/use_observable';
import {Team} from '../../../../../models/team';
import {Link} from 'react-router-dom';
import {RegattaMode} from '../../../../../models/edition';

interface OwnProps {
    recordedAs: RecordedAs;
    heat: HeatWithEdition;
    teams: Team[];
}

export const HeatControls: FunctionComponent<OwnProps> = ({heat, recordedAs, teams}) => {
    const recordTimeRequest: RecordTimeRequest = {
        recordedAs: recordedAs,
        heatId: heat.id,
        teams: teams,
    };

    const presenter = usePresenter(component => ({
        hasRecordedTime: component
            .trackingController(heat.id)
            .recordedTimesForRecordedAs(recordedAs)
            .pipe(
                map(recordedTimes => recordedTimes.length > 0),
                distinctUntilChanged(),
            ),
        recordTime: () => component.trackingController(heat.id).recordTime(recordTimeRequest),
    }));

    const hasRecordedTime = useObservable(presenter.hasRecordedTime, true);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 15,
            }}>
            {!hasRecordedTime && (
                <Button disableRipple variant="contained" color="primary" size="large" onClick={presenter.recordTime}>
                    Start
                </Button>
            )}
            {heat.edition.mode === RegattaMode.Sprint && (
                <Link
                    to={'/tracking/edition/' + heat.editionId}
                    style={{
                        padding: 10,
                        textDecoration: 'none',
                        color: '#000',
                        textTransform: 'uppercase',
                        fontSize: '0.9375rem',
                        marginTop: 25,
                    }}>
                    Switch heat
                </Link>
            )}
        </div>
    );
};
