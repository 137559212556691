import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Heat} from '../../../../../models/heat';
import {CompositeSubscription} from '../../../../support/composit_subscription';

export class EditHeatPresenter implements Presenter {
    @observable public loading: boolean = false;
    @observable public name: string = '';
    @observable public published: boolean = false;
    @observable public number: number = 0;
    @observable public startTimeMs: number | null = null;
    @observable public targetStartTimeMs: number | null = null;
    @observable private heat: Heat | null = null;
    private _heatId: string;
    private _heatRepository: HeatRepository;
    private _subscriptions = new CompositeSubscription();

    constructor(heatId: string, heatRepository: HeatRepository) {
        this._heatId = heatId;
        this._heatRepository = heatRepository;
    }

    public mount(): void {
        this._subscriptions.add(
            this._heatRepository.findById(this._heatId).subscribe((heat: Heat | null) => {
                this.loading = false;
                this.heat = heat;

                if (this.heat !== null) {
                    this.name = this.heat.name;
                    this.published = this.heat.published;
                    this.number = this.heat.number || 0;
                    this.startTimeMs = this.heat.startTimeMs;
                    this.targetStartTimeMs = this.heat.targetStartTimeMs;
                }
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onNumberChange(number: string) {
        this.number = parseInt(number, 10);
    }

    public onPublishedChange(checked: boolean) {
        this.published = checked;
    }

    public onStartTimeChange(value: any) {
        this.startTimeMs = value;
    }

    public onTargetStartDateChange(value: string) {
        const date = this.targetStartTimeMs !== null ? new Date(this.targetStartTimeMs) : new Date();

        const [Y, m, d] = value.split('-');
        date.setFullYear(parseInt(Y, 10));
        date.setMonth(parseInt(m, 10) - 1);
        date.setDate(parseInt(d, 10));

        this.targetStartTimeMs = date.getTime();
    }

    public onTargetStartTimeChange(value: string) {
        const date = this.targetStartTimeMs !== null ? new Date(this.targetStartTimeMs) : new Date();

        const [H, i] = value.split(':');
        date.setHours(parseInt(H, 10));
        date.setMinutes(parseInt(i, 10));

        this.targetStartTimeMs = date.getTime();
    }

    public async onSubmitClicked() {
        if (this.heat === null) {
            return;
        }
        this.loading = true;
        try {
            await this._heatRepository.update({
                ...this.heat,
                name: this.name,
                number: this.number,
                published: this.published,
                startTimeMs: this.startTimeMs !== null ? parseFloat(this.startTimeMs as any) : null,
                targetStartTimeMs: this.targetStartTimeMs,
            });
            window.location.href = '/edition/heats';
        } finally {
            this.loading = false;
        }
    }
}
