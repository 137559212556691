import * as React from 'react';
import Content from '../../components/content';
import {Table} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import {ResultRequest} from '../../../../../models/result_request';
import {Team} from '../../../../../models/team';

interface OwnProps {
    resultRequest: ResultRequest;
    groups: Array<{name: string; teams: Team[]}>;
}

export class ResultGroupingPreview extends React.Component<OwnProps> {
    public render() {
        return (
            <Content>
                {this.props.groups.map(group => {
                    return (
                        <React.Fragment key={group.name}>
                            <h2>{group.name}</h2>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Rug</TableCell>
                                        <TableCell>Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.teams.map(team => (
                                        <TableRow key={team.id}>
                                            <TableCell>{team.number}</TableCell>
                                            <TableCell>{team.name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    );
                })}
            </Content>
        );
    }
}
