import {Presenter} from '../../../support/with_presenter';
import {Team} from '../../../../models/team';
import {Heat} from '../../../../models/heat';
import {HeatInteractor} from '../../../business/heat/heat_interactor';
import {TeamRepository} from '../../../persistence/team/team_repository';
import {HeatRepository} from '../../../persistence/heat/heat_repository';
import {CompositeSubscription} from '../../../support/composit_subscription';
import {computed, observable} from 'mobx';
import {RecordedTimesInteractor} from '../../../business/recorded_times/recorded_times_interactor';
import {RecordedTime} from '../../../../models/recorded_time';

export class ManageTimesScreenPresenter implements Presenter {
    @computed
    public get loading() {
        return this.loadingTeams || this.loadingTimes || this.loadingHeat;
    }

    @observable private loadingTeams = true;
    @observable private loadingTimes = true;
    @observable private loadingHeat = true;
    @observable public error: string | null = null;
    @observable public actionsEnabled = true;
    @observable public heat: Heat | null = null;
    @observable public teams: Team[] | null = null;
    @observable public recordedTimes: RecordedTime[] = [];
    @observable public addTimeModalVisible = false;
    private _heatId: string;
    private _heatRepository: HeatRepository;
    private _subscription = new CompositeSubscription();
    private _teamRepository: TeamRepository;
    private _heatInteractor: HeatInteractor;
    private _recordedTimesInteractor: RecordedTimesInteractor;

    constructor(
        heatId: string,
        heatRepository: HeatRepository,
        teamRepository: TeamRepository,
        heatInteractor: HeatInteractor,
        recordedTimesInteractor: RecordedTimesInteractor,
    ) {
        this._heatId = heatId;
        this._heatRepository = heatRepository;
        this._teamRepository = teamRepository;
        this._heatInteractor = heatInteractor;
        this._recordedTimesInteractor = recordedTimesInteractor;
    }

    public mount(): void {
        this._subscription.add(
            this._heatRepository.findById(this._heatId).subscribe((heat: Heat | null) => {
                this.loadingHeat = false;
                this.heat = heat;
            }),
        );
        this._subscription.add(
            this._teamRepository.findByHeatId(this._heatId).subscribe((teams: Team[]) => {
                this.loadingTeams = false;
                this.teams = teams;
            }),
        );
        this._subscription.add(
            this._recordedTimesInteractor.forHeatId(this._heatId).subscribe((recordedTimes: RecordedTime[]) => {
                this.loadingTimes = false;
                this.recordedTimes = recordedTimes;
            }),
        );
    }

    public unmount(): void {
        this._subscription.clear();
    }

    public async onTimeAdd(time: number) {
        await this._recordedTimesInteractor.registerManualTime(this._heatId, time);
        this.addTimeModalVisible = false;
    }

    public showAddTimeModal() {
        this.addTimeModalVisible = true;
    }

    public onAddTimeModalClose() {
        this.addTimeModalVisible = false;
    }
}
