import {Presenter} from '../support/hooks/use_presenter_hook_factory';
import {TrackingController} from '../../tracking/tracking_controller';
import {NetworkStatus, NetworkStatusProvider} from '../../support/observables/network_status_provider';
import {flatMap, tap} from 'rxjs/operators';
import {BehaviorSubject, NEVER, Observable, Subscription} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';

export class HeatPresenter implements Presenter {
    public teams = this.trackingController.attendingTeams();

    public get isSyncing(): Observable<boolean> {
        return this._syncingSubject;
    }

    private _syncingSubject = new BehaviorSubject(true);

    private _subscription: Subscription | undefined;

    constructor(private trackingController: TrackingController, private networkStatusProvider: NetworkStatusProvider) {}

    public async mount() {
        this._syncingSubject.next(true);
        await this.trackingController.sync();
        this._syncingSubject.next(false);
        this._subscription = this.networkStatusProvider
            .status()
            .pipe(
                tap(networkStatus => {
                    if (networkStatus === NetworkStatus.ONLINE) {
                        this._syncingSubject.next(true);
                    }
                }),
                flatMap(networkStatus =>
                    networkStatus === NetworkStatus.ONLINE ? fromPromise(this.trackingController.sync()) : NEVER,
                ),
                tap(() => this._syncingSubject.next(false)),
            )
            .subscribe();
    }

    public unmount() {
        if (this._subscription !== undefined) {
            this._subscription.unsubscribe();
        }
    }
}
