import * as React from 'react';
import {observer} from 'mobx-react';
import {Team} from '../../../../../models/team';
import {Theme, WithStyles} from '@material-ui/core/styles';
import {Avatar, Card, CardContent, CardHeader, IconButton, Typography} from '@material-ui/core';
import {decodeClubCode} from '../../../../support/club_code_decoder';
import withStyles from '@material-ui/core/styles/withStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {TagsInputWrapper} from '../../components/tags_input_wrapper';
import Input from '@material-ui/core/Input';
import {Tag} from '../../../../../models/tag';
import {Participant} from '../../../../../models/participant';

const styles = (theme: Theme) => ({
    card: {
        minWidth: 275,
    },
    category: {},
    avatar: {},
});

type Styles = WithStyles<'card' | 'category' | 'avatar'>;

interface OwnProps {
    team: Team;
    stroke: Participant | undefined;
    setTags?: (tags: Tag[]) => void;
}

interface OwnState {
    showMenu: boolean;
}

@observer
class StyledTeamBadge extends React.Component<OwnProps & Styles, OwnState> {
    public state: OwnState = {
        showMenu: false,
    };

    public render() {
        return (
            <Card className={this.props.classes.card} style={{marginBottom: 10}}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="team-number" className={this.props.classes.avatar}>
                            {this.props.team.number ? this.props.team.number : '-'}
                        </Avatar>
                    }
                    title={decodeClubCode(this.props.team.clubCode)}
                    subheader={this.props.team.boat}
                    style={{
                        paddingBottom: 0,
                        marginBottom: 0,
                    }}
                />
                <CardContent>
                    <Typography className={this.props.classes.category}>
                        Category: {this.props.team.category}
                    </Typography>
                    <Typography className={this.props.classes.category}>
                        Stroke: {this.props.stroke ? this.props.stroke.name : ''}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export const TeamBadge = withStyles(styles)(StyledTeamBadge);
