import * as React from 'react';
import {PresenterProps, withPresenter} from '../../support/with_presenter';
import {RequiresAuthenticationPresenter} from './requires_authentication_presenter';
import {observer} from 'mobx-react';
import {Edition} from '../../../models/edition';
import Loading from '../components/loading';
import TopBar from '../secured/components/top_bar';
import Grid from '@material-ui/core/Grid';

interface OwnProps {
    renders: (edition: Edition) => React.ReactElement<any>;
}

@observer
class RequiresAuthentication extends React.Component<OwnProps & PresenterProps<RequiresAuthenticationPresenter>, {}> {
    public shouldComponentUpdate() {
        /*
         * React Router depends on context, we're not listening to changes from context, so otherwise we're blocking
         * updates even though something changed
         */
        return true;
    }

    public render() {
        if (this.props.presenter.loading) {
            return (
                <div>
                    <TopBar />
                    <Grid container spacing={8}>
                        <span style={{margin: '30px auto'}}>
                            <Loading />
                        </span>
                    </Grid>
                </div>
            );
        }
        if (this.props.presenter.edition !== null) {
            return this.props.renders(this.props.presenter.edition);
        }

        return null;
    }
}

export default withPresenter<RequiresAuthenticationPresenter, OwnProps>(
    (props, component) => new RequiresAuthenticationPresenter(component.authProvider),
    RequiresAuthentication,
);
