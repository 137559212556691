import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';
import {Heat} from '../../../../../models/heat';
import {Team} from '../../../../../models/team';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Subscription} from 'rxjs';
import {Edition} from '../../../../../models/edition';

export class TeamManagePresenter implements Presenter {
    @observable public team: Team;
    @observable public heats: Heat[] = [];

    private readonly _edition: Edition;
    private readonly _teamRepository: TeamRepository;
    private readonly _heatRepository: HeatRepository;

    private _subscription: Subscription | null = null;

    constructor(edition: Edition, team: Team, teamRepository: TeamRepository, heatRepository: HeatRepository) {
        this._edition = edition;
        this.team = team;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
    }

    public mount(): void {
        this._subscription = this._heatRepository.findByEditionId(this._edition.id).subscribe((heats: Heat[]) => {
            this.heats = heats;
        });
    }

    public unmount(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
}
