import {Presenter} from '../../../support/with_presenter';
import {v4 as uuid} from 'uuid';
import {ResultRequest} from '../../../../models/result_request';
import {observable} from 'mobx';
import {Grouping} from '../../../business/results/grouping';
import {ResultMode} from '../../../business/results/result_mode';
import {ResultProvider} from '../../../business/results/result_provider';
import {Edition} from '../../../../models/edition';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ResultGroup} from '../../../business/results/models/resultGroup';
import {CompositeSubscription} from '../../../support/composit_subscription';
import {ResultRequestRepository} from '../../../persistence/result_request/result_request_repository';
import {ResultGrouper} from '../../../business/results/result_builder';
import {TeamRepository} from '../../../persistence/team/team_repository';
import {Team} from '../../../../models/team';

export class ResultsPresenter implements Presenter {
    @observable
    public activeResultRequest: ResultRequest = this.stub();

    @observable
    public resultRequests: ResultRequest[] = [];

    @observable
    public results: ResultGroup[] | null = null;

    @observable
    public groups: Array<{name: string; teams: Team[]}> = [];

    private changes = new BehaviorSubject(this.activeResultRequest);

    private subscriptions = new CompositeSubscription();

    constructor(
        private edition: Edition,
        private resultProvider: ResultProvider,
        private resultRequestRepository: ResultRequestRepository,
        private resultGrouper: ResultGrouper,
        private teamRepository: TeamRepository,
    ) {}

    public mount(): void {
        this.subscriptions.add(
            this.changes
                .pipe(switchMap(resultRequest => this.resultProvider.build(this.edition.id, resultRequest)))
                .subscribe(results => (this.results = results)),
        );

        this.subscriptions.add(
            combineLatest(this.changes, this.teamRepository.findByEditionId(this.edition.id))
                .pipe(switchMap(([resultRequest, teams]) => this.resultGrouper.group(resultRequest, teams)))
                .subscribe(groups => (this.groups = groups)),
        );

        this.subscriptions.add(
            this.resultRequestRepository
                .findByEditionId(this.edition.id)
                .subscribe(resultRequests => (this.resultRequests = resultRequests)),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public onResultRequestChange(resultRequest: ResultRequest) {
        this.activeResultRequest = resultRequest;
        this.changes.next(this.activeResultRequest);
    }

    public onResultRequestDelete(resultRequest: ResultRequest) {
        if (resultRequest.id === this.activeResultRequest.id) {
            this.activeResultRequest = this.stub();
        }
        this.resultRequestRepository.destroy(resultRequest);
    }

    public onResultRequestActivate(resultRequest: ResultRequest) {
        this.activeResultRequest = {
            ...resultRequest,
        };
        this.changes.next(this.activeResultRequest);
    }

    public onSubmit() {
        this.resultRequestRepository.createOrUpdate(this.activeResultRequest);
    }

    public onClear() {
        this.activeResultRequest = this.stub();
    }

    private stub(): ResultRequest {
        return {
            id: uuid(),
            editionId: this.edition.id,
            includeInNarrowcasting: false,
            name: '',
            mode: ResultMode.Absolute,
            grouping: Grouping.Category,
            tags: [],
            categories: [],
        };
    }
}
