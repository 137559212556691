import * as React from 'react';
import {Team} from '../../../../models/team';
import {usePresenter} from '../../support/use_presenter';
import {useObservable} from '../../support/hooks/use_observable';
import {RecordedTimePair} from '../../../tracking/models/recorded_time_pair';
import {RecordedAs} from '../../../../models/recorded_time';
import {TeamTimer} from './team_timer';
import {RecordTimeRequest} from '../../../tracking/models/record_time_request';
import {TeamControlButton} from './team_timer/team_control_button';
import {distinctUntilChanged} from 'rxjs/operators';
import {pure} from 'recompose';

interface OwnProps {
    recordedAs: RecordedAs;
    team: Team;
}

export const TeamControls = pure(({recordedAs, team}: OwnProps) => {
    const recordTimeRequest: RecordTimeRequest = {
        recordedAs: recordedAs,
        heatId: team.heatId!,
        teams: [team],
    };

    const presenter = usePresenter(component => ({
        recordedTimes: component
            .trackingController(team.heatId)
            .recordedTimes(team.id)
            .pipe(
                distinctUntilChanged(
                    (a: RecordedTimePair, b: RecordedTimePair) => JSON.stringify(a) === JSON.stringify(b),
                ),
            ),
        recordTime: () => component.trackingController(team.heatId).recordTime(recordTimeRequest),
    }));

    const recordedTimePair: RecordedTimePair | null = useObservable(presenter.recordedTimes, null);
    if (recordedTimePair === null) {
        return null;
    }
    if (recordTimeRequest.recordedAs === RecordedAs.Start && recordedTimePair.start === null) {
        return <TeamControlButton label="Start" onClick={presenter.recordTime} />;
    }
    if (recordTimeRequest.recordedAs === RecordedAs.Finish && recordedTimePair.finish === null) {
        return <TeamControlButton label="Finish" onClick={presenter.recordTime} />;
    }

    return <TeamTimer recordedTimePair={recordedTimePair} />;
});
