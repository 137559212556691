import TWEEN from '@tweenjs/tween.js';
import {Presenter} from '../../../support/with_presenter';
import {Observable} from 'rxjs';
import {ResultGroup} from '../../../business/results/models/resultGroup';
import {CompositeSubscription} from '../../../support/composit_subscription';
import {computed, observable} from 'mobx';
import {ResultRequest} from '../../../../models/result_request';

export interface ResultForResultRequest {
    resultRequest: ResultRequest;
    results: ResultGroup[];
}

export interface NarrowcastableResultGroupsProvider {
    resultsForResultRequests(): Observable<ResultForResultRequest[]>;
}

export class NarrowcastingPresenter implements Presenter {
    @observable
    public progress = 0;

    @observable
    public resultForResultRequests: ResultForResultRequest[] = [];

    @computed
    public get showPlaceholder(): boolean {
        return !this.resultForResultRequests.some(resultForResultRequest => resultForResultRequest.results.length > 0);
    }

    private subscriptions = new CompositeSubscription();

    constructor(private narrowcastableResultGroupsProvider: NarrowcastableResultGroupsProvider) {}

    public mount(): void {
        this.subscriptions.add(
            this.narrowcastableResultGroupsProvider.resultsForResultRequests().subscribe(results => {
                return (this.resultForResultRequests = results);
            }),
        );

        this.animateProgress();
        setInterval(() => {
            const windowHeight = window.innerHeight;
            const documentHeight = document!.documentElement!.scrollHeight;
            const scrollY = window.scrollY;

            const nextScrollY = this.nextScrollY(windowHeight, documentHeight, scrollY);

            this.animateProgress();
            this.animateScroll(scrollY, nextScrollY);
        }, 10000);
    }

    private animateProgress() {
        const data = {progress: 0};
        new TWEEN.Tween(data)
            .to({progress: 100}, 10000)
            .easing(TWEEN.Easing.Linear.None)
            .onUpdate(() => {
                this.progress = data.progress;
            })
            .start();
    }

    private animateScroll(fromScrollY: number, toScrollY: number) {
        const data = {y: fromScrollY};
        new TWEEN.Tween(data)
            .to({y: toScrollY}, 500)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
                window.scrollTo(0, data.y);
            })
            .start();
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    private nextScrollY(windowHeight: number, documentHeight: number, scrollY: number): number {
        const distanceLeft = documentHeight - windowHeight - scrollY;
        if (distanceLeft <= 0) {
            return 0;
        }

        const safetyMargin = 200; //When the last result on the page is cut halfway, whole page skips would result in never showing this result

        return scrollY + windowHeight - safetyMargin;
    }
}
