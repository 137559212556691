import {useEffect, useState} from 'react';
import {Observable} from 'rxjs';

export const useObservable: <T>(a: Observable<T>, b: T) => T = (observable, initialValue) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        const subscription = observable.subscribe({next: setValue});
        return () => {
            subscription.unsubscribe();
        };
    }, [observable]);

    return value;
};
