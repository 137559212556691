import {Category, Team} from '../../../models/team';
import {Heat} from '../../../models/heat';
import {ResourceChecker} from './resource_checker';
import {Participant} from '../../../models/participant';
import {Tag} from '../../../models/tag';
import {RegattaMode} from '../../../models/edition';

export interface TeamState {
    heat: Heat | null;
    number: number | null;
    remarks: string[];
    team: Team;
    changed: boolean;
    tags: Tag[];
}

export const predefinedHeatOrderTWW: Category[][] = [
    // Heat 1
    [
        'D 8+',
        'D 8x+',
        'H 4+',
        'H 4x-',
        'H 4x+',
        'D 4-',
        'D C4x+',
        'H C4+',
        'MX 2x-',
        'H 2-',
        'MX18 8+',
        'M18 4+',
        'M18 4x+',
        'M18 C4x+',
        'MX18 C4+',
        'J18 2x-',
        'MX18 2-',
        'MX C2x+',
        'J16 2x-',
        'M16 4x+',
    ],
    // Heat 2
    [
        'MX 8+',
        'MX 8x+',
        'H 4-',
        'D 4x-',
        'D 4+',
        'D 4x+',
        'H C4x+',
        'D C4+',
        'H 2x-',
        'D 2-',
        'J18 8+',
        'MX18 4+',
        'MX18 4x+',
        'MX18 C4x+',
        'J18 C4+',
        'M18 2x-',
        'J18 2-',
        'MX C2x-',
        'M16 2x-',
    ],
    // Heat 3
    [
        'H 8+',
        'H 8x+',
        'MX 4+',
        'MX 4x-',
        'MX 4x+',
        'MX C4x+',
        'MX C4+',
        'D 2x-',
        'MX 2-',
        'M18 8+',
        'J18 4+',
        'J18 4x+',
        'J18 C4x+',
        'M18 C4+',
        'MX18 2x-',
        'M18 2-',
        'J16 4x+',
    ],
];

// Oude IJsselrace
const predefinedHeatOrderOIJR: Category[][] = [
    // Heat 1
    [
        'H 2x-',
        'D 2x-',
        'MX 2x-',
        'J18 2x-',
        'M18 2x-',
        'MX18 2x-',

        'H 2-',
        'D 2-',

        'H 1x-',
        'D 1x-',
        'J18 1x-',
        'M18 1x-',
    ],
];

export class TeamsManagementInteractor {
    private predefinedHeatAndOrderMapping = new Map<Category, {heat: number; weight: number}>();

    public constructor(mode: RegattaMode) {
        let predefinedHeatOrder: Category[][] | null = null;
        switch (mode) {
            case RegattaMode.OIJR:
                predefinedHeatOrder = predefinedHeatOrderOIJR;
                break;
            case RegattaMode.Sprint:
            case RegattaMode.Head:
            case RegattaMode.TWW:
            default:
                predefinedHeatOrder = predefinedHeatOrderTWW;
                break;
        }
        predefinedHeatOrder.forEach((order: Category[], index: number) => {
            const heatNumber = index + 1;
            order.forEach((category: Category, weight: number) => {
                this.predefinedHeatAndOrderMapping.set(category, {
                    heat: heatNumber,
                    weight: weight,
                });
            });
        });
    }

    public runValidation(teams: TeamState[], participants: Map<string, Participant>) {
        const assignedNumbers = new Set<number>();
        const assignedMultipleTimes = new Set<number>();
        const boatChecker: ResourceChecker = new ResourceChecker();
        const personChecker: ResourceChecker = new ResourceChecker();
        teams.forEach((teamState: TeamState) => {
            teamState.remarks = [];
            if (teamState.number) {
                if (assignedNumbers.has(teamState.number)) {
                    assignedMultipleTimes.add(teamState.number);
                }
                assignedNumbers.add(teamState.number);
            }
            if (teamState.heat) {
                boatChecker.add(teamState.team.boat, teamState.heat.id);
                personChecker.add(teamState.team.rower1Id, teamState.heat.id);
                personChecker.add(teamState.team.rower2Id, teamState.heat.id);
                personChecker.add(teamState.team.rower3Id, teamState.heat.id);
                personChecker.add(teamState.team.rower4Id, teamState.heat.id);
                personChecker.add(teamState.team.rower5Id, teamState.heat.id);
                personChecker.add(teamState.team.rower6Id, teamState.heat.id);
                personChecker.add(teamState.team.rower7Id, teamState.heat.id);
                personChecker.add(teamState.team.rower8Id, teamState.heat.id);
                personChecker.add(teamState.team.coxswainId, teamState.heat.id);
            }
        });

        teams.forEach((teamState: TeamState) => {
            if (teamState.number) {
                if (assignedMultipleTimes.has(teamState.number)) {
                    teamState.remarks.push('Number is used multiple times');
                }
            } else {
                teamState.remarks.push('No number assigned');
            }

            if (teamState.heat) {
                if (boatChecker.isUsedMultipleTimes(teamState.team.boat, teamState.heat.id)) {
                    teamState.remarks.push('Boat is used multiple times in this heat');
                }
                this.rowerCheck(participants, teamState, teamState.team.rower1Id, personChecker, 'Rower 1');
                this.rowerCheck(participants, teamState, teamState.team.rower2Id, personChecker, 'Rower 2');
                this.rowerCheck(participants, teamState, teamState.team.rower3Id, personChecker, 'Rower 3');
                this.rowerCheck(participants, teamState, teamState.team.rower4Id, personChecker, 'Rower 4');
                this.rowerCheck(participants, teamState, teamState.team.rower5Id, personChecker, 'Rower 5');
                this.rowerCheck(participants, teamState, teamState.team.rower6Id, personChecker, 'Rower 6');
                this.rowerCheck(participants, teamState, teamState.team.rower7Id, personChecker, 'Rower 7');
                this.rowerCheck(participants, teamState, teamState.team.rower8Id, personChecker, 'Rower 8');
                this.rowerCheck(participants, teamState, teamState.team.coxswainId, personChecker, 'Coxswain');
            } else {
                teamState.remarks.push('No heat assigned');
            }
        });
    }

    private getParticipantName(
        participants: Map<string, Participant>,
        participantId: string,
        placeholder: string,
    ): string {
        const participant = participants.get(participantId);
        if (participant === undefined) {
            return placeholder;
        } else {
            return participant.name + ' (' + placeholder + ')';
        }
    }

    private rowerCheck(
        participants: Map<string, Participant>,
        teamState: TeamState,
        rowerId: string | null,
        personChecker: ResourceChecker,
        placeholder: string,
    ): void {
        if (rowerId !== null && teamState.heat && personChecker.isUsedMultipleTimes(rowerId, teamState.heat.id)) {
            teamState.remarks.push(
                this.getParticipantName(participants, rowerId, placeholder) + ' is also in another team of this heat',
            );
        }
    }

    public reset(teams: TeamState[], participants: Map<string, Participant>): void {
        teams.forEach(teamState => {
            teamState.heat = null;
            teamState.number = null;
        });
        this.runValidation(teams, participants);
    }

    public autoAssignHeat(teams: TeamState[], heats: Heat[], participants: Map<string, Participant>): void {
        const number2heat: Map<number, Heat> = new Map();
        heats.forEach((heat: Heat) => {
            number2heat.set(heat.number, heat);
        });
        teams.forEach((teamState: TeamState) => {
            const categoryData = this.category2heat(teamState.team.category);
            const heat: Heat | undefined = number2heat.get(categoryData.heat);
            if (heat !== undefined) {
                teamState.heat = heat;
                teamState.number = null;
            }
        });
        this.runValidation(teams, participants);
    }

    public autoAssignNumber(
        teams: TeamState[],
        heats: Heat[],
        maxHeatSize: number,
        participants: Map<string, Participant>,
    ): void {
        const teamNumbers: Map<string, number> = new Map();
        const heatTeamInfo: Map<number, Array<{teamId: string; weight: number}>> = new Map();

        for (const heat of heats) {
            heatTeamInfo.set(heat.number, []);
        }
        teams.forEach((teamState: TeamState) => {
            if (teamState.heat) {
                const heatNumber = teamState.heat.number;
                const weight = this.category2heat(teamState.team.category).weight;
                const teamList = heatTeamInfo.get(heatNumber);
                const item = {teamId: teamState.team.id, weight: weight};
                if (teamList) {
                    teamList.push(item);
                } else {
                    heatTeamInfo.set(heatNumber, [item]);
                }
            }
        });
        heatTeamInfo.forEach((value, heatNumber) => {
            value.sort((a, b) => {
                if (a.weight < b.weight) {
                    return -1;
                } else if (a.weight > b.weight) {
                    return 1;
                } else {
                    return 0;
                }
            });
            let number = (heatNumber - 1) * maxHeatSize + 1;
            value.forEach(entry => {
                if (number) {
                    teamNumbers.set(entry.teamId, number);
                    number = number + 1;
                }
            });
        });
        teams.forEach((teamState: TeamState) => {
            const number = teamNumbers.get(teamState.team.id);
            if (number) {
                teamState.number = number;
            }
        });
        this.runValidation(teams, participants);
    }

    private category2heat(category: Category): {heat: number; weight: number} {
        const result = this.predefinedHeatAndOrderMapping.get(category);
        if (result !== undefined) {
            return result;
        } else {
            return {
                heat: 0,
                weight: 0,
            };
        }
    }
}
