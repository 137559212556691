import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';
import {Category, Team} from '../../../../../models/team';
import {Heat} from '../../../../../models/heat';
import {Edition} from '../../../../../models/edition';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {CompositeSubscription} from '../../../../support/composit_subscription';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {predefinedHeatOrderTWW} from '../../../../business/manage/teams_management_interactor';

export interface CategoryItem {
    category: Category;
    teams: Team[];
}

export interface AutoAssignData {
    heats: Array<{
        heat: Heat;
        categories: CategoryItem[];
        startNumber: number;
        startNumberValid: boolean;
    }>;
    unassigned: CategoryItem[];
}

export class AutoAssignPresenter implements Presenter {
    @observable public data: AutoAssignData = {heats: [], unassigned: []};
    private teams: Team[] = [];

    private readonly _edition: Edition;
    private readonly _teamRepository: TeamRepository;
    private readonly _heatRepository: HeatRepository;
    private _subscription = new CompositeSubscription();

    constructor(edition: Edition, teamRepository: TeamRepository, heatRepository: HeatRepository) {
        this._edition = edition;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
    }

    public mount() {
        const heatsObs = this._heatRepository.findByEditionId(this._edition.id).pipe(take(1));
        const teamsObs = this._teamRepository.findByEditionId(this._edition.id).pipe(take(1));
        this._subscription.add(
            forkJoin([heatsObs, teamsObs]).subscribe(async (values: [Heat[], Team[]]) => {
                const heats: Heat[] = values[0];
                const teams: Team[] = values[1];
                const categoryTeamsMap = new Map<Category, Team[]>();
                teams.forEach(team => {
                    const categoryTeams = categoryTeamsMap.get(team.category);
                    if (categoryTeams === undefined) {
                        categoryTeamsMap.set(team.category, [team]);
                    } else {
                        categoryTeams.push(team);
                    }
                });
                const data = {
                    heats: heats.map(heat => ({
                        heat: heat,
                        categories: [],
                        startNumber: 0,
                        startNumberValid: false,
                    })),
                    unassigned: Array.from(categoryTeamsMap.entries()).map(value => ({
                        category: value[0],
                        teams: value[1],
                    })),
                };
                let nextStartNumber = 1;
                data.heats.forEach(value => {
                    value.startNumber = nextStartNumber;
                    value.startNumberValid = true;
                    nextStartNumber = value.startNumber + value.categories.length;
                });
                this.teams = teams;
                this.data = data;
            }),
        );
    }

    public unmount(): void {
        this._subscription.clear();
    }

    private getHeatCategories(heatNumber: number): CategoryItem[] {
        if (heatNumber === -1) {
            return this.data.unassigned;
        } else {
            return this.data.heats[heatNumber].categories;
        }
    }

    public swapCategories(sourceHeat: number, targetHeat: number, sourceIndex: number, targetIndex: number) {
        const item = this.getHeatCategories(sourceHeat).splice(sourceIndex, 1);
        this.getHeatCategories(targetHeat).splice(targetIndex, 0, item[0]);
    }

    public setHeatStartNumber(heatNumber: number, startNumber: number) {
        this.data.heats[heatNumber].startNumber = startNumber;
        let minimalStartNumber = 1;
        this.data.heats.forEach(value => {
            value.startNumberValid = value.startNumber >= minimalStartNumber;
            minimalStartNumber = value.startNumber + value.categories.length;
        });
    }

    public async save() {
        const promises: Array<Promise<Team>> = [];
        this.data.heats.forEach(heatData => {
            let nextNumber = heatData.startNumber;
            heatData.categories.forEach(categoryItem => {
                categoryItem.teams.forEach(team => {
                    promises.push(this._teamRepository.update({...team, heatId: heatData.heat.id, number: nextNumber}));
                    nextNumber++;
                });
            });
        });
        this.data.unassigned.forEach(categoryItem => {
            categoryItem.teams.forEach(team => {
                promises.push(this._teamRepository.update({...team, heatId: null, number: null}));
            });
        });
        await Promise.all(promises);
    }

    public presetTWW() {
        const allItems = new Map<Category, CategoryItem>();
        this.data.unassigned.forEach(item => allItems.set(item.category, item));
        this.data.heats.forEach(heatData => {
            heatData.categories.forEach(item => allItems.set(item.category, item));
        });
        this.data.unassigned = [];
        let start = 1;
        for (let i = 0; i < 3; i++) {
            const heatData = this.data.heats[i];
            heatData.categories = [];
            heatData.startNumber = start;
            const preset: Category[] = predefinedHeatOrderTWW[i];
            let teamCount = 0;
            preset.forEach(category => {
                const item = allItems.get(category);
                if (item !== undefined) {
                    teamCount += item.teams.length;
                    heatData.categories.push(item);
                    allItems.delete(category);
                }
            });
            start += teamCount;
        }
        this.data.unassigned = Array.from(allItems.values());
    }
}
