import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Content from '../../components/content';
import ContentTitle from '../../components/content_title';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {Link} from 'react-router-dom';
import Header from '../../components/header';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {TeamsPresenter} from './teams_presenter';
import {Edition} from '../../../../../models/edition';
import {observer} from 'mobx-react';
import Loading from '../../../components/loading';
import {decodeClubCode} from '../../../../support/club_code_decoder';
import {Team} from '../../../../../models/team';
import TableHead from '@material-ui/core/TableHead/TableHead';
import PrintOnly from '../../../components/print_only';
import Unprintable from '../../../components/unprintable';
import {Select} from '@material-ui/core';
import {ViewIcon} from '../../../components/icons/view_icon';

interface OwnProps {
    edition: Edition;
}

@observer
class Teams extends React.Component<OwnProps & PresenterProps<TeamsPresenter>> {
    private sortTeams(a: Team, b: Team): number {
        if (a.number === null && b.number === null) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
        if (a.number === null) {
            return -1;
        }
        if (b.number === null) {
            return 1;
        }
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }
        return 0;
    }

    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Teams',
                            link: '/edition/teams',
                        },
                    ]}>
                    Teams
                </Header>
                <Content>
                    <ContentTitle>Manage</ContentTitle>
                    <Button key="import" component={props => <a href={`/edition/import`} {...props} />}>
                        Import
                    </Button>
                    <Button key="manage" component={props => <a href="/edition/manage" {...props} />}>
                        Beheer
                    </Button>
                </Content>
                <Content>
                    <ContentTitle>Prints</ContentTitle>
                    <Button
                        key="prints-startlijst"
                        component={props => <a target="_blank" href={`/edition/prints/startlijst`} {...props} />}>
                        Startlijst
                    </Button>
                    <Button
                        key="prints-inschrijftafel-nummer"
                        component={props => (
                            <a href="/edition/prints/inschrijftafel/opnummer" target="_blank" {...props} />
                        )}>
                        Inschrijftafel (gesorteeerd op nummer)
                    </Button>
                    <Button
                        key="prints-inschrijftafel-naam"
                        component={props => (
                            <a href="/edition/prints/inschrijftafel/opnaam" target="_blank" {...props} />
                        )}>
                        Inschrijftafel (gesorteerd op naam)
                    </Button>
                    <Button
                        key="prints-inschrijvingen"
                        component={props => <a href="/edition/prints/inschrijvingen" target="_blank" {...props} />}>
                        Inschrijvingen
                    </Button>
                </Content>
                <Content>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="dense">
                                    <Select
                                        native
                                        value={this.props.presenter.activeFilterValue}
                                        onChange={(e: any) => this.props.presenter.onFilterChange(e)}>
                                        <option value="-1">- geen -</option>
                                        <option value="not-attending">Uitgevallen</option>
                                    </Select>
                                </TableCell>
                                <TableCell>#</TableCell>
                                <TableCell>Club</TableCell>
                                <TableCell>Team</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Boat</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.presenter.teams.sort(this.sortTeams).map(team => {
                                return (
                                    <TableRow key={team.id}>
                                        <TableCell padding="dense">
                                            {team.notes !== '' ? <AddAlertIcon /> : ''}
                                            {!team.isAttending ? <ClearIcon /> : ''}
                                        </TableCell>
                                        <TableCell>{team.number ? team.number : '-'}</TableCell>
                                        <TableCell>{decodeClubCode(team.clubCode)}</TableCell>
                                        <TableCell>{team.name}</TableCell>
                                        <TableCell>
                                            {team.category}
                                            {team.tags.length > 0
                                                ? ' (' + team.tags.map(tag => tag.name).join(', ') + ')'
                                                : null}
                                        </TableCell>
                                        <TableCell>{team.boat}</TableCell>
                                        <TableCell>
                                            <Link to={`/edition/team/${team.id}`}>
                                                <ViewIcon />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Content>
                <Button
                    variant="fab"
                    color="primary"
                    aria-label="add"
                    style={{float: 'right', margin: 20}}
                    component={props => <Link to="/edition/teams/add" {...(props as any)} />}>
                    <AddIcon />
                </Button>
            </Grid>
        );
    }
}

export default withPresenter<TeamsPresenter, OwnProps>(
    (props, component) => new TeamsPresenter(props.edition, component.persistence.teamRepository),
    Teams,
);
