import {Heat} from '../../models/heat';
import {Observable} from 'rxjs';
import {HeatRepository} from './repositories/heat_repository';
import {HeatTimeProvider} from './heat_time_provider';
import {Team} from '../../models/team';
import {TeamProvider} from './team_provider';
import {RecordedTimePair} from './models/recorded_time_pair';
import {TimeRecorder} from '../recording/time_recorder';
import {RecordTimeRequest} from './models/record_time_request';
import {RecordedAs, RecordedTime} from '../../models/recorded_time';
import {Maybe} from 'tsmonad';
import {Edition} from '../../models/edition';
import {flatMap, map, tap} from 'rxjs/operators';
import {EditionRepository} from '../../administration/persistence/edition/edition_repository';

export interface HeatWithEdition extends Heat {
    edition: Edition;
}

export interface TrackingController {
    times(): Observable<number | null>;

    heat(): Observable<Maybe<HeatWithEdition>>;

    attendingTeams(): Observable<Team[]>;

    sync(): Promise<void>;

    allTimes(): Observable<RecordedTime[]>;

    recordedTimes(teamId: string): Observable<RecordedTimePair>;

    recordedTimesForRecordedAs(recordedAs: RecordedAs): Observable<RecordedTime[]>;

    recordTime(recordTimeRequest: RecordTimeRequest): Promise<void>;
}

export class DefaultTrackingController implements TrackingController {
    constructor(
        private heatId: string,
        private heatRepository: HeatRepository,
        private editionRepository: EditionRepository,
        private heatTimeProvider: HeatTimeProvider,
        private teamProvider: TeamProvider,
        private timeRecorder: TimeRecorder,
    ) {}

    public heat(): Observable<Maybe<HeatWithEdition>> {
        return this.heatRepository.findByIdMaybe(this.heatId).pipe(
            flatMap((heatMaybe: Maybe<Heat>) => {
                return heatMaybe.caseOf({
                    just: heat => this.toHeatWithEdition(heat),
                    nothing: async () => Maybe.nothing<HeatWithEdition>(),
                });
            }),
        );
    }

    private async toHeatWithEdition(heat: Heat): Promise<Maybe<HeatWithEdition>> {
        const edition = await this.editionRepository.findById(heat.editionId);
        if (edition === null) {
            return Maybe.nothing<HeatWithEdition>();
        }
        return Maybe.just<HeatWithEdition>({...heat, edition});
    }

    public attendingTeams(): Observable<Team[]> {
        return this.teamProvider.attendingTeams();
    }

    public sync(): Promise<void> {
        return this.timeRecorder.sync();
    }

    public async recordTime(recordTimeRequest: RecordTimeRequest): Promise<void> {
        return this.timeRecorder.recordTime(recordTimeRequest);
    }

    public allTimes(): Observable<RecordedTime[]> {
        return this.timeRecorder.allTimes();
    }

    public recordedTimes(teamId: string): Observable<RecordedTimePair> {
        return this.timeRecorder.recordedTimes(teamId);
    }

    public recordedTimesForRecordedAs(recordedAs: RecordedAs): Observable<RecordedTime[]> {
        return this.allTimes().pipe(
            map((times: RecordedTime[]) =>
                times.filter((time: RecordedTime) => time.context.recordedAs === recordedAs),
            ),
        );
    }

    public times(): Observable<number | null> {
        return this.heatTimeProvider.time();
    }
}
