import * as Rx from 'rxjs';

export class CompositeSubscription {
    private _list: Rx.Subscription[] = [];

    public add(subscription: Rx.Subscription) {
        this._list.push(subscription);
    }

    public clear() {
        this._list.forEach(subscription => {
            subscription.unsubscribe();
        });

        this._list = [];
    }
}
