import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {ImportScreenPresenter} from './import_presenter';
import Content from '../components/content';
import ContentTitle from '../components/content_title';
import {observer} from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Header from '../components/header';
import Button from '@material-ui/core/Button';
import {Edition} from '../../../../models/edition';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';

interface OwnProps {
    edition: Edition;
}

@observer
class ImportScreen extends React.Component<OwnProps & PresenterProps<ImportScreenPresenter>> {
    public render() {
        return (
            <Grid item xs={12}>
                <Header>Import</Header>
                <Content>
                    <ContentTitle>Upload a file from KNRB</ContentTitle>
                    <input type="file" onChange={(e: any) => this.props.presenter.onFileChange(e.target.files)} />
                    <Grid justify="flex-end" container>
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={this.props.presenter.file === null}
                                style={{marginTop: 16}}
                                onClick={() => this.props.presenter.onSubmitClicked()}>
                                {this.props.presenter.loading ? 'Importing..' : 'Import'}
                            </Button>
                            <LinearProgress variant="determinate" value={this.props.presenter.progress} />
                        </Grid>
                    </Grid>
                </Content>
            </Grid>
        );
    }
}

export default withPresenter<ImportScreenPresenter, OwnProps>(
    (props, component) =>
        new ImportScreenPresenter(
            props.edition,
            component.persistence.heatRepository,
            component.persistence.teamRepository,
            component.persistence.participantRepository,
        ),
    ImportScreen,
);
