import {Observable, of} from 'rxjs';

import {filter, first, map, mergeMap, switchMap} from 'rxjs/operators';
import {RecordedAs, RecordedTime} from '../../../models/recorded_time';
import {Team} from '../../../models/team';
import {RecordedTimeRepository} from '../../persistence/recorded_time/recorded_time_repository';
import {TeamRepository} from '../../persistence/team/team_repository';
import {ServerTimeProvider} from '../../../server_time/server_time_provider';

export interface RecordedTimePair {
    start: RecordedTime | null;
    finish: RecordedTime | null;
}

export class RecordedTimesInteractor {
    private _teamRepository: TeamRepository;
    private _recordedTimeRepository: RecordedTimeRepository;
    private _serverTimeProvider: ServerTimeProvider;

    constructor(
        teamRepository: TeamRepository,
        recordedTimeRepository: RecordedTimeRepository,
        serverTimeProvider: ServerTimeProvider,
    ) {
        this._teamRepository = teamRepository;
        this._recordedTimeRepository = recordedTimeRepository;
        this._serverTimeProvider = serverTimeProvider;
    }

    public forTeamId(teamId: string): Observable<RecordedTimePair> {
        return this._teamRepository.findById(teamId).pipe(
            filter<Team | null, Team>((team: Team | null): team is Team => team !== null),
            switchMap((team: Team) => {
                if (team.heatId === null) {
                    return of({start: null, finish: null});
                }
                return this._recordedTimeRepository.findByHeatId(team.heatId).pipe(
                    map(recordedTimes => {
                        return {
                            start:
                                recordedTimes.find(recordedTime => recordedTime.id === team.recordedStartTimeId) ||
                                null,
                            finish:
                                recordedTimes.find(recordedTime => recordedTime.id === team.recordedFinishTimeId) ||
                                null,
                        };
                    }),
                );
            }),
        );
    }

    public forHeatId(heatId: string): Observable<RecordedTime[]> {
        return this._recordedTimeRepository.findByHeatId(heatId);
    }

    public registerManualTime(heatId: string, time: number): Promise<RecordedTime> {
        return this._recordedTimeRepository.create(heatId, time, {
            recordedAs: RecordedAs.Loose,
            teamId: null,
        });
    }

    public async updateStartTime(recordedTime: RecordedTime, teamId: string): Promise<Team> {
        const teamsInHeat = await this._teamRepository
            .findByHeatId(recordedTime.heatId)
            .pipe(first())
            .toPromise();

        await this.resetRecordedTime(teamsInHeat, recordedTime);
        const newTeam = teamsInHeat.find(team => team.id === teamId);

        return await this._teamRepository.update({
            ...(newTeam as Team),
            recordedStartTimeId: recordedTime.id,
        });
    }

    public async updateFinishTime(recordedTime: RecordedTime, teamId: string): Promise<Team> {
        const teamsInHeat = await this._teamRepository
            .findByHeatId(recordedTime.heatId)
            .pipe(first())
            .toPromise();

        await this.resetRecordedTime(teamsInHeat, recordedTime);
        const newTeam = teamsInHeat.find(team => team.id === teamId);

        return await this._teamRepository.update({
            ...(newTeam as Team),
            recordedFinishTimeId: recordedTime.id,
        });
    }

    private async resetRecordedTime(teamsInHeat: Team[], recordedTime: RecordedTime) {
        const previousTeamWithRecordedTimeAsStartTime = teamsInHeat.find(
            team => team.recordedStartTimeId === recordedTime.id,
        );
        if (previousTeamWithRecordedTimeAsStartTime) {
            await this._teamRepository.update({
                ...(previousTeamWithRecordedTimeAsStartTime as Team),
                recordedStartTimeId: null,
            });
        }
        const previousTeamWithRecordedTimeAsFinishTime = teamsInHeat.find(
            team => team.recordedFinishTimeId === recordedTime.id,
        );
        if (previousTeamWithRecordedTimeAsFinishTime) {
            await this._teamRepository.update({
                ...(previousTeamWithRecordedTimeAsFinishTime as Team),
                recordedFinishTimeId: null,
            });
        }
    }
}
