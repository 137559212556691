import {lazy} from '../client/support/lazy';
import {SharedServerTimeInteractor} from './shared_server_time_interactor';
import {WeightedServerTimeInteractor} from './weighted_server_time_interactor';
import {BestServerTimeInteractor} from './best_server_time_interactor';
import {BurstServerTimeInteractor} from './burst_server_time_interactor';
import {SingleServerTimeInteractor} from './single_server_time_interactor';
import {ServerTimeProvider, ServerTimeProviderImpl} from './server_time_provider';
import {TimeProviderImpl} from './time_provider';
import {SharedServerTimeProvider} from './shared_server_time_provider';

export interface ServerTimeComponent {
    serverTimeProvider: ServerTimeProvider;
}

export class DefaultServerTimeComponent implements ServerTimeComponent {
    @lazy()
    public get serverTimeProvider(): ServerTimeProvider {
        return new SharedServerTimeProvider(
            new ServerTimeProviderImpl(this.serverTimeInteractor, new TimeProviderImpl()),
        );
    }

    @lazy()
    private get serverTimeInteractor() {
        return new SharedServerTimeInteractor(
            new WeightedServerTimeInteractor(
                new BestServerTimeInteractor(
                    new BurstServerTimeInteractor(new SingleServerTimeInteractor(), 5, 2, 30000),
                ),
            ),
        );
    }
}
