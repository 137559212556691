import {HandicapTable} from '../handicap_table';

export class Handicap2019Short extends HandicapTable {
    protected create(): void {
        this.handicaps.set(13, {male: 0.87, female: 0.783});
        this.handicaps.set(14, {male: 0.911, female: 0.82});
        this.handicaps.set(15, {male: 0.937, female: 0.843});
        this.handicaps.set(16, {male: 0.953, female: 0.858});
        this.handicaps.set(17, {male: 0.964, female: 0.868});
        this.handicaps.set(18, {male: 0.972, female: 0.875});
        this.handicaps.set(19, {male: 0.981, female: 0.883});
        this.handicaps.set(20, {male: 0.988, female: 0.889});
        this.handicaps.set(21, {male: 0.994, female: 0.895});
        this.handicaps.set(22, {male: 0.999, female: 0.899});
        this.handicaps.set(23, {male: 1.0, female: 0.9});
        this.handicaps.set(24, {male: 1.0, female: 0.9});
        this.handicaps.set(25, {male: 1.0, female: 0.9});
        this.handicaps.set(26, {male: 1.0, female: 0.9});
        this.handicaps.set(27, {male: 0.997, female: 0.897});
        this.handicaps.set(28, {male: 0.996, female: 0.896});
        this.handicaps.set(29, {male: 0.994, female: 0.895});
        this.handicaps.set(30, {male: 0.992, female: 0.893});
        this.handicaps.set(31, {male: 0.991, female: 0.892});
        this.handicaps.set(32, {male: 0.989, female: 0.89});
        this.handicaps.set(33, {male: 0.987, female: 0.888});
        this.handicaps.set(34, {male: 0.984, female: 0.886});
        this.handicaps.set(35, {male: 0.982, female: 0.884});
        this.handicaps.set(36, {male: 0.98, female: 0.882});
        this.handicaps.set(37, {male: 0.977, female: 0.879});
        this.handicaps.set(38, {male: 0.975, female: 0.878});
        this.handicaps.set(39, {male: 0.972, female: 0.875});
        this.handicaps.set(40, {male: 0.97, female: 0.873});
        this.handicaps.set(41, {male: 0.967, female: 0.87});
        this.handicaps.set(42, {male: 0.964, female: 0.868});
        this.handicaps.set(43, {male: 0.961, female: 0.865});
        this.handicaps.set(44, {male: 0.958, female: 0.862});
        this.handicaps.set(45, {male: 0.955, female: 0.86});
        this.handicaps.set(46, {male: 0.952, female: 0.857});
        this.handicaps.set(47, {male: 0.949, female: 0.854});
        this.handicaps.set(48, {male: 0.946, female: 0.851});
        this.handicaps.set(49, {male: 0.942, female: 0.848});
        this.handicaps.set(50, {male: 0.939, female: 0.845});
        this.handicaps.set(51, {male: 0.935, female: 0.842});
        this.handicaps.set(52, {male: 0.932, female: 0.839});
        this.handicaps.set(53, {male: 0.928, female: 0.835});
        this.handicaps.set(54, {male: 0.924, female: 0.832});
        this.handicaps.set(55, {male: 0.92, female: 0.828});
        this.handicaps.set(56, {male: 0.916, female: 0.824});
        this.handicaps.set(57, {male: 0.912, female: 0.821});
        this.handicaps.set(58, {male: 0.908, female: 0.817});
        this.handicaps.set(59, {male: 0.903, female: 0.813});
        this.handicaps.set(60, {male: 0.898, female: 0.808});
        this.handicaps.set(61, {male: 0.893, female: 0.804});
        this.handicaps.set(62, {male: 0.888, female: 0.799});
        this.handicaps.set(63, {male: 0.883, female: 0.795});
        this.handicaps.set(64, {male: 0.877, female: 0.789});
        this.handicaps.set(65, {male: 0.871, female: 0.784});
        this.handicaps.set(66, {male: 0.865, female: 0.779});
        this.handicaps.set(67, {male: 0.859, female: 0.773});
        this.handicaps.set(68, {male: 0.852, female: 0.767});
        this.handicaps.set(69, {male: 0.845, female: 0.761});
        this.handicaps.set(70, {male: 0.837, female: 0.753});
        this.handicaps.set(71, {male: 0.829, female: 0.746});
        this.handicaps.set(72, {male: 0.821, female: 0.739});
        this.handicaps.set(73, {male: 0.812, female: 0.731});
        this.handicaps.set(74, {male: 0.802, female: 0.722});
        this.handicaps.set(75, {male: 0.792, female: 0.713});
        this.handicaps.set(76, {male: 0.782, female: 0.704});
        this.handicaps.set(77, {male: 0.771, female: 0.694});
        this.handicaps.set(78, {male: 0.759, female: 0.683});
        this.handicaps.set(79, {male: 0.747, female: 0.672});
        this.handicaps.set(80, {male: 0.734, female: 0.661});
        this.handicaps.set(81, {male: 0.72, female: 0.648});
        this.handicaps.set(82, {male: 0.706, female: 0.635});
        this.handicaps.set(83, {male: 0.691, female: 0.622});
        this.handicaps.set(84, {male: 0.675, female: 0.608});
        this.handicaps.set(85, {male: 0.658, female: 0.592});
        this.handicaps.set(86, {male: 0.641, female: 0.577});
        this.handicaps.set(87, {male: 0.623, female: 0.561});
        this.handicaps.set(88, {male: 0.603, female: 0.543});
        this.handicaps.set(89, {male: 0.583, female: 0.525});
        this.handicaps.set(90, {male: 0.562, female: 0.506});
        this.handicaps.set(91, {male: 0.54, female: 0.486});
    }
}
