import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {Theme} from '@material-ui/core/styles';

interface OwnProps {}

const styles = (theme: Theme) => ({
    root: {
        marginBottom: '12px',
    },
});

class UnstyledContentTitle extends React.Component<OwnProps & WithStyles<'root'>> {
    public render() {
        return (
            <Typography variant="title" className={this.props.classes.root}>
                {this.props.children}
            </Typography>
        );
    }
}

export const ContentTitle = withStyles(styles)(UnstyledContentTitle);
