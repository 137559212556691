import {observer} from 'mobx-react';
import * as React from 'react';
import {Draggable, DraggableProvided, DraggableStateSnapshot} from 'react-beautiful-dnd';

interface TeamDraggableProps {
    teamId: string;
    index: number;
}

@observer
export class TeamDraggable extends React.Component<TeamDraggableProps> {
    public render() {
        const props = this.props;
        return (
            <Draggable draggableId={props.teamId} index={props.index}>
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {props.children}
                    </div>
                )}
            </Draggable>
        );
    }
}
