import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography/Typography';
import {FormControl, FormHelperText, Input, InputLabel, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import {Heat} from '../../../../models/heat';

interface OwnProps {
    heat: Heat;
    open: boolean;
    onClose: () => void;
    onAdd: (time: number) => void;
}

interface State {
    value: string;
}

const styles = (theme: Theme) => ({
    paper: {
        position: 'absolute' as any,
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
});

class UnstyledAddTimeModal extends React.Component<OwnProps & WithStyles<'paper'>, State> {
    public state: State = {
        value: '',
    };

    constructor(props: Readonly<OwnProps & WithStyles<'paper'>>) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    private handleChange(e: any) {
        this.setState({
            value: e.target.value,
        });
    }

    private submitButtonDisabled() {
        const parts = this.state.value.split(':');
        return parts.length !== 4;
    }

    private handleClickSubmit() {
        const parts = this.state.value.split(':');
        if (parts.length !== 4) {
            return;
        }
        const [hours, minutes, seconds, milliseconds] = parts.map(part => parseInt(part, 10));

        if (this.props.heat.startTimeMs === null) {
            throw new Error('Can not manually add reference time without heat start time');
        }
        const referenceTime = parseFloat(this.props.heat.startTimeMs as any);

        const time = hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds;

        this.props.onAdd(referenceTime + time);
    }

    public render() {
        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open}
                onClose={this.props.onClose}>
                <div className={this.props.classes.paper}>
                    <Typography variant="h6">Manual entry</Typography>
                    <Typography variant="subtitle1">
                        Manually add a loose time from the stopwatch, if you want to assign it edit is as any other
                        recorded time
                    </Typography>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="number">Time</InputLabel>
                        <Input id="number" value={this.state.value} onChange={this.handleChange} />
                        <FormHelperText>hh:mm:ss:sss</FormHelperText>
                    </FormControl>
                    <Button
                        style={{float: 'right'}}
                        disabled={this.submitButtonDisabled()}
                        component={(props: any) => <a onClick={() => this.handleClickSubmit()} {...props} />}>
                        Add
                    </Button>
                    <Button
                        style={{float: 'right'}}
                        component={(props: any) => <a onClick={() => this.props.onClose()} {...props} />}>
                        Close
                    </Button>
                </div>
            </Modal>
        );
    }
}

export const AddTimeModal = withStyles(styles)(UnstyledAddTimeModal);
