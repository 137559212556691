import * as React from 'react';
import {observer} from 'mobx-react';
import {ResultRequest} from '../../../../models/result_request';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {ResultRequestFormPresenter} from './result_request_form_presenter';
import Content from '../components/content';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import {ResultMode} from '../../../business/results/result_mode';
import {Grouping} from '../../../business/results/grouping';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Edition} from '../../../../models/edition';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

interface OwnProps {
    edition: Edition;
    resultRequest: ResultRequest;
    onChange: (resultRequest: ResultRequest) => void;
    onClear: () => void;
    onSubmit: () => void;
}

@observer
class ResultRequestFormComponent extends React.Component<OwnProps & PresenterProps<ResultRequestFormPresenter>> {
    public render() {
        return (
            <Content>
                <FormControl fullWidth>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input
                        id="name"
                        value={this.props.resultRequest.name}
                        onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                    />
                </FormControl>
                <FormControlLabel
                    label="Include in narrowcasting"
                    control={
                        <Checkbox
                            checked={this.props.resultRequest.includeInNarrowcasting}
                            onChange={(e: any, checked: boolean) =>
                                this.props.presenter.onIncludeInNarrowcastingChange(checked)
                            }
                        />
                    }
                />
                <FormControl fullWidth>
                    <InputLabel variant="standard">Modus</InputLabel>
                    <Select
                        native
                        value={this.props.resultRequest.mode}
                        onChange={(e: any) => this.props.presenter.onModeChange(e.target.value)}
                        input={<Input id="result-mode" />}>
                        {Object.values(ResultMode).map(resultMode => (
                            <option key={resultMode} value={resultMode}>
                                {resultMode}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel variant="standard">Grouping</InputLabel>
                    <Select
                        native
                        value={this.props.resultRequest.grouping}
                        onChange={(e: any) => this.props.presenter.onGroupingChange(e.target.value)}
                        input={<Input id="grouping" />}>
                        {Object.values(Grouping).map(grouping => (
                            <option key={grouping} value={grouping}>
                                {grouping}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <br />
                {this.props.resultRequest.grouping === Grouping.Tags ? (
                    <>
                        <InputLabel variant="standard">Tags</InputLabel>
                        <br />
                        {this.props.presenter.tags.map(tag => (
                            <FormControlLabel
                                key={tag.name}
                                label={tag.name}
                                control={
                                    <Checkbox
                                        checked={
                                            this.props.resultRequest.tags !== undefined &&
                                            this.props.resultRequest.tags.some(t => t.name === tag.name)
                                        }
                                        onChange={(e: any, checked: boolean) =>
                                            this.props.presenter.onTagCheck(tag, checked)
                                        }
                                    />
                                }
                            />
                        ))}
                        <br />
                    </>
                ) : null}
                {this.props.resultRequest.grouping === Grouping.Category ? (
                    <>
                        <InputLabel variant="standard">Categorieen</InputLabel>
                        <br />
                        {this.props.presenter.categories.map(category => (
                            <FormControlLabel
                                key={category}
                                label={category}
                                control={
                                    <Checkbox
                                        checked={
                                            this.props.resultRequest.categories !== undefined &&
                                            this.props.resultRequest.categories.some(c => c === category)
                                        }
                                        onChange={(e: any, checked: boolean) =>
                                            this.props.presenter.onCategoryCheck(category, checked)
                                        }
                                    />
                                }
                            />
                        ))}
                    </>
                ) : null}
                {this.props.resultRequest.grouping === Grouping.Heat ? (
                    <>
                        <InputLabel variant="standard">Heats</InputLabel>
                        <br />
                        {this.props.presenter.heats.map(heat => (
                            <FormControlLabel
                                key={heat.id}
                                label={heat.name}
                                control={
                                    <Checkbox
                                        checked={
                                            this.props.resultRequest.heatIds !== undefined &&
                                            this.props.resultRequest.heatIds.indexOf(heat.id) !== -1
                                        }
                                        onChange={(e: any, checked: boolean) =>
                                            this.props.presenter.onHeatCheck(heat, checked)
                                        }
                                    />
                                }
                            />
                        ))}
                    </>
                ) : null}
                <Grid justify="flex-end" container>
                    <Grid item>
                        <Button
                            variant="contained"
                            style={{marginTop: 16, marginRight: 16}}
                            onClick={() => this.props.onClear()}>
                            Clear
                        </Button>
                        <Button variant="contained" style={{marginTop: 16}} onClick={() => this.props.onSubmit()}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Content>
        );
    }
}

export const ResultRequestForm = withPresenter<ResultRequestFormPresenter, OwnProps>(
    (props, component) =>
        new ResultRequestFormPresenter(
            props.resultRequest,
            props.onChange,
            props.edition,
            component.persistence.teamRepository,
            component.persistence.heatRepository,
        ),
    ResultRequestFormComponent,
);
