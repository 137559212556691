import * as React from 'react';
import Unprintable from '../components/unprintable';
import TopBar from './components/top_bar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import {Link} from 'react-router-dom';
import TabContainer from './components/tab_container';
import Grid from '@material-ui/core/Grid';
import {Theme, WithStyles} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 30,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    tabs: {
        flexGrow: 1,
        maxWidth: 1200,
        margin: '0 auto',
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
    },
});

interface State {
    tab: string;
}

type Styles = WithStyles<'root' | 'tabs' | 'appBar'>;

interface OwnProps {
    wide?: boolean;
}

class StyledLayout extends React.Component<OwnProps & Styles, State> {
    public state: State = {
        tab: window.location.pathname.split('/')[2] || 'heats',
    };

    public render() {
        return (
            <div>
                <Unprintable>
                    <TopBar />
                    <AppBar position="static" className={this.props.classes.appBar}>
                        <div className={this.props.classes.tabs}>
                            <Tabs value={this.state.tab} onChange={(event, value) => this.setState({tab: value})}>
                                <Tab
                                    value="heats"
                                    label="Heats"
                                    component={props => <Link to="/edition/heats" {...(props as any)} />}
                                />
                                <Tab
                                    value="teams"
                                    label="Teams"
                                    component={props => <Link to="/edition/teams" {...(props as any)} />}
                                />
                                <Tab
                                    value="results"
                                    label="Uitslagen"
                                    component={props => <Link to="/edition/results" {...(props as any)} />}
                                />
                                <Tab
                                    value="heateditor"
                                    label="Heatindeling"
                                    component={props => <Link to="/edition/heateditor" {...(props as any)} />}
                                />
                                {/*<Tab*/}
                                {/*    value="participants"*/}
                                {/*    label="Deelnemers"*/}
                                {/*    component={props => <Link to="/edition/participants" {...(props as any)} />}*/}
                                {/*/>*/}
                                <Tab
                                    value="autoassign"
                                    label="Batch assign"
                                    component={props => <Link to="/edition/autoassign" {...(props as any)} />}
                                />
                            </Tabs>
                        </div>
                    </AppBar>
                </Unprintable>
                <div className={this.props.classes.root}>
                    <TabContainer>
                        <Grid container spacing={8}>
                            {this.props.wide === undefined || !this.props.wide ? this.props.children : null}
                        </Grid>
                    </TabContainer>
                </div>
                {this.props.wide === true ? this.props.children : null}
            </div>
        );
    }
}

export const Layout = withStyles(styles)(StyledLayout);
