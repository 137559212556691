import * as React from 'react';
import {RecordedAs, RecordedTime} from '../../../../../models/recorded_time';
import {VictoryAxis, VictoryChart, VictoryScatter, VictoryTheme} from 'victory';
import {Team} from '../../../../../models/team';

interface OwnProps {
    recordedTimes: RecordedTime[];
    teams: Team[] | null;
}

export class StartPerformance extends React.Component<OwnProps> {
    private toChart(recordedTimes: RecordedTime[]) {
        const startTimes = recordedTimes.filter(recordedTime => recordedTime.context.recordedAs === RecordedAs.Start);
        const diffs = startTimes.reduce<number[]>((previous: number[], startTime: RecordedTime, index: number) => {
            if (index === 0) {
                return previous;
            }

            const diffMs = startTime.timeMs - startTimes[index - 1].timeMs;
            previous.push(diffMs);

            return previous;
        }, []);

        return diffs.map((diffMs, index) => {
            return {
                x: index,
                y: diffMs / 1000,
            };
        });
    }

    private getDomain(data: Array<{x: number; y: number}>, teams: Team[]): {x: [number, number]; y: [number, number]} {
        return {
            x: [0, teams.length],
            y: [0, Math.ceil(Math.max(...data.map(item => item.y)))],
        };
    }

    public render() {
        if (this.props.recordedTimes.length <= 1 || this.props.teams === null) {
            return <div style={{height: '350px', width: '896px'}} />;
        }
        const data = this.toChart(this.props.recordedTimes);

        return (
            <div style={{height: '350px', width: '896px'}}>
                <VictoryChart
                    height={350}
                    width={896}
                    theme={VictoryTheme.material}
                    domain={this.getDomain(data, this.props.teams)}>
                    <VictoryScatter style={{data: {fill: '#303f9f'}}} size={3} data={data} />
                    <VictoryAxis label="Δt (s)0" dependentAxis />
                    <VictoryAxis crossAxis label="# in start" />
                </VictoryChart>
            </div>
        );
    }
}
