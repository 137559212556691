import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {AddTeamPresenter} from './teams_add_presenter';
import Header from '../../components/header';
import Grid from '@material-ui/core/Grid';
import Content from '../../components/content';
import {observer} from 'mobx-react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import {Edition} from '../../../../../models/edition';
import Select from '@material-ui/core/Select';
import Loading from '../../../components/loading';
import {categories} from '../../../../../models/team';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import {TagsInputWrapper} from '../../components/tags_input_wrapper';

interface OwnProps {
    edition: Edition;
}

@observer
class AddTeamScreen extends React.Component<OwnProps & PresenterProps<AddTeamPresenter>> {
    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        if (this.props.presenter.heats === undefined) {
            //TODO add fancy 500
            return <div>Error</div>;
        }
        return (
            <Grid item xs={12}>
                <Header>Add team</Header>
                <Content>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            id="name"
                            value={this.props.presenter.name}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="boat">Boat</InputLabel>
                        <Input
                            id="boat"
                            value={this.props.presenter.boat}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onBoatChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="club_code">Club code</InputLabel>
                        <Input
                            id="club_codee"
                            value={this.props.presenter.clubCode}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onClubCodeChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="category">Category</InputLabel>
                        <Select
                            native
                            value={this.props.presenter.category}
                            onChange={(e: any) => this.props.presenter.onCategoryChange(e.target.value)}
                            input={<Input id="category" />}>
                            <option value="" />
                            {categories.map(category => {
                                return (
                                    <option key={category} value={category}>
                                        {category}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="tags">tags</InputLabel>
                        <Input
                            value={this.props.presenter.tags}
                            onChange={tags => this.props.presenter.onTagsChange(tags as any)}
                            inputComponent={TagsInputWrapper as any}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="heat-id">Heat</InputLabel>
                        <Select
                            native
                            value={this.props.presenter.heatId}
                            onChange={(e: any) => this.props.presenter.onHeatIdChange(e.target.value)}
                            input={<Input id="heat-id" />}>
                            <option value="" />
                            {this.props.presenter.heats.map(heat => {
                                return (
                                    <option key={heat.id} value={heat.id}>
                                        {heat.name}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Grid justify="flex-end" container>
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={!this.props.presenter.submitIsEnabled}
                                style={{marginTop: 16}}
                                onClick={() => this.props.presenter.onSubmitClicked()}>
                                {this.props.presenter.loading ? 'Creating..' : 'Create'}
                            </Button>
                        </Grid>
                    </Grid>
                </Content>
            </Grid>
        );
    }
}

export default withPresenter<AddTeamPresenter, OwnProps>(
    (props, component) =>
        new AddTeamPresenter(props.edition, component.persistence.teamRepository, component.persistence.heatRepository),
    AddTeamScreen,
);
