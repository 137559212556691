import {observer} from 'mobx-react';
import * as React from 'react';
import {Droppable, DroppableProvided, DroppableStateSnapshot} from 'react-beautiful-dnd';

interface TeamDroppableProps {
    droppableId: string;
}

@observer
export class TeamDroppable extends React.Component<TeamDroppableProps> {
    public render() {
        const props = this.props;
        return (
            <Droppable droppableId={props.droppableId} key={props.droppableId}>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        // style={{backgroundColor: snapshot.isDraggingOver ? 'blue' : 'gray'}}
                        {...provided.droppableProps}>
                        {props.children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }
}
