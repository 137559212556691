import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';
import {Team} from '../../../../../models/team';
import {Heat} from '../../../../../models/heat';
import {Edition} from '../../../../../models/edition';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {CompositeSubscription} from '../../../../support/composit_subscription';
import {take} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {Tag} from '../../../../../models/tag';
import {Participant} from '../../../../../models/participant';
import {ParticipantRepository} from '../../../../persistence/participant/participant_repository';

export interface HeatEditorData {
    heats: Array<{
        heat: Heat;
        teams: Team[];
        startNumber: number;
        startNumberValid: boolean;
    }>;
    unassigned: Team[];
    participants: Participant[];
    validationMessages: string[];
}

export class HeatEditorScreenPresenter implements Presenter {
    @observable public data: HeatEditorData = {heats: [], unassigned: [], validationMessages: [], participants: []};

    private readonly _edition: Edition;
    private readonly _teamRepository: TeamRepository;
    private readonly _heatRepository: HeatRepository;
    private readonly _participantRepository: ParticipantRepository;
    private _subscription = new CompositeSubscription();

    private participants = new Map<string, Participant>();

    constructor(
        edition: Edition,
        teamRepository: TeamRepository,
        heatRepository: HeatRepository,
        participantRepository: ParticipantRepository,
    ) {
        this._edition = edition;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
        this._participantRepository = participantRepository;
    }

    public mount() {
        const heatsObs = this._heatRepository.findByEditionId(this._edition.id).pipe(take(1));
        const teamsObs = this._teamRepository.findByEditionId(this._edition.id).pipe(take(1));
        const participantsObs = this._participantRepository.findByEditionId(this._edition.id).pipe(take(1));
        this._subscription.add(
            forkJoin([heatsObs, teamsObs, participantsObs]).subscribe(
                async (values: [Heat[], Team[], Participant[]]) => {
                    const participants: Participant[] = values[2];
                    participants.forEach(participant => {
                        this.participants.set(participant.id, participant);
                    });
                    const heats: Heat[] = values[0];
                    const teams: Team[] = values[1];
                    const heatIds: string[] = heats.map(heat => heat.id);
                    const data = {
                        heats: heats.map(heat => ({
                            heat: heat,
                            teams: teams.filter(team => team.heatId === heat.id).sort(this.sortByNumber),
                            startNumber: 0,
                            startNumberValid: false,
                        })),
                        unassigned: teams.filter(
                            team => team.heatId === null || heatIds.findIndex(value => value === team.heatId) === -1,
                        ),
                        validationMessages: [],
                        participants: participants,
                    };
                    let nextStartNumber = 1;
                    data.heats.forEach(value => {
                        value.startNumber = nextStartNumber;
                        value.startNumberValid = true;
                        nextStartNumber = value.startNumber + value.teams.length;
                    });
                    this.data = data;
                    this.revalidate();
                },
            ),
        );
    }

    public unmount(): void {
        this._subscription.clear();
    }

    private getHeatTeams(heatNumber: number): Team[] {
        if (heatNumber === -1) {
            return this.data.unassigned;
        } else {
            return this.data.heats[heatNumber].teams;
        }
    }

    private setHeatTeams(heatNumber: number, teams: Team[]): void {
        if (heatNumber === -1) {
            this.data.unassigned = teams;
        } else {
            this.data.heats[heatNumber].teams = teams;
        }
    }

    public swapTeams(sourceHeat: number, targetHeat: number, sourceIndex: number, targetIndex: number) {
        const source = this.getHeatTeams(sourceHeat);
        const target = this.getHeatTeams(targetHeat);
        const item = source.splice(sourceIndex, 1);
        target.splice(targetIndex, 0, item[0]);
        this.revalidate();
    }

    public setHeatStartNumber(heatNumber: number, startNumber: number) {
        this.data.heats[heatNumber].startNumber = startNumber;
        let minimalStartNumber = 1;
        this.data.heats.forEach(value => {
            value.startNumberValid = value.startNumber >= minimalStartNumber;
            minimalStartNumber = value.startNumber + value.teams.length;
        });
        this.revalidate();
    }

    public async assignNumbers() {
        const promises = new Array<Promise<Team>>();
        const newData = this.data;
        newData.heats.forEach(heat => {
            let nextNumber = heat.startNumber;
            heat.teams.map(team => {
                if (team.number !== nextNumber || team.heatId !== heat.heat.id) {
                    team.number = nextNumber;
                    team.heatId = heat.heat.id;
                }
                nextNumber++;
            });
        });
        this.data.unassigned.forEach(team => {
            team.number = null;
            team.heatId = null;
        });
        this.data = newData;
        this.revalidate();
    }

    public async save() {
        const promises = new Array<Promise<Team>>();
        const newData = this.data;
        newData.heats.forEach(heat => {
            heat.teams.map(team => {
                team.heatId = heat.heat.id;
                promises.push(this._teamRepository.update(team));
            });
        });
        this.data.unassigned.forEach(team => {
            team.heatId = null;
            promises.push(this._teamRepository.update(team));
        });
        await Promise.all(promises).then(() => {
            this.data = newData;
            this.revalidate();
        });
    }

    private sortByNumber(a: Team, b: Team): number {
        const an = a.number === null ? 0 : a.number;
        const bn = b.number === null ? 0 : b.number;
        return an - bn;
    }

    public async setTags(team: Team, tags: Tag[]) {
        const updatedTeam = await this._teamRepository.update({...team, tags: tags});
        team.tags = updatedTeam.tags;
    }

    public revalidate() {
        this.validate(this.data);
    }

    private validate(data: HeatEditorData) {
        const result: string[] = [];
        const assignCount = new Map<number, number>();
        const allTeams = data.unassigned.concat(...data.heats.map(heat => heat.teams));
        let noNumberCount = 0;
        allTeams.forEach(team => {
            if (team.number !== null) {
                const count = assignCount.get(team.number);
                if (count !== undefined) {
                    assignCount.set(team.number, count + 1);
                } else {
                    assignCount.set(team.number, 1);
                }
            } else if (data.unassigned.find(team2 => team2 === team) === undefined) {
                noNumberCount++;
            }
        });
        if (noNumberCount > 0) {
            result.push('There are ' + noNumberCount + ' teams assigned to a heat without a number');
        }
        assignCount.forEach((value: number, number: number) => {
            if (value > 1) {
                result.push('The number ' + number + ' is used for ' + value + ' teams');
            }
        });

        // Map<heatId, Map<participantId, roles>>
        const roleMapping = new Map<string, Map<string, string[]>>();
        const playRole = (heat: Heat, team: Team, participantId: string | null, role: string) => {
            if (participantId !== null) {
                let participantMap: Map<string, string[]> | undefined = roleMapping.get(heat.id);
                if (participantMap === undefined) {
                    participantMap = new Map<string, string[]>();
                    roleMapping.set(heat.id, participantMap);
                }
                let roles: string[] | undefined = participantMap.get(participantId);
                if (roles === undefined) {
                    roles = [];
                    participantMap.set(participantId, roles);
                }
                const description = 'As ' + role + ' in ' + this.describeTeam(team);
                roles.push(description);
            }
        };

        const boatMapping = new Map<string, Map<string, string[]>>();
        const useBoat = (heat: Heat, team: Team, boatName: string) => {
            if (boatName.length > 0) {
                let boatMap: Map<string, string[]> | undefined = boatMapping.get(heat.id);
                if (boatMap === undefined) {
                    boatMap = new Map<string, string[]>();
                    boatMapping.set(heat.id, boatMap);
                }
                let roles: string[] | undefined = boatMap.get(boatName);
                if (roles === undefined) {
                    roles = [];
                    boatMap.set(boatName, roles);
                }
                const description = this.describeTeam(team);
                roles.push(description);
            }
        };

        data.heats.forEach(heat => {
            heat.teams.forEach(team => {
                playRole(heat.heat, team, team.rower1Id, 'Rower');
                playRole(heat.heat, team, team.rower2Id, 'Rower');
                playRole(heat.heat, team, team.rower3Id, 'Rower');
                playRole(heat.heat, team, team.rower4Id, 'Rower');
                playRole(heat.heat, team, team.rower5Id, 'Rower');
                playRole(heat.heat, team, team.rower6Id, 'Rower');
                playRole(heat.heat, team, team.rower7Id, 'Rower');
                playRole(heat.heat, team, team.rower8Id, 'Rower');
                playRole(heat.heat, team, team.coxswainId, 'Coxswain');
                useBoat(heat.heat, team, team.boat);
            });
        });
        roleMapping.forEach((value, heatId) => {
            value.forEach((roles, participantId) => {
                if (roles.length > 1) {
                    const participantDescription: string = this.describeParticipant(participantId);
                    const heatDescription: string = this.describeHeat(heatId);
                    const message =
                        "'" + participantDescription + "' has " + roles.length + ' roles in ' + heatDescription;
                    result.push(message);
                    roles.forEach(role => {
                        result.push('Note: ' + role);
                    });
                }
            });
        });

        boatMapping.forEach((value, heatId) => {
            value.forEach((roles, boatName) => {
                if (roles.length > 1) {
                    const heatDescription: string = this.describeHeat(heatId);
                    const message = "Boat '" + boatName + "' is used multiple times in heat " + heatDescription;
                    result.push(message);
                    roles.forEach(role => {
                        result.push('Note: ' + role);
                    });
                }
            });
        });

        data.validationMessages = result;
    }

    private describeTeam(team: Team): string {
        if (team.number !== null) {
            return 'Team ' + team.number;
        } else {
            return 'Team ' + team.name;
        }
    }

    private describeParticipant(participantId: string): string {
        const participant: Participant | undefined = this.participants.get(participantId);
        if (participant === undefined) {
            return 'Unknown participant ' + participantId;
        } else {
            return participant.name;
        }
    }

    private describeHeat(heatId: string): string {
        const heatData = this.data.heats.find(heat => heat.heat.id === heatId);
        if (heatData === undefined) {
            return 'Unknown heat ' + heatId;
        } else {
            return heatData.heat.name;
        }
    }
}
