const clubs = new Map<string, string>();
clubs.set('AMY', 'Amycus');
clubs.set('ANK', 'Ank');
clubs.set('ARO', 'Aross');
clubs.set('COM', 'Compagnie');
clubs.set('DAV', 'Daventria');
clubs.set('DIE', 'Diep');
clubs.set('DOO', 'Doorslag');
clubs.set('EUR', 'Euros');
clubs.set('GOR', 'Gorcumse');
clubs.set('GOU', 'Gouda');
clubs.set('GRI', 'Grift');
clubs.set('HOO', 'Hoop');
clubs.set('IJS', 'IJssel');
clubs.set('JAS', 'Jason');
clubs.set('THY', 'Thyro');
clubs.set('TRI', 'Triton');
clubs.set('TUB', 'Tubantia');
clubs.set('VAD', 'Vada');
clubs.set('ZWO', 'Zwolsche');
clubs.set('WIL', 'Willem III');
clubs.set('LEY', 'Leythe');
clubs.set('RVM', 'Ruder Verein Münster');
clubs.set('THYL', 'Thyro/Leyte');
clubs.set('THYW', 'Thyro/Willem III');
clubs.set('NN', 'Onbekend');
clubs.set('AME', 'Amenophis');
clubs.set('ARC', 'ARC');
clubs.set('DDP', 'DDP');
clubs.set('HEM', 'Hemus');
clubs.set('NAA', 'Naarden');
clubs.set('POS', 'Poseidon');
clubs.set('SAL', 'Sallandse');
clubs.set('WEE', 'Weesp');
clubs.set('ROO', 'Roosendaalse');
clubs.set('DAAM', 'Daventria/Amycus');
clubs.set('DAZW', 'Daventria/Zwolsche');
clubs.set('ISA', 'Isala');
clubs.set('TRO', 'Tromp');
clubs.set('TUHU', 'Tubantia/Hunze');
clubs.set('TUZW', 'Tubantia/Zwolsche');
clubs.set('TUTY', 'Tubantia/Thyro');
clubs.set('NJO', 'Njord');
clubs.set('HUN', 'De Hunze');
clubs.set('BEA', 'Beatrix');
clubs.set('BOR', 'Boreas');
clubs.set('WAA', 'De Waal');
clubs.set('WAA', 'De Waal');
clubs.set('ORC', 'Orca');
clubs.set('HER', 'De Hertog');
clubs.set('Mun', 'Munster');
clubs.set('MEP', 'Meppen');
clubs.set('AEW', 'Aengwirden');
clubs.set('BIN', 'Binnenmaas');
clubs.set('DUD', 'Dudok van Heel');
clubs.set('EEM', 'De Eem');
clubs.set('HON', 'Honte');
clubs.set('LEE', 'Leerdam');

/**
 * Fetches the actual club name
 * @param {string} clubCode
 * @returns {string}
 */
export function decodeClubCode(clubCode: string): string {
    if (clubCode === '') {
        return '?';
    }
    return clubs.get(clubCode) || clubCode;
}
