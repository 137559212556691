import * as React from 'react';
import {ControleStartPresenter} from './controle_start_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import Loading from '../../../components/loading';
import {observer} from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {formatTimeMsClientTime, formatTimeMsUTC} from '../../../../support/format_time_ms';
import Grid from '@material-ui/core/Grid/Grid';
import PrintPage from '../../../components/print_page';

interface OwnProps {
    heatId: string;
}

@observer
class ControleStartPrint extends React.Component<OwnProps & PresenterProps<ControleStartPresenter>> {
    private renderTeamsWithoutStartTime() {
        return this.props.presenter.teams
            .map(team => {
                return {
                    team: team,
                    time: this.props.presenter.recordedTimes.find(
                        recordedTime => recordedTime.id === team.recordedStartTimeId,
                    ),
                };
            })
            .filter(teamTimePair => teamTimePair.time === undefined)
            .sort((a, b) => (a.team.name > b.team.name ? 1 : -1))
            .map(teamTimePair => {
                const rower1 = this.props.presenter.participants.find(
                    participant => participant.id === teamTimePair.team.rower1Id,
                );
                return (
                    <TableRow key={teamTimePair.team.id}>
                        <TableCell>{teamTimePair.team.name}</TableCell>
                        <TableCell>{rower1 ? rower1.name : '?'}</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>{teamTimePair.team.number ? teamTimePair.team.number : '?'}</TableCell>
                        <TableCell>-</TableCell>
                    </TableRow>
                );
            });
    }

    private renderTimes() {
        return this.props.presenter.teams
            .map(team => {
                return {
                    team: team,
                    time: this.props.presenter.recordedTimes.find(
                        recordedTime => recordedTime.id === team.recordedStartTimeId,
                    ),
                };
            })
            .filter(teamTimePair => teamTimePair.time !== undefined)
            .sort((a, b) => a.time!!.timeMs - b.time!!.timeMs)
            .map(teamTimePair => {
                const rower1 = this.props.presenter.participants.find(
                    participant => participant.id === teamTimePair.team.rower1Id,
                );
                return (
                    <TableRow key={teamTimePair.team.id}>
                        <TableCell>{teamTimePair.team.name}</TableCell>
                        <TableCell>{rower1 ? rower1.name : '?'}</TableCell>
                        <TableCell>{formatTimeMsClientTime(teamTimePair.time!!.timeMs)}</TableCell>
                        <TableCell>{teamTimePair.team.number ? teamTimePair.team.number : '?'}</TableCell>
                        <TableCell>
                            {formatTimeMsUTC(teamTimePair.time!!.timeMs - this.props.presenter.heat!!.startTimeMs!!)}
                        </TableCell>
                    </TableRow>
                );
            });
    }
    public render() {
        if (this.props.presenter.loading) {
            return (
                <Grid container>
                    <Loading />
                </Grid>
            );
        }
        if (this.props.presenter.heat === null) {
            //TODO add fancy 404
            return <div>Could not find heat</div>;
        }
        return (
            <PrintPage>
                <h2>Starttijd controle: {this.props.presenter.heat.name}</h2>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Naam</TableCell>
                            <TableCell>Slag</TableCell>
                            <TableCell>Starttijd</TableCell>
                            <TableCell>Num</TableCell>
                            <TableCell>Registratie</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderTeamsWithoutStartTime()}
                        {this.renderTimes()}
                    </TableBody>
                </Table>
            </PrintPage>
        );
    }
}

export default withPresenter<ControleStartPresenter, OwnProps>(
    (props, component) =>
        new ControleStartPresenter(
            props.heatId,
            component.persistence.teamRepository,
            component.persistence.heatRepository,
            component.persistence.participantRepository,
            component.persistence.recordedTimeRepository,
        ),
    ControleStartPrint,
);
