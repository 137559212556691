import {AuthenticationResult, AuthProvider, Failed, Success} from './auth_provider';
import {Edition} from '../../../models/edition';
import {SessionRepository} from '../../persistence/session/session_repository';
import {EditionRepository} from '../../persistence/edition/edition_repository';
import {Session} from '../../../models/session';

export default class SessionAuthProvider implements AuthProvider {
    private _editionRepository: EditionRepository;
    private _sessionRepository: SessionRepository;

    constructor(editionRepository: EditionRepository, sessionRepository: SessionRepository) {
        this._editionRepository = editionRepository;
        this._sessionRepository = sessionRepository;
    }

    public async edition(): Promise<Edition | null> {
        const session: Session | null = await this._sessionRepository.get();
        if (session === null) {
            return null;
        }
        return this._editionRepository.findByToken(session.editionToken);
    }

    public async login(token: string): Promise<AuthenticationResult> {
        const edition = await this._editionRepository.findByToken(token);

        if (edition === null) {
            return {
                error: true,
                reason: 'No edition for token',
            } as Failed;
        } else {
            const session: Session | null = await this._sessionRepository.get();
            if (session === null) {
                await this._sessionRepository.store({
                    editionToken: token,
                });
            } else {
                await this._sessionRepository.store({
                    ...session,
                    editionToken: token,
                });
            }
            return {
                error: false,
                edition: edition,
            } as Success;
        }
    }

    public async logout(): Promise<void> {
        return this._sessionRepository.clear();
    }
}
