import {Presenter} from '../../../../support/with_presenter';
import {ServerTimeProvider} from '../../../../../server_time/server_time_provider';
import {observable} from 'mobx';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {animationFrames} from '../../../../../client/support/observables/animation_frames';
import {formatTimeMsClientTime, formatTimeMsClientTimeLowPreciesion} from '../../../../support/format_time_ms';
import {CompositeSubscription} from '../../../../support/composit_subscription';

export class ClockPresenter implements Presenter {
    @observable
    public formattedTimeLowPrecision: string | null = null;

    @observable
    public formattedTimeHighPrecision: string | null = null;

    @observable
    public detailedTimeModalVisible: boolean = false;

    private subscriptions = new CompositeSubscription();

    constructor(private serverTimeProvider: ServerTimeProvider) {}

    public mount(): void {
        this.subscriptions.add(
            animationFrames()
                .pipe(
                    switchMap(() => this.serverTimeProvider.getTime()),
                    map(formatTimeMsClientTimeLowPreciesion),
                    distinctUntilChanged(),
                )
                .subscribe(formattedTime => (this.formattedTimeLowPrecision = formattedTime)),
        );

        this.subscriptions.add(
            animationFrames()
                .pipe(
                    filter(() => this.detailedTimeModalVisible), //Prevent events coming through while nothing is visible
                    switchMap(() => this.serverTimeProvider.getTime()),
                    map(time => formatTimeMsClientTime(time)),
                    distinctUntilChanged(),
                )
                .subscribe(formattedTime => (this.formattedTimeHighPrecision = formattedTime)),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public showDetailedTime() {
        this.detailedTimeModalVisible = true;
    }

    public onModalClose() {
        this.detailedTimeModalVisible = false;
    }
}
