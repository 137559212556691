import {first, switchMap} from 'rxjs/operators';
import {Presenter} from '../../../../support/with_presenter';
import {computed, observable} from 'mobx';
import {ParticipantRepository} from '../../../../persistence/participant/participant_repository';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Gender, Participant} from '../../../../../models/participant';
import {CompositeSubscription} from '../../../../support/composit_subscription';
import {Team} from '../../../../../models/team';
import {of} from 'rxjs';

export class ViewParticipantPresenter implements Presenter {
    @observable public name: string = '';
    @observable public birthYear: string = '';
    @observable public membershipCode: string = '';
    @observable public clubCode: string = '';
    @observable public gender: Gender = 'Unknown';
    @observable public participant: Participant | null | undefined = undefined;
    @observable public team: Team | null | undefined = undefined;
    @observable public submitting: boolean = false;

    @computed
    public get loading(): boolean {
        return this.participant === undefined || this.team === undefined;
    }

    private _participantId: string;
    private _participantRepository: ParticipantRepository;
    private _teamRepository: TeamRepository;
    private _subscriptions = new CompositeSubscription();

    constructor(participantId: string, teamRepository: TeamRepository, participantRepository: ParticipantRepository) {
        this._participantId = participantId;
        this._teamRepository = teamRepository;
        this._participantRepository = participantRepository;
    }

    public mount(): void {
        this._subscriptions.add(
            this._participantRepository
                .findById(this._participantId)
                .pipe(
                    first(),
                    switchMap((participant: Participant | null) =>
                        participant !== null ? this._teamRepository.findById(participant.teamId) : of(null),
                    ),
                )
                .subscribe(team => {
                    this.team = team;
                }),
        );
        this._subscriptions.add(
            this._participantRepository
                .findById(this._participantId)
                .pipe(first())
                .subscribe((participant: Participant | null) => {
                    this.participant = participant;

                    if (participant !== null) {
                        this.name = participant.name;
                        this.birthYear = '' + participant.birthYear;
                        this.membershipCode = participant.membershipCode;
                        this.clubCode = participant.clubCode;
                        this.gender = participant.gender;
                    }
                }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onBirthYearChange(birthYear: string) {
        this.birthYear = birthYear;
    }

    public onMembershipCodeChange(membershipCode: string) {
        this.membershipCode = membershipCode;
    }

    public onClubCodeChange(clubCode: string) {
        this.clubCode = clubCode;
    }

    public onGenderChange(gender: Gender) {
        this.gender = gender;
    }

    public async onSubmitClicked() {
        this.submitting = true;
        if (this.participant === null || this.participant === undefined) {
            throw new Error('Submitting updated participant while participant is null');
        }
        try {
            await this._participantRepository.update({
                ...this.participant,
                name: this.name,
                birthYear: parseInt(this.birthYear, 10),
                membershipCode: this.membershipCode,
                clubCode: this.clubCode,
                gender: this.gender,
            });
        } finally {
            this.submitting = false;
        }
    }
}
