import {combineLatest, Observable, of} from 'rxjs';
import {ResultGroup} from '../../../../business/results/models/resultGroup';
import {NarrowcastableResultGroupsProvider, ResultForResultRequest} from '../narrowcasting_presenter';
import {ResultRequestRepository} from '../../../../persistence/result_request/result_request_repository';
import {ResultProvider} from '../../../../business/results/result_provider';
import {map, switchMap} from 'rxjs/operators';
import {ResultRequest} from '../../../../../models/result_request';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Heat} from '../../../../../models/heat';

export class DefaultNarrowcastableResultGroupsProvider implements NarrowcastableResultGroupsProvider {
    constructor(
        private editionId: string,
        private resultRequestRepository: ResultRequestRepository,
        private resultProvider: ResultProvider,
        private heatRepository: HeatRepository,
    ) {}

    public resultsForResultRequests(): Observable<ResultForResultRequest[]> {
        return combineLatest(
            this.getResultRequestsForNarrowcasting(),
            this.heatRepository.findByEditionId(this.editionId),
        ).pipe(
            switchMap(([resultRequests, heats]: [ResultRequest[], Heat[]]) => {
                if (resultRequests.length === 0) {
                    return of([]);
                }
                return combineLatest(
                    ...resultRequests.map(resultRequest => this.resultForResultRequest(resultRequest, heats)),
                    (...resultRequestsResults) => {
                        return resultRequestsResults.reduce<ResultForResultRequest[]>((p, c) => {
                            return [...p, c];
                        }, []);
                    },
                );
            }),
        );
    }

    private getResultRequestsForNarrowcasting() {
        return this.resultRequestRepository
            .findByEditionId(this.editionId)
            .pipe(map(resultRequests => resultRequests.filter(resultRequest => resultRequest.includeInNarrowcasting)));
    }

    private resultForResultRequest(resultRequest: ResultRequest, heats: Heat[]): Observable<ResultForResultRequest> {
        return this.resultProvider.build(this.editionId, resultRequest).pipe(
            map(resultGroups => ({
                resultRequest: resultRequest,
                results: this.filterPublishedHeat(resultGroups, heats),
            })),
        );
    }

    private filterPublishedHeat(resultGroups: ResultGroup[], heats: Heat[]): ResultGroup[] {
        return resultGroups
            .map(resultGroup => ({
                ...resultGroup,
                results: resultGroup.results.filter(
                    result => result.team.heatId !== null && this.isPublished(result.team.heatId, heats),
                ),
            }))
            .filter(resultGroup => !this.isEmpty(resultGroup));
    }

    private isPublished(heatId: string, heats: Heat[]) {
        const heat = heats.find(h => h.id === heatId);

        return heat === undefined ? true : heat.published;
    }

    private isEmpty(resultGroup: ResultGroup): boolean {
        return resultGroup.results.length === 0;
    }
}
