import {observer} from 'mobx-react';
import * as React from 'react';
import {Card, CardContent, CardHeader, Grid, List, TextField, Typography} from '@material-ui/core';
import {TeamDroppable} from '../heat_editor/team_droppable';
import {TeamDraggable} from '../heat_editor/team_draggable';
import {CategoryItem} from './auto_assign_presenter';

interface CategoriesLaneProps {
    laneId: string;
    title: string;
    categories: CategoryItem[];
    showStartNumberInput: boolean;
    setStartNumber: (value: number) => void;
    startNumber: number;
    startNumberValid: boolean;
}

@observer
export class CategoriesLane extends React.Component<CategoriesLaneProps> {
    public render() {
        const props = this.props;
        return (
            <Grid item container xs={1} direction="column" style={{minWidth: 300}}>
                <Grid item>
                    <Typography variant={'h4'} noWrap>
                        {props.title}
                    </Typography>
                    <Typography variant={'body2'} noWrap>
                        {props.categories.map(x => x.teams.length).reduce((a, b) => a + b, 0)} teams
                    </Typography>
                    {props.showStartNumberInput ? (
                        <div>
                            <TextField
                                label={'Heat first start number'}
                                type={'number'}
                                value={props.startNumber}
                                error={!props.startNumberValid}
                                onChange={event => {
                                    const inputValue = parseInt(event.target.value, 10);
                                    props.setStartNumber(inputValue);
                                }}
                            />
                        </div>
                    ) : null}
                </Grid>
                <Grid item>
                    <TeamDroppable droppableId={props.laneId}>
                        <List>
                            {props.categories.map((item, index) => (
                                <TeamDraggable key={item.category} teamId={item.category} index={index}>
                                    <Card style={{marginBottom: 10}}>
                                        <CardContent>
                                            {item.category} ({item.teams.length} teams)
                                        </CardContent>
                                    </Card>
                                </TeamDraggable>
                            ))}
                        </List>
                    </TeamDroppable>
                </Grid>
            </Grid>
        );
    }
}
