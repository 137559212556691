import {Presenter} from '../../../../support/with_presenter';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Heat} from '../../../../../models/heat';
import {observable} from 'mobx';
import {Team} from '../../../../../models/team';
import {CompositeSubscription} from '../../../../support/composit_subscription';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {HeatInteractor} from '../../../../business/heat/heat_interactor';

export class ViewHeatScreenPresenter implements Presenter {
    @observable public loading = true;
    @observable public error: string | null = null;
    @observable public actionsEnabled = true;
    @observable public heat: Heat | null = null;
    @observable public teams: Team[] | null = null;
    @observable public trackTimesModalVisible: boolean = false;
    private _heatId: string;
    private _heatRepository: HeatRepository;
    private _subscriptions = new CompositeSubscription();
    private _teamRepository: TeamRepository;
    private _heatInteractor: HeatInteractor;

    constructor(
        heatId: string,
        heatRepository: HeatRepository,
        teamRepository: TeamRepository,
        heatInteractor: HeatInteractor,
    ) {
        this._heatId = heatId;
        this._heatRepository = heatRepository;
        this._teamRepository = teamRepository;
        this._heatInteractor = heatInteractor;
    }

    public mount(): void {
        this._subscriptions.add(
            this._heatRepository.findById(this._heatId).subscribe((heat: Heat | null) => {
                this.loading = false;
                this.heat = heat;
            }),
        );
        this._subscriptions.add(
            this._teamRepository.findByHeatId(this._heatId).subscribe((teams: Team[]) => {
                this.teams = teams;
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    public async onStartHeatTimerClick() {
        if (this.heat === null) {
            throw new Error('Invalid state, trying to start heat without a heat');
        }
        this.actionsEnabled = false;
        try {
            this.heat = await this._heatInteractor.start(this.heat);
        } catch (e) {
            this.error = 'Error while saving heat';
        } finally {
            this.actionsEnabled = true;
        }
    }

    public async onStopHeatTimerClick() {
        if (this.heat === null) {
            throw new Error('Invalid state, trying to stop heat without a heat');
        }
        this.actionsEnabled = false;
        try {
            this.heat = await this._heatInteractor.stop(this.heat);
        } catch (e) {
            this.error = 'Error while saving heat';
        } finally {
            this.actionsEnabled = true;
        }
    }

    public async onResumeHeatTimerClick() {
        if (this.heat === null) {
            throw new Error('Invalid state, trying to resume heat without a heat');
        }
        this.actionsEnabled = false;
        try {
            this.heat = await this._heatInteractor.resume(this.heat);
        } catch (e) {
            this.error = 'Error while saving heat';
        } finally {
            this.actionsEnabled = true;
        }
    }

    public onTrackTimesClick() {
        this.trackTimesModalVisible = true;
    }

    public onTrackTimesModalClose() {
        this.trackTimesModalVisible = false;
    }
}
