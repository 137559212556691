import * as React from 'react';
import {CSSProperties} from 'react';
import {ResultGroup} from '../../../../business/results/models/resultGroup';
import {Edition} from '../../../../../models/edition';
import PrintPage from '../../../components/print_page';
import {Result} from '../../../../business/results/models/result';
import {Participant} from '../../../../../models/participant';
import {ResultRequest} from '../../../../../models/result_request';
import {ResultMode} from '../../../../business/results/result_mode';

interface OwnProps {
    edition: Edition;
    resultRequest: ResultRequest;
    results: ResultGroup[];
}

export class ResultPrint extends React.Component<OwnProps> {
    private getRower(result: Result, uuid: string | null): Participant | null {
        if (uuid === null) {
            return null;
        }
        return result.participants.find(participant => participant.id === uuid) || null;
    }

    private getParticipants(result: Result) {
        const rower1 = this.getRower(result, result.team.rower1Id);
        const rower2 = this.getRower(result, result.team.rower2Id);
        const rower3 = this.getRower(result, result.team.rower3Id);
        const rower4 = this.getRower(result, result.team.rower4Id);
        const rower5 = this.getRower(result, result.team.rower5Id);
        const rower6 = this.getRower(result, result.team.rower6Id);
        const rower7 = this.getRower(result, result.team.rower7Id);
        const rower8 = this.getRower(result, result.team.rower8Id);

        const rowers: Participant[] = [rower1, rower2, rower3, rower4, rower5, rower6, rower7, rower8].filter(
            (rower): rower is Participant => rower !== null,
        );

        if (rowers.length === 1) {
            return (
                <table>
                    <td>{rowers[0].name}</td>
                </table>
            );
        }

        return (
            <table>
                <tbody>
                    {rowers.map((rower, index) => {
                        let position = '' + (index + 1);
                        if (index === 0) {
                            position = 'b';
                        }
                        if (index === rowers.length - 1) {
                            position = 's';
                        }
                        return (
                            <tr key={index}>
                                <td>({position})</td>
                                <td>{rower.name}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    private renderResult(result: ResultGroup) {
        const thStyle: CSSProperties = {
            padding: 15,
            borderBottom: '1px solid black',
            margin: 0,
        };
        const tdStyle: CSSProperties = {
            margin: 0,
            textAlign: 'center',
            verticalAlign: 'top',
            borderBottom: '1px solid black',
        };

        return (
            <PrintPage>
                <div style={{width: '100%', borderBottom: '1px solid black'}}>{this.props.edition.name}</div>
                <div
                    style={{
                        width: '100%',
                        margin: '15px 0',
                        textAlign: 'center',
                        fontSize: 36,
                        borderBottom: '1px solid black',
                    }}>
                    {result.name}
                </div>
                <table style={{width: '100%'}} cellSpacing={0} cellPadding={5}>
                    <thead>
                        <tr>
                            {this.props.resultRequest.mode !== ResultMode.Rugnummer ? (
                                <th style={thStyle}>Positie</th>
                            ) : null}
                            <th style={thStyle}>Rug</th>
                            <th style={{...thStyle, textAlign: 'left'}}>Team</th>
                            <th style={thStyle}>Club</th>
                            <th style={thStyle}>{result.timeTitle}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.results.map(item => {
                            return (
                                <tr key={item.team.id}>
                                    {this.props.resultRequest.mode !== ResultMode.Rugnummer ? (
                                        <td style={tdStyle}>{item.position}</td>
                                    ) : null}
                                    <td style={tdStyle}>{item.team.number}</td>
                                    <td style={{...tdStyle, textAlign: 'left'}}>{this.getParticipants(item)}</td>
                                    <td style={tdStyle}>{item.team.clubCode}</td>
                                    <td style={tdStyle}>
                                        {item.displayTime}
                                        {!item.team.isInCompetition ? (
                                            <>
                                                <br />
                                                {' (buiten mededinging)'}
                                            </>
                                        ) : null}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </PrintPage>
        );
    }

    public render() {
        return this.props.results.map(result => this.renderResult(result));
    }
}
