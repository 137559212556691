import * as React from 'react';
import {ViewHeatScreenPresenter} from './heats_view_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import Content from '../../components/content';
import ContentTitle from '../../components/content_title';
import Header from '../../components/header';
import Grid from '@material-ui/core/Grid';
import {observer} from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import HeatTimer from '../../components/heat_timer/heat_timer';
import TeamTime from '../../components/team_time/team_time';
import Loading from '../../../components/loading';
import {Team} from '../../../../../models/team';
import {TrackTimesModal} from './components/track_times_modal';
import {ViewIcon} from '../../../components/icons/view_icon';

interface OwnProps {
    heatId: string;
}

@observer
class ViewHeatScreen extends React.Component<OwnProps & PresenterProps<ViewHeatScreenPresenter>> {
    private renderActions() {
        const actions = [];
        if (this.props.presenter.heat !== null) {
            actions.push(
                <Button
                    key="edit"
                    component={props => <Link to={`/edition/heat/${this.props.heatId}/edit`} {...props as any} />}>
                    Edit
                </Button>,
            );
            if (this.props.presenter.heat.startTimeMs === null) {
                actions.push(
                    <Button
                        key="start-timer"
                        color="primary"
                        variant="contained"
                        onClick={() => this.props.presenter.onStartHeatTimerClick()}
                        disabled={!this.props.presenter.actionsEnabled}>
                        Start heat timer
                    </Button>,
                );
            } else if (this.props.presenter.heat.endTimeMs === null) {
                actions.push(
                    <Button
                        key="stop-timer"
                        color="secondary"
                        variant="contained"
                        onClick={() => this.props.presenter.onStopHeatTimerClick()}
                        disabled={!this.props.presenter.actionsEnabled}>
                        Stop heat timer
                    </Button>,
                );
            } else {
                actions.push(
                    <Button
                        key="resume-timer"
                        onClick={() => this.props.presenter.onResumeHeatTimerClick()}
                        disabled={!this.props.presenter.actionsEnabled}>
                        Resume heat timer
                    </Button>,
                );
            }

            if (this.props.presenter.heat.startTimeMs !== null && this.props.presenter.heat.endTimeMs === null) {
                actions.push(
                    <Button
                        key="track"
                        component={props => <a onClick={() => this.props.presenter.onTrackTimesClick()} {...props} />}>
                        Track times
                    </Button>,
                );
            }

            if (this.props.presenter.heat.startTimeMs !== null) {
                actions.push(
                    <Button
                        key="manage"
                        component={props => (
                            <Link to={`/edition/heat/${this.props.heatId}/recorded-times`} {...props as any} />
                        )}>
                        Manage recorded times
                    </Button>,
                );
            }
        }

        return (
            <Content>
                <ContentTitle>Actions</ContentTitle>
                {actions}
                {this.props.presenter.heat !== null ? (
                    <TrackTimesModal
                        heat={this.props.presenter.heat}
                        open={this.props.presenter.trackTimesModalVisible}
                        onClose={() => this.props.presenter.onTrackTimesModalClose()}
                    />
                ) : null}
            </Content>
        );
    }

    private renderPrints() {
        return (
            <Content>
                <ContentTitle>Prints</ContentTitle>
                <Button
                    key="prints-controle-start"
                    component={props => (
                        <a target="_blank" href={`/edition/prints/controle-start/${this.props.heatId}`} {...props} />
                    )}>
                    Controle start-tijden
                </Button>
                <Button
                    key="prints-controle-finish"
                    component={props => (
                        <a target="_blank" href={`/edition/prints/controle-finish/${this.props.heatId}`} {...props} />
                    )}>
                    Controle finish-tijden
                </Button>
            </Content>
        );
    }

    private renderTeams() {
        if (this.props.presenter.teams === null) {
            return (
                <Content>
                    <ContentTitle>Teams</ContentTitle>
                    <Loading />
                </Content>
            );
        }
        return (
            <Content>
                <ContentTitle>Teams</ContentTitle>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Start time</TableCell>
                            <TableCell>Finish time</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.presenter.teams.sort(this.sortTeams).map(team => {
                            return (
                                <TableRow key={team.id}>
                                    <TableCell>{team.number}</TableCell>
                                    <TableCell>{team.name}</TableCell>
                                    <TableCell>
                                        <TeamTime start teamId={team.id} />
                                    </TableCell>
                                    <TableCell>
                                        <TeamTime finish teamId={team.id} />
                                    </TableCell>
                                    <TableCell>
                                        <TeamTime teamId={team.id} />
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/edition/team/${team.id}`}>
                                            <ViewIcon />
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Content>
        );
    }

    private sortTeams(a: Team, b: Team) {
        if (a.number !== null && b.number !== null) {
            return a.number > b.number ? 1 : -1;
        }
        if (a.number !== null) {
            return -1;
        }
        if (b.number !== null) {
            return 1;
        }

        return a.name > b.name ? 1 : -1;
    }

    public render() {
        if (this.props.presenter.loading) {
            return <Loading />;
        }
        if (this.props.presenter.heat === null) {
            //TODO add fancy 404
            return <div>Could not find heat</div>;
        }
        return (
            <Grid item xs={12}>
                <Header
                    breadcrumbs={[
                        {
                            name: 'Heats',
                            link: '/edition/heats',
                        },
                        {
                            name: this.props.presenter.heat.name,
                            link: '/edition/heat/' + this.props.presenter.heat.id,
                        },
                    ]}>
                    {this.props.presenter.heat.name}{' '}
                    {this.props.presenter.heat.number === undefined
                        ? ' (NO NUMBER)'
                        : '(#' + this.props.presenter.heat.number + ')'}
                    <div style={{float: 'right'}}>
                        <HeatTimer heatId={this.props.heatId} />
                    </div>
                </Header>
                {this.renderActions()}
                {this.renderPrints()}
                {this.renderTeams()}
            </Grid>
        );
    }
}

export default withPresenter<ViewHeatScreenPresenter, OwnProps>(
    (props, component) =>
        new ViewHeatScreenPresenter(
            props.heatId,
            component.persistence.heatRepository,
            component.persistence.teamRepository,
            component.business.heatInteractor,
        ),
    ViewHeatScreen,
);
