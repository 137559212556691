import {map} from 'rxjs/operators';
import {ServerTime, ServerTimeInteractor} from './server_time_interactor';
import {Observable} from 'rxjs';
import {TimeProvider} from './time_provider';

export interface ServerTimeProvider {
    getTime(): Observable<number>;
}

export class ServerTimeProviderImpl {
    private _serverTimeInteractor: ServerTimeInteractor;
    private _timeProvider: TimeProvider;

    constructor(serverTimeInteractor: ServerTimeInteractor, timeProvider: TimeProvider) {
        this._serverTimeInteractor = serverTimeInteractor;
        this._timeProvider = timeProvider;
    }

    public getTime(): Observable<number> {
        return this._serverTimeInteractor.serverTimes().pipe(
            map((serverTime: ServerTime) => {
                return this._timeProvider.getTime() - serverTime.offsetMillis;
            }),
        );
    }
}
