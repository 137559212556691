import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {AddHeatPresenter} from './heats_add_presenter';
import Header from '../../components/header';
import Grid from '@material-ui/core/Grid';
import Content from '../../components/content';
import {observer} from 'mobx-react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Button from '@material-ui/core/Button';
import {Edition} from '../../../../../models/edition';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';

interface OwnProps {
    edition: Edition;
}

@observer
class AddHeatScreen extends React.Component<OwnProps & PresenterProps<AddHeatPresenter>> {
    public render() {
        return (
            <Grid item xs={12}>
                <Header>Add heat</Header>
                <Content>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                            id="name"
                            value={this.props.presenter.name}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="number">Number</InputLabel>
                        <Input
                            id="number"
                            value={this.props.presenter.number}
                            type="number"
                            onChange={(e: any) => this.props.presenter.onNumberChange(e.target.value)}
                        />
                    </FormControl>
                    <Grid justify="flex-end" container>
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={this.props.presenter.loading}
                                style={{marginTop: 16}}
                                onClick={() => this.props.presenter.onSubmitClicked()}>
                                {this.props.presenter.loading ? 'Creating..' : 'Create'}
                            </Button>
                        </Grid>
                    </Grid>
                </Content>
            </Grid>
        );
    }
}

export default withPresenter<AddHeatPresenter, OwnProps>(
    (props, component) => new AddHeatPresenter(props.edition, component.persistence.heatRepository),
    AddHeatScreen,
);
