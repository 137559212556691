import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

interface OwnProps {}

export default class Loading extends React.Component<OwnProps> {
    public render() {
        return <CircularProgress color="secondary" style={{margin: '25px auto'}} />;
    }
}
