import {Category} from '../../models/team';
import {Participant} from '../../models/participant';
import getBoatTypeHandicap from './corrected_time_calculator/boat_type_handicap';
import {HandicapTable} from './corrected_time_calculator/handicap_table';

export class CorrectedTimeCalculator {
    private readonly _currentYear: number;
    private _handicaps: HandicapTable;

    constructor(currentYear: number, handicaps: HandicapTable) {
        this._currentYear = currentYear;
        this._handicaps = handicaps;
    }

    public calculate(absoluteTime: number, category: Category, rowers: Participant[]): number {
        if (rowers.length === 0) {
            throw new Error('Trying to calculate corrected time without any rowers');
        }
        if (rowers.some(value => value.gender === 'Unknown')) {
            // Refuse to calculate corrected time if at least one of the participants has unknown gender
            return 0;
        }
        const rowerHandicaps = rowers.map(rower =>
            this._handicaps.getRowerHandicap(this._currentYear - rower.birthYear, rower.gender),
        );
        const rowersHandicap = rowerHandicaps.reduce((p, c) => p + c, 0) / rowerHandicaps.length;
        const boatTypeHandicap = getBoatTypeHandicap(category);

        return absoluteTime * boatTypeHandicap * rowersHandicap;
    }
}
