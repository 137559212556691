import {first} from 'rxjs/operators';
import {Presenter} from '../../../../support/with_presenter';
import {RecordedTimePair, RecordedTimesInteractor} from '../../../../business/recorded_times/recorded_times_interactor';
import {Subscription} from 'rxjs';
import {observable} from 'mobx';
import {ServerTimeProvider} from '../../../../../server_time/server_time_provider';
import {formatTimeMsClientTime, formatTimeMsUTC, formatTimeMsUTCLowPrecision} from '../../../../support/format_time_ms';

export class TeamTimePresenter implements Presenter {
    @observable public formattedTime: string = '-:--';
    private _teamId: string;
    private _showStartTimeOnly: boolean | undefined;
    private _showFinishTimeOnly: boolean | undefined;
    private _recordedTimesInteractor: RecordedTimesInteractor;
    private _subscription: Subscription | undefined;
    private _recordedTimePair: RecordedTimePair | null = null;
    private _mounted: boolean = false;
    private _serverTimeProvider: ServerTimeProvider;

    constructor(
        teamId: string,
        showStartTimeOnly: boolean | undefined,
        showFinishTimeOnly: boolean | undefined,
        recordedTimesInteractor: RecordedTimesInteractor,
        serverTimeProvider: ServerTimeProvider,
    ) {
        this._teamId = teamId;
        this._showStartTimeOnly = showStartTimeOnly;
        this._showFinishTimeOnly = showFinishTimeOnly;
        this._recordedTimesInteractor = recordedTimesInteractor;
        this._serverTimeProvider = serverTimeProvider;
    }

    public mount(): void {
        this._subscription = this._recordedTimesInteractor.forTeamId(this._teamId).subscribe(recordedTimePair => {
            this._recordedTimePair = recordedTimePair;
        });

        this._mounted = true;
        this.start();
    }

    public unmount(): void {
        this._mounted = false;

        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    private start() {
        if (this._mounted) {
            window.requestAnimationFrame(async () => {
                if (this._recordedTimePair === null) {
                    this.formattedTime = formatTimeMsUTC(null);
                } else {
                    if (this._showStartTimeOnly === true) {
                        this.formattedTime = formatTimeMsClientTime(
                            this._recordedTimePair.start ? this._recordedTimePair.start.timeMs : null,
                        );
                    } else if (this._showFinishTimeOnly === true) {
                        this.formattedTime = formatTimeMsClientTime(
                            this._recordedTimePair.finish ? this._recordedTimePair.finish.timeMs : null,
                        );
                    } else {
                        if (this._recordedTimePair.start !== null && this._recordedTimePair.finish !== null) {
                            this.formattedTime = formatTimeMsUTC(
                                this._recordedTimePair.finish.timeMs - this._recordedTimePair.start.timeMs,
                            );
                        } else if (this._recordedTimePair.start !== null && this._recordedTimePair.finish === null) {
                            const serverTime = await this._serverTimeProvider
                                .getTime()
                                .pipe(first())
                                .toPromise();
                            this.formattedTime = formatTimeMsUTCLowPrecision(
                                serverTime - this._recordedTimePair.start.timeMs,
                            );
                        } else {
                            this.formattedTime = formatTimeMsUTC(null);
                        }
                    }
                }
                this.start();
            });
        }
    }
}
