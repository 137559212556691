import {Presenter} from '../../../../support/with_presenter';
import {computed, observable} from 'mobx';
import {RecordedTimesInteractor} from '../../../../business/recorded_times/recorded_times_interactor';
import {RecordedTime} from '../../../../../models/recorded_time';

export class ManageRecordedTimePresenter implements Presenter {
    @observable public saving = false;
    @observable public editDialogVisible = false;
    @observable public selectedTeamId: string = '';

    @computed
    public get validates() {
        return this.selectedTeamId !== '';
    }

    private _recordedTime: RecordedTime;
    private _recordedTimesInteractor: RecordedTimesInteractor;

    constructor(recordedTime: RecordedTime, recordedTimesInteractor: RecordedTimesInteractor) {
        this._recordedTime = recordedTime;
        this._recordedTimesInteractor = recordedTimesInteractor;
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onEditClick() {
        this.editDialogVisible = true;
    }

    public closeDialog() {
        this.editDialogVisible = false;
    }

    public async onSetStartClicked() {
        this.saving = true;
        try {
            await this._recordedTimesInteractor.updateStartTime(this._recordedTime, this.selectedTeamId);
        } finally {
            this.editDialogVisible = false;
            this.saving = false;
        }
    }

    public async onSetFinishClicked() {
        this.saving = true;
        try {
            await this._recordedTimesInteractor.updateFinishTime(this._recordedTime, this.selectedTeamId);
        } finally {
            this.editDialogVisible = false;
            this.saving = false;
        }
    }

    public onSelectedTeamChange(id: string) {
        this.selectedTeamId = id;
    }
}
