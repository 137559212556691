import {EditionInteractor} from './edition/edition_interactor';
import {PersistenceComponent} from '../persistence/persistence_component';
import {HeatInteractor} from './heat/heat_interactor';
import {RecordedTimesInteractor} from './recorded_times/recorded_times_interactor';
import {SingleServerTimeInteractor} from '../../server_time/single_server_time_interactor';
import {BurstServerTimeInteractor} from '../../server_time/burst_server_time_interactor';
import {BestServerTimeInteractor} from '../../server_time/best_server_time_interactor';
import {WeightedServerTimeInteractor} from '../../server_time/weighted_server_time_interactor';
import {SharedServerTimeInteractor} from '../../server_time/shared_server_time_interactor';
import {ServerTimeInteractor} from '../../server_time/server_time_interactor';
import {ServerTimeProvider, ServerTimeProviderImpl} from '../../server_time/server_time_provider';
import {TimeProvider, TimeProviderImpl} from '../../server_time/time_provider';
import {DefaultResultProvider, ResultProvider} from './results/result_provider';
import {DefaultResultBuilder} from './results/result_builder';
import {DefaultYearProvider} from '../support/year_provider';
import {lazy} from '../../client/support/lazy';

export interface BusinessComponent {
    editionInteractor: EditionInteractor;
    heatInteractor: HeatInteractor;
    recordedTimeInteractor: RecordedTimesInteractor;
    serverTimeProvider: ServerTimeProvider;
    resultProvider: ResultProvider;
}

export class DefaultBusinessComponent implements BusinessComponent {
    constructor(private persistenceComponent: PersistenceComponent) {}

    @lazy()
    get editionInteractor(): EditionInteractor {
        return new EditionInteractor(
            this.persistenceComponent.editionRepository,
            this.persistenceComponent.heatRepository,
        );
    }

    @lazy()
    get heatInteractor(): HeatInteractor {
        return new HeatInteractor(this.persistenceComponent.heatRepository, this.serverTimeProvider);
    }

    @lazy()
    get recordedTimeInteractor(): RecordedTimesInteractor {
        return new RecordedTimesInteractor(
            this.persistenceComponent.teamRepository,
            this.persistenceComponent.recordedTimeRepository,
            this.serverTimeProvider,
        );
    }

    @lazy()
    get serverTimeProvider(): ServerTimeProvider {
        return new ServerTimeProviderImpl(this.serverTimeInteractor, this.timeProvider);
    }

    @lazy()
    get resultProvider(): ResultProvider {
        return new DefaultResultProvider(
            new DefaultResultBuilder(this.persistenceComponent.heatRepository, new DefaultYearProvider()),
            this.persistenceComponent.teamRepository,
            this.persistenceComponent.heatRepository,
            this.persistenceComponent.recordedTimeRepository,
            this.persistenceComponent.participantRepository,
        );
    }

    @lazy()
    private get serverTimeInteractor(): ServerTimeInteractor {
        return new SharedServerTimeInteractor(
            new WeightedServerTimeInteractor(
                new BestServerTimeInteractor(
                    new BurstServerTimeInteractor(new SingleServerTimeInteractor(), 5, 2, 30000),
                ),
            ),
        );
    }

    @lazy()
    private get timeProvider(): TimeProvider {
        return new TimeProviderImpl();
    }
}
