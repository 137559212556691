import * as React from 'react';
import {FunctionComponent} from 'react';
import {Content} from '../../components/content';
import Hidden from '@material-ui/core/Hidden/Hidden';
import {ContentTitle} from '../../components/content_title';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {TeamControls} from '../teams/team_controls';
import {LooseBar} from './loose_bar';
import {RecordedAs} from '../../../../models/recorded_time';
import {HeatWithEdition} from '../../../tracking/tracking_controller';
import {Team} from '../../../../models/team';
import {RegattaMode} from '../../../../models/edition';
import {Link} from 'react-router-dom';

interface OwnProps {
    recordedAs: RecordedAs;
    heat: HeatWithEdition;
    teams: Team[];
}

export const TimesPerTeam: FunctionComponent<OwnProps> = ({recordedAs, teams, heat}) => {
    return (
        <Content>
            <Hidden xsDown>
                <ContentTitle>{heat.name}</ContentTitle>
            </Hidden>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teams.map(team => {
                        return (
                            <TableRow key={team.id}>
                                <TableCell>{team.number}</TableCell>
                                <TableCell>{team.clubCode}</TableCell>
                                <TableCell style={{height: 50}}>
                                    <TeamControls team={team} recordedAs={recordedAs} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            {heat.edition.mode === RegattaMode.Sprint && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 15,
                    }}>
                    <Link
                        to={'/tracking/edition/' + heat.editionId}
                        style={{
                            padding: 10,
                            textDecoration: 'none',
                            color: '#000',
                            textTransform: 'uppercase',
                            fontSize: '0.9375rem',
                            marginTop: 25,
                        }}>
                        Switch heat
                    </Link>
                </div>
            )}
            <LooseBar heat={heat} />
        </Content>
    );
};
