import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {Theme, WithStyles} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

interface OwnProps {}

const styles = (theme: Theme) => ({
    root: {
        padding: 24,
        margin: 12,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: 12,
        },
    },
});

class ContentComponent extends React.Component<OwnProps & WithStyles<'root'>> {
    public render() {
        return (
            <>
                <Paper className={this.props.classes.root}>{this.props.children}</Paper>

                {/* Push content up to make room for the FAB button */}
                <div style={{height: 75, width: '100%', display: 'block'}} />
            </>
        );
    }
}

export const Content = withStyles(styles)(ContentComponent);
