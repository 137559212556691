import * as Papa from 'papaparse';
import {Presenter} from '../../../support/with_presenter';
import {observable} from 'mobx';
import {HeatRepository} from '../../../persistence/heat/heat_repository';
import {TeamRepository} from '../../../persistence/team/team_repository';
import {ParticipantRepository} from '../../../persistence/participant/participant_repository';
import {Edition} from '../../../../models/edition';
import {CsvImportParser, ParticipantData} from '../../../support/importing';
import {Participant} from '../../../../models/participant';
import {Category, categoryToGenderClassMapping, Team} from '../../../../models/team';

export class ImportScreenPresenter implements Presenter {
    @observable public file: File | null = null;
    @observable public loading = false;
    @observable public progress: number = 0;
    private _heatRepository: HeatRepository;
    private _teamRepository: TeamRepository;
    private _participantRepository: ParticipantRepository;
    private _edition: Edition;
    private _participantCache: Map<string, Participant>;

    constructor(
        edition: Edition,
        heatRepository: HeatRepository,
        teamRepository: TeamRepository,
        participantRepository: ParticipantRepository,
    ) {
        this._edition = edition;
        this._heatRepository = heatRepository;
        this._teamRepository = teamRepository;
        this._participantRepository = participantRepository;
        this._participantCache = new Map();
    }

    public mount(): void {
        /* Noop */
    }

    public unmount(): void {
        /* Noop */
    }

    public onFileChange(files: FileList) {
        this.file = files.item(0);
    }

    private async createParticipant(team: Team, participantData: ParticipantData): Promise<Participant> {
        const fromCache = this._participantCache.get(participantData.membershipCode);
        if (participantData.membershipCode.length > 0 && fromCache !== undefined) {
            return new Promise<Participant>((resolve, reject) => resolve(fromCache));
        } else {
            const result = this._participantRepository.create(
                this._edition.id,
                team.id,
                participantData.name,
                participantData.birthYear,
                participantData.membershipCode,
                participantData.clubCode,
                participantData.gender,
            );
            return result.then(value => {
                this._participantCache.set(participantData.membershipCode, value);
                return value;
            });
            return result;
        }
    }

    public async onSubmitClicked() {
        this.loading = true;
        try {
            const parsedData = await this.read();
            const parser = new CsvImportParser();
            const data = parser.process(parsedData);
            this.progress = 0;
            let numDone = 0;
            for (const teamData of data) {
                const team = await this._teamRepository.create(
                    this._edition.id,
                    '',
                    teamData.name,
                    teamData.boat,
                    teamData.category,
                    [{name: teamData.category}, ...this.categoryToTags(teamData.category)], //By default a team also gets the category as a tag
                    teamData.clubCode,
                    teamData.notes === undefined ? '' : teamData.notes,
                );
                const participants: Participant[] = await Promise.all(
                    teamData.participants.map(participant => {
                        return this.createParticipant(team, participant);
                    }),
                );
                if (participants.length > 0) {
                    team.rower1Id = participants[0].id;
                }
                if (participants.length > 1) {
                    team.rower2Id = participants[1].id;
                }
                if (participants.length > 2) {
                    team.rower3Id = participants[2].id;
                }
                if (participants.length > 3) {
                    team.rower4Id = participants[3].id;
                }
                if (participants.length > 4) {
                    team.rower5Id = participants[4].id;
                }
                if (participants.length > 5) {
                    team.rower6Id = participants[5].id;
                }
                if (participants.length > 6) {
                    team.rower7Id = participants[6].id;
                }
                if (participants.length > 7) {
                    team.rower8Id = participants[7].id;
                }
                if (teamData.coxswain) {
                    const coxswain: Participant = await this.createParticipant(team, teamData.coxswain);
                    team.coxswainId = coxswain.id;
                }
                await this._teamRepository.update(team);
                numDone++;
                this.progress = (100 * numDone) / data.length;
            }
        } catch (e) {
            //TODO show error
        } finally {
            this.loading = false;
        }
    }

    private read() {
        return new Promise((resolve, reject) => {
            if (this.file === null) {
                reject();
                return;
            }
            Papa.parse(this.file, {
                header: true,
                encoding: 'utf-8',
                skipEmptyLines: true,
                complete: function(results) {
                    resolve(results.data);
                },
            });
        });
    }

    private categoryToTags(category: Category): Array<{name: string}> {
        return categoryToGenderClassMapping[category].map(genderClass => ({
            name: genderClass,
        }));
    }
}
