import * as React from 'react';
import {Team} from '../../../../../models/team';
import {observer} from 'mobx-react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {TeamManagePresenter} from './team_manage_presenter';
import {Heat} from '../../../../../models/heat';
import {MenuItem, Select, TableCell, TableRow} from '@material-ui/core';
import {decodeClubCode} from '../../../../support/club_code_decoder';
import {Edition} from '../../../../../models/edition';
import TextField from '@material-ui/core/TextField';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import {TagsInputWrapper} from '../../components/tags_input_wrapper';
import {Participant} from '../../../../../models/participant';
import Table from '@material-ui/core/Table';

interface OwnProps {
    edition: Edition;
    team: Team;
    firstParticipant: Participant | undefined;
    localHeat: Heat | null;
    localNumber: number | null;
    localTags: string[];
    remarks: string[];
    isFirstInHeat: boolean;
    isLastInHeat: boolean;
    setHeat: (heatId: string | null) => void;
    setNumber: (number: number) => void;
    setTags: (tags: string[]) => void;
    moveUp: () => void;
    moveDown: () => void;
}

@observer
class TeamManage extends React.Component<OwnProps & PresenterProps<TeamManagePresenter>> {
    private renderRemarks() {
        return this.props.remarks.map((remark, index) => {
            return (
                <TableRow key={index}>
                    <TableCell colSpan={6}>{remark}</TableCell>
                </TableRow>
            );
        });
    }

    private renderTeamData() {
        return [
            <TableRow key="team-data" style={{background: 'white'}}>
                <TableCell padding="dense">
                    <IconButton disabled={this.props.isFirstInHeat} onClick={this.props.moveUp}>
                        <ArrowDropUp />
                    </IconButton>
                    <IconButton disabled={this.props.isLastInHeat} onClick={this.props.moveDown}>
                        <ArrowDropDown />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Select
                        value={this.props.localHeat ? this.props.localHeat.id : undefined}
                        onChange={e => this.props.setHeat(e.target.value)}>
                        {this.props.presenter.heats.map((heat: Heat) => {
                            return (
                                <MenuItem key={heat.id} value={heat.id}>
                                    {heat.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </TableCell>
                <TableCell>
                    <TextField
                        type="number"
                        value={this.props.localNumber ? this.props.localNumber : undefined}
                        onChange={e => this.props.setNumber(parseInt(e.target.value, 10))}
                    />
                </TableCell>
                <TableCell>{decodeClubCode(this.props.presenter.team.clubCode)}</TableCell>
                <TableCell>{this.props.presenter.team.category}</TableCell>
                <TableCell>
                    <Table>
                        <TableRow>
                            <TableCell padding="dense">Team:</TableCell>
                            <TableCell padding="dense">{this.props.presenter.team.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="dense">Boat:</TableCell>
                            <TableCell padding="dense">{this.props.presenter.team.boat}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="dense">Rower:</TableCell>
                            <TableCell padding="dense">
                                {this.props.firstParticipant !== undefined ? this.props.firstParticipant.name : '---'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} padding="dense">
                                <Input
                                    value={this.props.localTags}
                                    onChange={tags => this.props.setTags(tags as any)}
                                    inputComponent={TagsInputWrapper as any}
                                />
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableCell>
            </TableRow>,
        ];
    }

    public render() {
        return [...this.renderTeamData(), ...this.renderRemarks()];
    }
}

export default withPresenter<TeamManagePresenter, OwnProps>(
    (props, component) =>
        new TeamManagePresenter(
            props.edition,
            props.team,
            component.persistence.teamRepository,
            component.persistence.heatRepository,
        ),
    TeamManage,
);
