import {Presenter} from '../../support/with_presenter';
import {AuthProvider} from '../../business/auth/auth_provider';
import {observable} from 'mobx';
import {Edition} from '../../../models/edition';

export class RequiresAuthenticationPresenter implements Presenter {
    @observable public loading: boolean = true;
    @observable public edition: Edition | null = null;
    private _authProvider: AuthProvider;

    constructor(authProvider: AuthProvider) {
        this._authProvider = authProvider;
    }

    public async mount(): Promise<void> {
        this.edition = await this._authProvider.edition();
        this.loading = false;
        if (this.edition === null && window.location.pathname !== '/login') {
            window.location.href = '/login';
        }
    }

    public unmount(): void {
        /* Noop */
    }
}
