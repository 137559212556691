import * as React from 'react';
import Routes from './routes';
import './index.css';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme/theme';
import TWEEN from '@tweenjs/tween.js';

function animate(time: number) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
}
requestAnimationFrame(animate);

const App = () => (
    <MuiThemeProvider theme={theme}>
        <Routes />
    </MuiThemeProvider>
);

export default App;
