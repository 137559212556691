import {Team} from '../../../../../models/team';
import {Tag} from '../../../../../models/tag';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Grid, List, TextField, Typography} from '@material-ui/core';
import {TeamDroppable} from './team_droppable';
import {TeamDraggable} from './team_draggable';
import {TeamBadge} from '../team_badge/team_badge';
import {Participant} from '../../../../../models/participant';
import {getStrokePosition} from '../../../../support/importing/boat_type_map';

interface LaneProps {
    laneId: string;
    title: string;
    teams: Team[];
    participants: Participant[];
    setTags: (team: Team, tags: Tag[]) => void;
    showStartNumberInput: boolean;
    setStartNumber: (value: number) => void;
    startNumber: number;
    startNumberValid: boolean;
}

@observer
export class Lane extends React.Component<LaneProps> {
    public render() {
        const props = this.props;

        const participants = this.props.participants;

        function getStroke(team: Team): Participant | undefined {
            const position = getStrokePosition(team.category);
            let strokeParticipantId: string | null = null;
            switch (position) {
                case 1:
                    strokeParticipantId = team.rower1Id;
                    break;
                case 2:
                    strokeParticipantId = team.rower2Id;
                    break;
                case 3:
                    strokeParticipantId = team.rower3Id;
                    break;
                case 4:
                    strokeParticipantId = team.rower4Id;
                    break;
                case 5:
                    strokeParticipantId = team.rower5Id;
                    break;
                case 6:
                    strokeParticipantId = team.rower6Id;
                    break;
                case 7:
                    strokeParticipantId = team.rower7Id;
                    break;
                case 8:
                    strokeParticipantId = team.rower8Id;
                    break;
            }
            return participants.find(participant => participant.id === strokeParticipantId);
        }

        return (
            <Grid item container xs={1} direction="column" style={{minWidth: 300}}>
                <Grid item>
                    <Typography variant={'h4'} noWrap>
                        {props.title}
                    </Typography>
                    <Typography variant={'body2'} noWrap>
                        {props.teams.length} teams
                    </Typography>
                    {props.showStartNumberInput ? (
                        <div>
                            <TextField
                                label={'Heat first start number'}
                                type={'number'}
                                value={props.startNumber}
                                error={!props.startNumberValid}
                                onChange={event => {
                                    const inputValue = parseInt(event.target.value, 10);
                                    props.setStartNumber(inputValue);
                                }}
                            />
                        </div>
                    ) : null}
                </Grid>
                <Grid item>
                    <TeamDroppable droppableId={props.laneId}>
                        <List>
                            {props.teams.map((team, index) => (
                                <TeamDraggable key={team.id} teamId={team.id} index={index}>
                                    <TeamBadge
                                        team={team}
                                        stroke={getStroke(team)}
                                        setTags={(tags: Tag[]) => {
                                            props.setTags(team, tags);
                                        }}
                                    />
                                </TeamDraggable>
                            ))}
                        </List>
                    </TeamDroppable>
                </Grid>
            </Grid>
        );
    }
}
