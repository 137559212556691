import {Session} from '../../../models/session';
import {LocalStorageDriver} from '../drivers/local_storage_driver';

export interface SessionRepository {
    get(): Promise<Session | null>;

    store(session: Session): Promise<Session>;

    clear(): Promise<void>;
}

export class DefaultSessionRepository implements SessionRepository {
    private _localStorageDriver: LocalStorageDriver<Session>;

    constructor(localstorageDriver: LocalStorageDriver<Session>) {
        this._localStorageDriver = localstorageDriver;
    }

    public async get(): Promise<Session | null> {
        const session = await this._localStorageDriver.get();

        return session || null;
    }

    public async store(session: Session): Promise<Session> {
        return await this._localStorageDriver.set(session);
    }

    public async clear(): Promise<void> {
        await this._localStorageDriver.destroy();
    }
}
