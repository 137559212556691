import {Observable} from 'rxjs';
import {Team} from '../../models/team';
import {TeamRepository} from './repositories/team_repository';
import {map} from 'rxjs/operators';

export interface TeamProvider {
    attendingTeams(): Observable<Team[]>;
}

export class DefaultTeamProvider implements TeamProvider {
    private readonly _heatId: string;
    private _teamRepository: TeamRepository;

    constructor(heatId: string, teamRepository: TeamRepository) {
        this._heatId = heatId;
        this._teamRepository = teamRepository;
    }

    public attendingTeams(): Observable<Team[]> {
        return this._teamRepository.findByHeatId(this._heatId).pipe(
            map(teams => teams.filter(team => team.isAttending)),
            map(teams => teams.sort(this.sortTeams)),
        );
    }

    private sortTeams(a: Team, b: Team) {
        if (a.number !== null && b.number !== null) {
            return a.number > b.number ? 1 : -1;
        }
        if (a.number !== null) {
            return -1;
        }
        if (b.number !== null) {
            return 1;
        }

        return a.name > b.name ? 1 : -1;
    }
}
