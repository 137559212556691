import {delay, first, map, tap} from 'rxjs/operators';
import {RecordTimeRequest} from '../tracking/models/record_time_request';
import {RecordedTimesProvider} from './recorded_times_provider';
import {combineLatest, Observable} from 'rxjs';
import {TimePairMerger} from './support/time_pair_merger';
import {animationFrames} from '../support/observables/animation_frames';
import {RemoteRecorder} from './recorders/remote_recorder';
import {LocalRecorder} from './recorders/local_recorder';
import {RecordedTimePair} from './models/recorded_time_pair';
import {RecordedTime} from '../../models/recorded_time';

export interface TimeRecorder {
    sync(): Promise<void>;

    recordTime(recordTimeRequest: RecordTimeRequest): Promise<void>;

    recordedTimes(teamId: string): Observable<RecordedTimePair>;

    allTimes(): Observable<RecordedTime[]>;
}

export class DefaultTimeRecorder implements TimeRecorder {
    constructor(
        private heatId: string,
        private recordedTimeProvider: RecordedTimesProvider,
        private localRecorder: LocalRecorder,
        private remoteRecorder: RemoteRecorder,
    ) {}

    public allTimes(): Observable<RecordedTime[]> {
        return combineLatest(this.recordedTimeProvider.allRecordedTimes(), this.localRecorder.allRecordedTimes()).pipe(
            map(([a, b]: [RecordedTime[], RecordedTime[]]) => {
                const recordedTimeMap = new Map<string, RecordedTime>();

                a.forEach(recordedTime => recordedTimeMap.set(recordedTime.id, recordedTime));
                b.forEach(recordedTime => recordedTimeMap.set(recordedTime.id, recordedTime));

                return [...recordedTimeMap.values()];
            }),
        );
    }

    public recordedTimes(teamId: string): Observable<RecordedTimePair> {
        return combineLatest(
            this.recordedTimeProvider.recordedTimes(teamId),
            this.localRecorder.recordedTimes(teamId),
        ).pipe(
            map(([persistedRecordedTimePair, newRecordedTime]) =>
                TimePairMerger.mergeWithRecordedTimeForTeamId(persistedRecordedTimePair, newRecordedTime, teamId),
            ),
        );
    }

    public async recordTime(recordTimeRequest: RecordTimeRequest): Promise<void> {
        const recordedTimes = await this.localRecorder.record(recordTimeRequest);

        //Make sure the UI has time to redraw the newly acquired locally persisted time
        //before Firebase will kick-in and mess this up
        await animationFrames()
            .pipe(delay(100), first())
            .toPromise();

        const teams = recordTimeRequest.teams.map(team => team);

        const synchronizedRecordedTimes = await Promise.all(
            recordedTimes.map(recordedTime => {
                return this.remoteRecorder.record(
                    recordedTime,
                    teams.find(team => team.id === recordedTime.context.teamId) || null,
                );
            }),
        );

        await this.localRecorder.markSynced(synchronizedRecordedTimes);
    }

    public async sync(): Promise<void> {
        const unsyncedRecordedTimes = await this.localRecorder.unsynced();

        await Promise.all(
            unsyncedRecordedTimes.map(async recordedTime => {
                const synchronizedRecordedTime = await this.remoteRecorder.sync(recordedTime);

                await this.localRecorder.markSynced([synchronizedRecordedTime]);
            }),
        );
    }
}
