import {RecordedAs, RecordedTime} from '../../../models/recorded_time';
import {RecordedTimeRepository} from '../../tracking/repositories/recorded_time_repository';
import {TeamRepository} from '../../tracking/repositories/team_repository';
import {Team} from '../../../models/team';
import {first} from 'rxjs/operators';

export class RemoteRecorder {
    constructor(private recordedTimeRepository: RecordedTimeRepository, private teamRepository: TeamRepository) {}

    public async record(recordedTime: RecordedTime, team: Team | null): Promise<RecordedTime> {
        const result = await this.recordedTimeRepository.createWithId(
            recordedTime.id,
            recordedTime.heatId,
            recordedTime.timeMs,
            recordedTime.context,
        );

        if (team !== null) {
            await this.setTeamTime(team, result);
        }

        return result;
    }

    public async sync(recordedTime: RecordedTime): Promise<RecordedTime> {
        const result = await this.recordedTimeRepository.createWithId(
            recordedTime.id,
            recordedTime.heatId,
            recordedTime.timeMs,
            recordedTime.context,
        );

        if (recordedTime.context.teamId !== null) {
            const team: Team | null = await this.teamRepository
                .findById(recordedTime.context.teamId)
                .pipe(first())
                .toPromise();

            if (team !== null) {
                await this.setTeamTime(team, result);
            }
        }

        return result;
    }

    private async setTeamTime(team: Team, result: RecordedTime) {
        const upToDateTeam: Team | null = await this.teamRepository
            .findById(team.id)
            .pipe(first())
            .toPromise();
        if (upToDateTeam === null) {
            throw new Error('Assertaion error');
        } else {
            await this.teamRepository.update({
                ...upToDateTeam,
                recordedStartTimeId:
                    result.context.recordedAs === RecordedAs.Start ? result.id : upToDateTeam.recordedStartTimeId,
                recordedFinishTimeId:
                    result.context.recordedAs === RecordedAs.Finish ? result.id : upToDateTeam.recordedFinishTimeId,
            });
        }
    }
}
