//import * as Papa from 'papaparse';
//const windows1252 = require('windows-1252');
import {BoatType, ParticipantGroup} from './boat_type';
import {Category} from '../../models/team';
import {Gender} from '../../models/participant';
import {toBoatType} from './importing/boat_type_map';
import {encodeClubName} from './club_code_encoder';

export interface ParticipantData {
    name: string;
    birthYear: number;
    membershipCode: string;
    clubCode: string;
    gender: Gender;
}

export interface TeamData {
    name: string;
    clubCode: string;
    boat: string;
    coach: string;
    notes: string;
    participants: ParticipantData[];
    coxswain: ParticipantData | null;
    category: Category;
}

export class CsvImportParser {
    public process(parsedData: any): TeamData[] {
        const teamList: TeamData[] = [];
        for (const row of parsedData) {
            if (!('WedstrijdCode' in row)) {
                throw new Error('Row is missing WedstrijdCode field');
            }
            const boatType = toBoatType(row.WedstrijdCode);
            if (boatType === undefined) {
                throw new Error('BoatType undefined for: ' + row.WedstrijdCode);
            }

            const participants: ParticipantData[] = this.getParticipants(row, boatType);
            const coxswain: ParticipantData | null = this.getCoxswain(row, boatType);

            const teamData: TeamData = {
                name: row.VerenigingNaam || row.VerenigingsNaam,
                clubCode: this.getClubCode(row),
                boat: row.Boot,
                category: boatType.category,
                participants: participants,
                coxswain: coxswain,
                coach: row.Coach,
                notes: row.Opmerkingen,
            };
            teamList.push(teamData);
        }
        return teamList;
    }

    private getClubCode(row: any): string {
        //VerenigingCode is how its was called before 2022
        if (row.VerenigingCode !== '' && row.VerenigingCode !== undefined) {
            return row.VerenigingCode;
        }
        //Even though VerenigingsCode is in the export it is empty
        if (row.VerenigingsCode !== '' && row.VerenigingsCode !== undefined) {
            return row.VerenigingsCode;
        }

        return encodeClubName(row.VerenigingsNaam);
    }

    private getCoxswain(row: any, boatType: BoatType) {
        return boatType.hasCoxswain ? this.convertParticipant(row, 'Stuur') : null;
    }

    private getParticipants(row: any, boatType: BoatType) {
        const participants: ParticipantData[] = [];
        // The KNRB export format is quite flexibly defined :) Seems that e.g. a 2x has a 'Boeg' and a '2'. Parsing below
        // now accepts the numbers 1..8 and Boeg and Slag.
        if (row.Boeg) {
            participants.push(this.convertParticipant(row, 'Boeg', boatType.participantGroup));
        }
        for (let i = 1; i <= boatType.numberOfRowers; i++) {
            const field = String(i);
            if (row[field]) {
                participants.push(this.convertParticipant(row, field, boatType.participantGroup));
            }
        }
        if (row.Slag) {
            participants.push(this.convertParticipant(row, 'Slag', boatType.participantGroup));
        }
        return participants;
    }

    private getGender(participantGroup?: ParticipantGroup): Gender {
        if (!participantGroup) {
            return 'Unknown';
        } else if (participantGroup === ParticipantGroup.Men || participantGroup === ParticipantGroup.Boys) {
            return 'Male';
        } else if (participantGroup === ParticipantGroup.Women || participantGroup === ParticipantGroup.Girls) {
            return 'Female';
        } else {
            return 'Unknown';
        }
    }

    private convertParticipant(data: any, suffix: string, participantGroup?: ParticipantGroup): ParticipantData {
        // In the new format since 2022, we get 'gebBoeg' and 'geb 2' etc. So now accept either with or without the space in between.
        const birthYear = Number(data['geb ' + suffix] ? data['geb ' + suffix] : data['geb' + suffix]);
        const name = data[suffix];
        const membershipCode = data['NKODE ' + suffix];
        const clubCode = data['VKODE ' + suffix];
        return {
            name: name,
            birthYear: birthYear,
            membershipCode: membershipCode,
            clubCode: clubCode,
            gender: this.getGender(participantGroup),
        };
    }
}
