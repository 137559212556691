export interface YearProvider {
    getYear(): number;
}

export class DefaultYearProvider implements YearProvider {
    public getYear(): number {
        return new Date().getFullYear();
    }
}

// tslint:disable-next-line:max-classes-per-file
export class TestYearProvider implements YearProvider {
    constructor(private year: number) {}

    public getYear(): number {
        return this.year;
    }
}
