import {Observable, Observer} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

export class LocalStorageDriver<T> {
    private _namespace: string;
    private _streamObservable: Observable<T | null> | undefined;

    public static isAvailable() {
        return window.localStorage !== undefined;
    }

    constructor(namespace: string) {
        this._namespace = namespace;
    }

    public stream(): Observable<T | null> {
        if (this._streamObservable === undefined) {
            this._streamObservable = new Observable((observer: Observer<T | null>) => {
                const namespace = this._namespace;
                const listener = function() {
                    const result = localStorage.getItem(namespace);

                    observer.next(result === null ? null : (JSON.parse(result) as T));
                };
                window.addEventListener('storage', listener, false);

                const item = localStorage.getItem(namespace);
                observer.next(item === null || item === undefined ? null : (JSON.parse(item) as T));

                return () => {
                    window.removeEventListener('storage', listener);
                };
            }).pipe(shareReplay(1));
        }
        return this._streamObservable as Observable<T | null>;
    }

    public async set(val: T): Promise<T> {
        localStorage.setItem(this._namespace, JSON.stringify(val));

        LocalStorageDriver.dispatchStorageEventInOwnTab();

        return val;
    }

    public async get(): Promise<T | null> {
        const item = localStorage.getItem(this._namespace);

        return item === null ? null : (JSON.parse(item) as T);
    }

    public async destroy(): Promise<void> {
        localStorage.removeItem(this._namespace);
        LocalStorageDriver.dispatchStorageEventInOwnTab();
    }

    private static dispatchStorageEventInOwnTab() {
        setTimeout(() => {
            const event = new Event('storage');
            window.dispatchEvent(event);
        }, 0);
    }
}
