import * as React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import {Theme} from '@material-ui/core';
import {usePresenter} from './support/use_presenter';
import {useObservable} from './support/hooks/use_observable';
import {Heat as HeatComponent} from './heat/heat';
import Loading from './components/loading';
import {Timer} from './timer/timer';
import {Maybe} from 'tsmonad';
import {HeatWithEdition} from '../tracking/tracking_controller';
import {RegattaMode} from '../../models/edition';

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 0,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
});

interface OwnProps {
    id: string;
}

function ClientComponent({id, classes}: OwnProps & WithStyles<'root'>) {
    const presenter = usePresenter(component => ({
        heat: component.trackingController(id).heat(),
    }));
    const heatMaybe: Maybe<HeatWithEdition> | null = useObservable(presenter.heat, null);

    //TODO refactor this
    if (heatMaybe === null) {
        return (
            <div>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 0.8}}>
                            WedstrijdKlok
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Loading />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    const heat = heatMaybe.caseOf({
        nothing: () => null,
        just: h => h,
    });

    if (heat === null) {
        return (
            <div>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="title" color="inherit" style={{flex: 0.8}}>
                            WedstrijdKlok
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    <Grid container spacing={8}>
                        <Grid item xs={12}>
                            <Grid container>Not found..</Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

    return (
        <div>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="title" color="inherit" style={{flex: 0.8}}>
                        WedstrijdKlok
                    </Typography>
                    <Typography variant="overline" color="inherit" style={{flex: 0.2, textAlign: 'right'}}>
                        <Timer heat={heat} />
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <HeatComponent heat={heat} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export const Client = withStyles(styles)(ClientComponent);
