import * as React from 'react';
import {Grid, Theme, WithStyles} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {Edition} from '../../../../models/edition';
import {observer} from 'mobx-react';
import {PresenterProps, withPresenter} from '../../../support/with_presenter';
import {ResultsPresenter} from './results_presenter';
import TabContainer from '../components/tab_container';
import Unprintable from '../../components/unprintable';
import PrintOnly from '../../components/print_only';
import Content from '../components/content';
import Header from '../components/header';
import {ResultPreview} from './components/result_preview';
import {ResultPrint} from './components/result_print';
import {ResultRequestForm} from './result_request_form';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Table from '@material-ui/core/Table';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {DefaultResultGrouper} from '../../../business/results/internal/team_grouper';
import {ResultGroupingPreview} from './components/result_grouping_preview';
import {DeleteIcon} from '../../components/icons/delete_icon';
import {ViewIcon} from '../../components/icons/view_icon';

interface OwnProps {
    edition: Edition;
}

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: -60,
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
});

@observer
class ResultsComponent extends React.Component<OwnProps & PresenterProps<ResultsPresenter> & WithStyles<'root'>> {
    public render() {
        const hasParticipantsWithUnknownGender =
            this.props.presenter.results !== null &&
            this.props.presenter.results.some(resultGroup =>
                resultGroup.results.some(result =>
                    result.participants.some(participant => participant.gender === 'Unknown'),
                ),
            );
        const genderWarningMessage = hasParticipantsWithUnknownGender ? (
            <div style={{backgroundColor: 'red'}}>Warning: For some participants the gender is not yet known</div>
        ) : null;

        return (
            <>
                <Unprintable>
                    <div className={this.props.classes.root}>
                        <TabContainer>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Header
                                        breadcrumbs={[
                                            {
                                                name: 'Uitslagen',
                                                link: '/edition/results',
                                            },
                                        ]}>
                                        Uitslagen
                                    </Header>
                                    <Content>
                                        {genderWarningMessage}
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>In narrowcasting</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.presenter.resultRequests.map(resultRequest => (
                                                    <TableRow key={resultRequest.id}>
                                                        <TableCell>{resultRequest.name}</TableCell>
                                                        <TableCell>
                                                            {resultRequest.includeInNarrowcasting ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <a
                                                                onClick={() =>
                                                                    confirm('Are you sure?') &&
                                                                    this.props.presenter.onResultRequestDelete(
                                                                        resultRequest,
                                                                    )
                                                                }>
                                                                <DeleteIcon />
                                                            </a>
                                                            <a
                                                                onClick={() =>
                                                                    this.props.presenter.onResultRequestActivate(
                                                                        resultRequest,
                                                                    )
                                                                }>
                                                                <ViewIcon />
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Content>
                                    <Header>Edit result overview</Header>
                                    <ResultRequestForm
                                        key={this.props.presenter.activeResultRequest.id}
                                        edition={this.props.edition}
                                        resultRequest={this.props.presenter.activeResultRequest}
                                        onChange={resultRequest =>
                                            this.props.presenter.onResultRequestChange(resultRequest)
                                        }
                                        onClear={() => this.props.presenter.onClear()}
                                        onSubmit={() => this.props.presenter.onSubmit()}
                                    />
                                </Grid>
                            </Grid>
                        </TabContainer>
                    </div>
                </Unprintable>
                {this.props.presenter.results !== null ? (
                    <>
                        <PrintOnly>
                            <ResultPrint
                                resultRequest={this.props.presenter.activeResultRequest}
                                edition={this.props.edition}
                                results={this.props.presenter.results}
                            />
                        </PrintOnly>
                        <Unprintable>
                            <div className={this.props.classes.root}>
                                <TabContainer>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Header>Preview grouping</Header>
                                            <ResultGroupingPreview
                                                resultRequest={this.props.presenter.activeResultRequest}
                                                groups={this.props.presenter.groups}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            </div>
                        </Unprintable>
                        <Unprintable>
                            <div className={this.props.classes.root}>
                                <TabContainer>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Header>Preview results</Header>
                                            <ResultPreview
                                                resultRequest={this.props.presenter.activeResultRequest}
                                                results={this.props.presenter.results}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            </div>
                        </Unprintable>
                    </>
                ) : null}
            </>
        );
    }
}

export const Results = withPresenter<ResultsPresenter, OwnProps>(
    (props, component) =>
        new ResultsPresenter(
            props.edition,
            component.business.resultProvider,
            component.persistence.resultRequestRepository,
            new DefaultResultGrouper(component.persistence.heatRepository),
            component.persistence.teamRepository,
        ),
    withStyles(styles)(ResultsComponent),
);
