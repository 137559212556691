import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {WithStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BreadcrumbIcon from '@material-ui/icons/KeyboardArrowRight';
import {Link} from 'react-router-dom';

interface Breadcrumb {
    name: string;
    link: string;
}

interface OwnProps {
    breadcrumbs?: Breadcrumb[];
}

const styles = (theme: Theme) => ({
    root: {
        fontSize: 36,
        margin: '24px 12px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            margin: '12px',
        },
    },
    rootWithBreadcrumb: {
        fontSize: 36,
        margin: '24px 12px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            margin: '12px',
        },
    },
    breadcrumbContainer: {},
    breadcrumb: {
        margin: '0 5px',
    },
});

class Header extends React.Component<
    OwnProps & WithStyles<'root' | 'rootWithBreadcrumb' | 'breadcrumbContainer' | 'breadcrumb'>
> {
    public render() {
        if (this.props.breadcrumbs) {
            return (
                <span>
                    <span className={this.props.classes.breadcrumbContainer}>
                        {this.props.breadcrumbs.map((breadcrumb, i) => {
                            return (
                                <span key={i}>
                                    <Button
                                        variant="text"
                                        className={this.props.classes.breadcrumb}
                                        component={props => <Link to={breadcrumb.link} {...props as any} />}>
                                        {breadcrumb.name}
                                    </Button>
                                    {i < this.props.breadcrumbs!!.length - 1 ? (
                                        <BreadcrumbIcon style={{height: 15}} />
                                    ) : null}
                                </span>
                            );
                        })}
                    </span>
                    <Typography variant="title" className={this.props.classes.rootWithBreadcrumb}>
                        {this.props.children}
                    </Typography>
                </span>
            );
        }
        return (
            <Typography variant="title" className={this.props.classes.root}>
                {this.props.children}
            </Typography>
        );
    }
}

export default withStyles(styles)(Header);
