import * as React from 'react';
import {Team} from '../../../../models/team';
import {usePresenter} from '../../support/use_presenter';
import {useObservable} from '../../support/hooks/use_observable';
import {RecordedTimePair} from '../../../tracking/models/recorded_time_pair';
import {TeamTimer} from './team_timer';
import {distinctUntilChanged} from 'rxjs/operators';
import {pure} from 'recompose';

interface OwnProps {
    team: Team;
}

export const TeamStatus = pure(({team}: OwnProps) => {
    const presenter = usePresenter(component => ({
        recordedTimes: component
            .trackingController(team.heatId)
            .recordedTimes(team.id)
            .pipe(
                distinctUntilChanged(
                    (a: RecordedTimePair, b: RecordedTimePair) => JSON.stringify(a) === JSON.stringify(b),
                ),
            ),
    }));

    const recordedTimePair: RecordedTimePair | null = useObservable(presenter.recordedTimes, null);
    if (recordedTimePair === null || recordedTimePair.start === null) {
        return <>--:--</>;
    }

    return <TeamTimer recordedTimePair={recordedTimePair} />;
});
