import * as React from 'react';
import {observer} from 'mobx-react';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import {ClockPresenter} from './clock_presenter';
import Modal from '@material-ui/core/Modal';
import {Theme, WithStyles} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

interface OwnProps {}

const styles = (theme: Theme) => ({
    paper: {
        position: 'absolute' as any,
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        textAlign: 'center' as any,
    },
});

@observer
class ClockComponent extends React.Component<OwnProps & PresenterProps<ClockPresenter> & WithStyles<'paper'>> {
    public render() {
        return (
            <span style={{margin: '0 10px'}}>
                <a onClick={() => this.props.presenter.showDetailedTime()}>
                    {this.props.presenter.formattedTimeLowPrecision}
                </a>
                <Modal
                    open={this.props.presenter.detailedTimeModalVisible}
                    onClose={() => this.props.presenter.onModalClose()}>
                    <div className={this.props.classes.paper}>
                        <Typography variant="h2">{this.props.presenter.formattedTimeHighPrecision}</Typography>
                    </div>
                </Modal>
            </span>
        );
    }
}

export const Clock = withPresenter<ClockPresenter, OwnProps>(
    (props, component) => new ClockPresenter(component.business.serverTimeProvider),
    withStyles(styles)(ClockComponent),
);
