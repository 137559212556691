import {Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {Team} from '../../models/team';
import {RecordedTimeRepository} from '../tracking/repositories/recorded_time_repository';
import {TeamRepository} from '../tracking/repositories/team_repository';
import {RecordedTimePair} from './models/recorded_time_pair';
import {RecordedTime} from '../../models/recorded_time';

export interface RecordedTimesProvider {
    recordedTimes(teamId: string): Observable<RecordedTimePair>;

    allRecordedTimes(): Observable<RecordedTime[]>;
}

export class DefaultRecordedTimesProvider implements RecordedTimesProvider {
    private readonly _heatId: string;
    private _recordedTimeRepository: RecordedTimeRepository;
    private _teamRepository: TeamRepository;

    constructor(heatId: string, recordedTimeRepository: RecordedTimeRepository, teamRepository: TeamRepository) {
        this._heatId = heatId;
        this._recordedTimeRepository = recordedTimeRepository;
        this._teamRepository = teamRepository;
    }

    public allRecordedTimes(): Observable<RecordedTime[]> {
        return this._recordedTimeRepository.findByHeatId(this._heatId);
    }

    public recordedTimes(teamId: string): Observable<RecordedTimePair> {
        return this._teamRepository.findById(teamId).pipe(
            filter<Team | null, Team>((team: Team | null): team is Team => team !== null),
            switchMap((team: Team) => {
                if (team.heatId === null) {
                    return of({start: null, finish: null});
                }
                return this._recordedTimeRepository.findByHeatId(team.heatId).pipe(
                    map(recordedTimes => {
                        return {
                            start:
                                recordedTimes.find(recordedTime => recordedTime.id === team.recordedStartTimeId) ||
                                null,
                            finish:
                                recordedTimes.find(recordedTime => recordedTime.id === team.recordedFinishTimeId) ||
                                null,
                        };
                    }),
                );
            }),
        );
    }
}
