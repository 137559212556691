export class ResourceChecker {
    private count: Map<string, number> = new Map();

    public add(resource: string | null, heatId: string): void {
        if (resource === null) {
            return;
        }
        const key = resource + '=>' + heatId;
        const value = this.count.get(key);
        if (value === undefined) {
            this.count.set(key, 1);
        } else {
            this.count.set(key, value + 1);
        }
    }

    public isUsedMultipleTimes(resource: string, heatId: string): boolean {
        const key = resource + '=>' + heatId;
        const count = this.count.get(key);
        if (count === undefined) {
            return false;
        } else {
            return count > 1;
        }
    }
}
