import * as React from 'react';
import {InschrijvingenPresenter} from './inschrijvingen_presenter';
import {PresenterProps, withPresenter} from '../../../../support/with_presenter';
import Loading from '../../../components/loading';
import {Edition} from '../../../../../models/edition';
import {observer} from 'mobx-react';
import PrintPage from '../../../components/print_page';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import {Team} from '../../../../../models/team';
import {decodeClubCode} from '../../../../support/club_code_decoder';
import {Participant} from '../../../../../models/participant';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import {TableHead} from '@material-ui/core';

const styles = createStyles({
    tableCell: {
        paddingLeft: 6,
        paddingRight: 6,
    },
});

interface OwnProps extends WithStyles<typeof styles> {
    edition: Edition;
}

@observer
class InschrijvingenPrint extends React.Component<OwnProps & PresenterProps<InschrijvingenPresenter>> {
    private sortTeams(a: Team, b: Team): number {
        if (a.number === null && b.number === null) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
        if (a.number === null) {
            return -1;
        }
        if (b.number === null) {
            return 1;
        }
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }
        return 0;
    }

    public render() {
        if (this.props.presenter.loading) {
            return (
                <Grid container>
                    <Loading />
                </Grid>
            );
        }
        const {classes} = this.props;
        return (
            <>
                {this.props.presenter.heats.map(heat => {
                    return (
                        <PrintPage key={heat.id}>
                            <h2>
                                {this.props.edition.name}: {heat.name}
                            </h2>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>Rug</TableCell>
                                        <TableCell className={classes.tableCell}>Categorie</TableCell>
                                        <TableCell className={classes.tableCell}>Veld</TableCell>
                                        <TableCell className={classes.tableCell}>Vereniging</TableCell>
                                        <TableCell className={classes.tableCell}>Boot</TableCell>
                                        <TableCell className={classes.tableCell}>Verenigingscode</TableCell>
                                        <TableCell className={classes.tableCell}>Stuur</TableCell>
                                        <TableCell className={classes.tableCell}>Roeiers</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.presenter.teams
                                        .sort(this.sortTeams)
                                        .filter(team => team.heatId === heat.id)
                                        .map(team => {
                                            return (
                                                <TableRow>
                                                    <TableCell className={classes.tableCell}>
                                                        {team.number ? team.number : '?'}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <span style={{whiteSpace: 'nowrap'}}>{team.category}</span>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <span style={{whiteSpace: 'nowrap'}}>
                                                            {team.tags.map(tag => tag.name).join(' ')}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {decodeClubCode(team.clubCode)}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>{team.boat}</TableCell>
                                                    <TableCell className={classes.tableCell}>{team.name}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.coxswain(team)}
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {this.rowers(team)}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </PrintPage>
                    );
                })}
            </>
        );
    }

    private coxswain(team: Team): string {
        if (team.coxswainId === null) {
            return '-';
        }
        const coxswain = this.props.presenter.participants.find(participant => participant.id === team.coxswainId);

        return coxswain ? coxswain.name + ' (' + coxswain.birthYear + ')' : '-';
    }

    private rowers(team: Team) {
        return [
            team.rower1Id,
            team.rower2Id,
            team.rower3Id,
            team.rower4Id,
            team.rower5Id,
            team.rower6Id,
            team.rower7Id,
            team.rower8Id,
        ]
            .filter<string>((id: string | null): id is string => id !== null)
            .map((participantId: string) =>
                this.props.presenter.participants.find(participant => participant.id === participantId),
            )
            .filter<Participant>(
                (participant: Participant | undefined): participant is Participant => participant !== undefined,
            )
            .map((participant: Participant, index: number) => (
                <div>{index + 1 + ': ' + participant.name + ' (' + participant.birthYear + ')'}</div>
            ));
    }
}

export default withStyles(styles)(
    withPresenter<InschrijvingenPresenter, OwnProps>(
        (props, component) =>
            new InschrijvingenPresenter(
                props.edition,
                component.persistence.teamRepository,
                component.persistence.heatRepository,
                component.persistence.participantRepository,
            ),
        InschrijvingenPrint,
    ),
);
