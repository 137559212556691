import * as React from 'react';
import {PresenterProps, withPresenter} from '../../../../../support/with_presenter';
import {EditTeamPresenter} from './teams_edit_presenter';
import Grid from '@material-ui/core/Grid';
import Content from '../../../components/content';
import {observer} from 'mobx-react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Loading from '../../../../components/loading';
import {categories, Team} from '../../../../../../models/team';
import {Participant} from '../../../../../../models/participant';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import {TagsInputWrapper} from '../../../components/tags_input_wrapper';

interface OwnProps {
    team: Team;
    participants: Participant[];
}

@observer
class EditTeamScreen extends React.Component<OwnProps & PresenterProps<EditTeamPresenter>> {
    private renderRowerInput(position: number, value: string, onChange: (e: any) => void) {
        return (
            <FormControl fullWidth>
                <InputLabel htmlFor={'rower-' + position}>Rower {position}</InputLabel>
                <Select
                    native
                    value={value}
                    onChange={(e: any) => onChange(e)}
                    input={<Input id={'rower-' + position} />}>
                    <option value="" />
                    {this.props.participants.map(participant => {
                        return (
                            <option key={participant.id} value={participant.id}>
                                {participant.name}
                            </option>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }

    public render() {
        if (this.props.presenter.loading) {
            return (
                <Content>
                    <Loading />
                </Content>
            );
        }
        if (this.props.presenter.heats === undefined) {
            //TODO add fancy 500
            return <div>Error</div>;
        }
        return (
            <Content>
                <FormControl fullWidth>
                    <InputLabel htmlFor="number">Number</InputLabel>
                    <Input
                        id="number"
                        value={this.props.presenter.number}
                        disabled={this.props.presenter.loading}
                        onChange={(e: any) => this.props.presenter.onNumberChange(e.target.value)}
                    />
                    <FormHelperText>{this.props.presenter.numberHelperText}</FormHelperText>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <Input
                        id="name"
                        value={this.props.presenter.name}
                        disabled={this.props.presenter.loading}
                        onChange={(e: any) => this.props.presenter.onNameChange(e.target.value)}
                    />
                </FormControl>
                <FormControlLabel
                    label="Is attending"
                    control={
                        <Checkbox
                            id="name"
                            checked={this.props.presenter.isAttending}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any, checked: boolean) => this.props.presenter.onIsAttendingChange(checked)}
                        />
                    }
                />
                <FormControlLabel
                    label="Is in competition"
                    control={
                        <Checkbox
                            id="name"
                            checked={this.props.presenter.isInCompetition}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any, checked: boolean) =>
                                this.props.presenter.onIsInCompetitionChange(checked)
                            }
                        />
                    }
                />
                <FormControlLabel
                    label="Is qualified"
                    control={
                        <Checkbox
                            id="name"
                            checked={this.props.presenter.isQualified}
                            disabled={this.props.presenter.loading}
                            onChange={(e: any, checked: boolean) => this.props.presenter.onIsQualifiedChange(checked)}
                        />
                    }
                />
                <FormControl fullWidth>
                    <InputLabel htmlFor="club_code">Club code</InputLabel>
                    <Input
                        id="club_code"
                        value={this.props.presenter.clubCode}
                        disabled={this.props.presenter.loading}
                        onChange={(e: any) => this.props.presenter.onClubCodeChange(e.target.value)}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="boat">Boat</InputLabel>
                    <Input
                        id="boat"
                        value={this.props.presenter.boat}
                        disabled={this.props.presenter.loading}
                        onChange={(e: any) => this.props.presenter.onBoatChange(e.target.value)}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="category">Category</InputLabel>
                    <Select
                        native
                        value={this.props.presenter.category}
                        onChange={(e: any) => this.props.presenter.onCategoryChange(e.target.value)}
                        input={<Input id="category" />}>
                        <option value="" />
                        {categories.map(category => {
                            return (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="tags">tags</InputLabel>
                    <Input
                        value={this.props.presenter.tags}
                        onChange={tags => this.props.presenter.onTagsChange(tags as any)}
                        inputComponent={TagsInputWrapper as any}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel htmlFor="heat-id">Heat</InputLabel>
                    <Select
                        native
                        value={this.props.presenter.heatId}
                        onChange={(e: any) => this.props.presenter.onHeatIdChange(e.target.value)}
                        input={<Input id="heat-id" />}>
                        <option value="-1">No heat</option>
                        {this.props.presenter.heats.map(heat => {
                            return (
                                <option key={heat.id} value={heat.id}>
                                    {heat.name}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel htmlFor="coxswain">Coxswain</InputLabel>
                    <Select
                        native
                        value={this.props.presenter.coxswainId}
                        onChange={(e: any) => this.props.presenter.onCoxswainIdChange(e.target.value)}
                        input={<Input id="coxswain" />}>
                        <option value="" />
                        {this.props.participants.map(participant => {
                            return (
                                <option key={participant.id} value={participant.id}>
                                    {participant.name}
                                </option>
                            );
                        })}
                    </Select>
                </FormControl>
                {this.renderRowerInput(1, this.props.presenter.rower1Id, (e: any) =>
                    this.props.presenter.onRower1IdChange(e.target.value),
                )}
                {this.renderRowerInput(2, this.props.presenter.rower2Id, (e: any) =>
                    this.props.presenter.onRower2IdChange(e.target.value),
                )}
                {this.renderRowerInput(3, this.props.presenter.rower3Id, (e: any) =>
                    this.props.presenter.onRower3IdChange(e.target.value),
                )}
                {this.renderRowerInput(4, this.props.presenter.rower4Id, (e: any) =>
                    this.props.presenter.onRower4IdChange(e.target.value),
                )}
                {this.renderRowerInput(5, this.props.presenter.rower5Id, (e: any) =>
                    this.props.presenter.onRower5IdChange(e.target.value),
                )}
                {this.renderRowerInput(6, this.props.presenter.rower6Id, (e: any) =>
                    this.props.presenter.onRower6IdChange(e.target.value),
                )}
                {this.renderRowerInput(7, this.props.presenter.rower7Id, (e: any) =>
                    this.props.presenter.onRower7IdChange(e.target.value),
                )}
                {this.renderRowerInput(8, this.props.presenter.rower8Id, (e: any) =>
                    this.props.presenter.onRower8IdChange(e.target.value),
                )}

                <FormControl fullWidth>
                    <InputLabel htmlFor="name">Notes</InputLabel>
                    <Input
                        id="name"
                        multiline
                        rowsMax={4}
                        value={this.props.presenter.notes}
                        disabled={this.props.presenter.loading}
                        onChange={(e: any) => this.props.presenter.onNotesChange(e.target.value)}
                    />
                </FormControl>
                <Grid justify="flex-end" container>
                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={!this.props.presenter.submitIsEnabled}
                            style={{marginTop: 16}}
                            onClick={() => this.props.presenter.onSubmitClicked()}>
                            {this.props.presenter.saving ? 'Saving..' : 'Save'}
                        </Button>
                    </Grid>
                </Grid>
            </Content>
        );
    }
}

export default withPresenter<EditTeamPresenter, OwnProps>(
    (props, component) =>
        new EditTeamPresenter(props.team, component.persistence.teamRepository, component.persistence.heatRepository),
    EditTeamScreen,
);
