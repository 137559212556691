import {RecordedTimePair} from '../models/recorded_time_pair';
import {RecordedAs, RecordedTime} from '../../../models/recorded_time';

export class TimePairMerger {
    public static mergeWithRecordedTimeForTeamId(
        recordedTimePair: RecordedTimePair,
        recordedTime: RecordedTime | null,
        teamId: string,
    ): RecordedTimePair {
        if (recordedTime === null || recordedTime.context.teamId !== teamId) {
            return recordedTimePair;
        }
        if (this.recordedTimeValidForStart(recordedTime, recordedTimePair)) {
            return {
                start: recordedTime,
                finish: recordedTimePair.finish,
            };
        }
        if (this.recordedTimeValidForFinish(recordedTime, recordedTimePair)) {
            return {
                start: recordedTimePair.start,
                finish: recordedTime,
            };
        }
        return recordedTimePair;
    }

    private static recordedTimeValidForFinish(recordedTime: RecordedTime, recordedTimePair: RecordedTimePair) {
        return (
            recordedTime.context.recordedAs === RecordedAs.Finish &&
            (recordedTimePair.finish === null || this.recordedTimeIsNewer(recordedTimePair.finish, recordedTime))
        );
    }

    private static recordedTimeValidForStart(recordedTime: RecordedTime, recordedTimePair: RecordedTimePair) {
        return (
            recordedTime.context.recordedAs === RecordedAs.Start &&
            (recordedTimePair.start === null || this.recordedTimeIsNewer(recordedTimePair.start, recordedTime))
        );
    }

    private static recordedTimeIsNewer(previous: RecordedTime, next: RecordedTime): boolean {
        return previous.id !== next.id && previous.timeMs < next.timeMs;
    }
}
