import {HandicapTable} from '../handicap_table';

export class Handicap2023Long extends HandicapTable {
    protected create(): void {
        this.handicaps.set(13, {male: 0.876, female: 0.788});
        this.handicaps.set(14, {male: 0.912, female: 0.82});
        this.handicaps.set(15, {male: 0.936, female: 0.842});
        this.handicaps.set(16, {male: 0.952, female: 0.857});
        this.handicaps.set(17, {male: 0.963, female: 0.867});
        this.handicaps.set(18, {male: 0.972, female: 0.875});
        this.handicaps.set(19, {male: 0.979, female: 0.881});
        this.handicaps.set(20, {male: 0.986, female: 0.887});
        this.handicaps.set(21, {male: 0.992, female: 0.893});
        this.handicaps.set(22, {male: 0.997, female: 0.898});
        this.handicaps.set(23, {male: 1, female: 0.9});
        this.handicaps.set(24, {male: 1, female: 0.9});
        this.handicaps.set(25, {male: 1, female: 0.9});
        this.handicaps.set(26, {male: 1, female: 0.9});
        this.handicaps.set(27, {male: 0.997, female: 0.897});
        this.handicaps.set(28, {male: 0.994, female: 0.895});
        this.handicaps.set(29, {male: 0.992, female: 0.893});
        this.handicaps.set(30, {male: 0.991, female: 0.892});
        this.handicaps.set(31, {male: 0.99, female: 0.891});
        this.handicaps.set(32, {male: 0.989, female: 0.89});
        this.handicaps.set(33, {male: 0.988, female: 0.889});
        this.handicaps.set(34, {male: 0.986, female: 0.888});
        this.handicaps.set(35, {male: 0.985, female: 0.887});
        this.handicaps.set(36, {male: 0.984, female: 0.885});
        this.handicaps.set(37, {male: 0.982, female: 0.884});
        this.handicaps.set(38, {male: 0.981, female: 0.883});
        this.handicaps.set(39, {male: 0.979, female: 0.881});
        this.handicaps.set(40, {male: 0.977, female: 0.879});
        this.handicaps.set(41, {male: 0.974, female: 0.877});
        this.handicaps.set(42, {male: 0.972, female: 0.875});
        this.handicaps.set(43, {male: 0.969, female: 0.873});
        this.handicaps.set(44, {male: 0.967, female: 0.87});
        this.handicaps.set(45, {male: 0.964, female: 0.868});
        this.handicaps.set(46, {male: 0.961, female: 0.865});
        this.handicaps.set(47, {male: 0.958, female: 0.862});
        this.handicaps.set(48, {male: 0.955, female: 0.86});
        this.handicaps.set(49, {male: 0.952, female: 0.857});
        this.handicaps.set(50, {male: 0.949, female: 0.854});
        this.handicaps.set(51, {male: 0.946, female: 0.852});
        this.handicaps.set(52, {male: 0.943, female: 0.849});
        this.handicaps.set(53, {male: 0.94, female: 0.846});
        this.handicaps.set(54, {male: 0.937, female: 0.843});
        this.handicaps.set(55, {male: 0.934, female: 0.841});
        this.handicaps.set(56, {male: 0.931, female: 0.838});
        this.handicaps.set(57, {male: 0.928, female: 0.835});
        this.handicaps.set(58, {male: 0.925, female: 0.832});
        this.handicaps.set(59, {male: 0.921, female: 0.829});
        this.handicaps.set(60, {male: 0.918, female: 0.826});
        this.handicaps.set(61, {male: 0.914, female: 0.823});
        this.handicaps.set(62, {male: 0.911, female: 0.82});
        this.handicaps.set(63, {male: 0.907, female: 0.816});
        this.handicaps.set(64, {male: 0.902, female: 0.812});
        this.handicaps.set(65, {male: 0.898, female: 0.808});
        this.handicaps.set(66, {male: 0.893, female: 0.803});
        this.handicaps.set(67, {male: 0.887, female: 0.799});
        this.handicaps.set(68, {male: 0.881, female: 0.793});
        this.handicaps.set(69, {male: 0.875, female: 0.787});
        this.handicaps.set(70, {male: 0.868, female: 0.781});
        this.handicaps.set(71, {male: 0.86, female: 0.774});
        this.handicaps.set(72, {male: 0.851, female: 0.766});
        this.handicaps.set(73, {male: 0.842, female: 0.758});
        this.handicaps.set(74, {male: 0.832, female: 0.749});
        this.handicaps.set(75, {male: 0.822, female: 0.739});
        this.handicaps.set(76, {male: 0.81, female: 0.729});
        this.handicaps.set(77, {male: 0.798, female: 0.718});
        this.handicaps.set(78, {male: 0.785, female: 0.706});
        this.handicaps.set(79, {male: 0.771, female: 0.694});
        this.handicaps.set(80, {male: 0.757, female: 0.681});
        this.handicaps.set(81, {male: 0.741, female: 0.667});
        this.handicaps.set(82, {male: 0.726, female: 0.653});
        this.handicaps.set(83, {male: 0.71, female: 0.639});
        this.handicaps.set(84, {male: 0.693, female: 0.624});
        this.handicaps.set(85, {male: 0.677, female: 0.609});
        this.handicaps.set(86, {male: 0.66, female: 0.594});
        this.handicaps.set(87, {male: 0.644, female: 0.58});
        this.handicaps.set(88, {male: 0.628, female: 0.565});
        this.handicaps.set(89, {male: 0.613, female: 0.552});
        this.handicaps.set(90, {male: 0.6, female: 0.54});
        this.handicaps.set(91, {male: 0.588, female: 0.529});
    }
}
