import {DefaultServerTimeComponent, ServerTimeComponent} from '../../server_time/server_time_component';
import {lazy} from '../support/lazy';
import {DefaultTrackingController, TrackingController} from '../tracking/tracking_controller';
import {PersistenceComponent} from '../../administration/persistence/persistence_component';
import {DefaultHeatTimeProvider} from '../tracking/heat_time_provider';
import {DefaultTeamProvider} from '../tracking/team_provider';
import {DefaultRecordedTimesProvider} from '../recording/recorded_times_provider';
import {DefaultTimeRecorder} from '../recording/time_recorder';
import {DefaultTemporaryRecordedTimesManager} from '../recording/temporary_recorded_times_manager';
import {RemoteRecorder} from '../recording/recorders/remote_recorder';
import {LocalRecorder} from '../recording/recorders/local_recorder';
import {DefaultNetworkStatusProvider, NetworkStatusProvider} from '../support/observables/network_status_provider';
import {Observable} from 'rxjs';
import {Heat} from '../../models/heat';

export interface ClientComponent {
    serverTime: ServerTimeComponent;
    networkStatusProvider: NetworkStatusProvider;

    heatsForEdition(editionId: string): Observable<Heat[]>;

    trackingController(heatId: string | null): TrackingController;
}

export class DefaultClientComponent implements ClientComponent {
    private _persistenceComponent: PersistenceComponent;

    constructor(persistenceComponent: PersistenceComponent) {
        this._persistenceComponent = persistenceComponent;
    }

    @lazy()
    public get serverTime(): ServerTimeComponent {
        return new DefaultServerTimeComponent();
    }

    @lazy()
    public get networkStatusProvider(): NetworkStatusProvider {
        return new DefaultNetworkStatusProvider();
    }

    private _trackingControllers = new Map<string, TrackingController>();

    public trackingController(heatId: string): TrackingController {
        if (!this._trackingControllers.has(heatId)) {
            const trackingController = new DefaultTrackingController(
                heatId,
                this._persistenceComponent.heatRepository,
                this._persistenceComponent.editionRepository,
                new DefaultHeatTimeProvider(
                    heatId,
                    this.serverTime.serverTimeProvider,
                    this._persistenceComponent.heatRepository,
                ),
                new DefaultTeamProvider(heatId, this._persistenceComponent.teamRepository),
                new DefaultTimeRecorder(
                    heatId,
                    new DefaultRecordedTimesProvider(
                        heatId,
                        this._persistenceComponent.recordedTimeRepository,
                        this._persistenceComponent.teamRepository,
                    ),
                    new LocalRecorder(
                        heatId,
                        new DefaultTemporaryRecordedTimesManager(),
                        this.serverTime.serverTimeProvider,
                    ),
                    new RemoteRecorder(
                        this._persistenceComponent.recordedTimeRepository,
                        this._persistenceComponent.teamRepository,
                    ),
                ),
            );
            this._trackingControllers.set(heatId, trackingController);
        }
        return this._trackingControllers.get(heatId) as TrackingController;
    }

    public heatsForEdition(editionId: string): Observable<Heat[]> {
        return this._persistenceComponent.heatRepository.findByEditionId(editionId);
    }
}
