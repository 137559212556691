import {EditionRepository} from './edition_repository';
import {Edition, RegattaMode} from '../../../models/edition';
import FirebaseDriver from '../drivers/firebase_driver';
import {v4 as uuid} from 'uuid';

interface FirebaseEdition {
    id: string;
    token: string;
    name: string;
    handicapsSystem: string | undefined;
    mode: RegattaMode | undefined;
}

export class FirebaseEditionRepository implements EditionRepository {
    private _ref = 'editions';
    private _firebaseDriver: FirebaseDriver;

    constructor(firebaseDriver: FirebaseDriver) {
        this._firebaseDriver = firebaseDriver;
    }

    public async create(name: string, token: string, handicapsSystem: string, mode: RegattaMode): Promise<Edition> {
        const edition: Edition = {
            id: uuid(),
            token: token,
            name: name,
            handicapsSystem: handicapsSystem,
            mode: mode,
        };

        await this._firebaseDriver
            .getInstance()
            .database()
            .ref(this._ref)
            .child(edition.id)
            .set(FirebaseEditionRepository.toFirebaseEdition(edition));

        return edition;
    }

    public async findById(token: string): Promise<Edition | null> {
        return new Promise<Edition | null>((resolve, reject) => {
            //TODO unsubscribe?
            this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('id')
                .equalTo(token)
                .on('value', (snapshot: firebase.database.DataSnapshot | null) => {
                    if (snapshot === null) {
                        reject('Empty Firebase snapshot value');
                    } else {
                        const result: {[key: string]: FirebaseEdition} | null = snapshot.val();
                        if (result !== null && Object.keys(result).length > 0) {
                            const edition = result[Object.keys(result)[0]];
                            resolve(FirebaseEditionRepository.fromFirebaseEdition(edition));
                        } else {
                            resolve(null);
                        }
                    }
                });
        });
    }

    public async findByToken(token: string): Promise<Edition | null> {
        return new Promise<Edition | null>((resolve, reject) => {
            //TODO unsubscribe?
            this._firebaseDriver
                .getInstance()
                .database()
                .ref(this._ref)
                .orderByChild('token')
                .equalTo(token)
                .on('value', (snapshot: firebase.database.DataSnapshot | null) => {
                    //TODO extract this to something more generic!
                    if (snapshot === null) {
                        reject('Empty Firebase snapshot value');
                    } else {
                        const result: {[key: string]: FirebaseEdition} | null = snapshot.val();
                        if (result !== null && Object.keys(result).length > 0) {
                            const edition = result[Object.keys(result)[0]];
                            resolve(FirebaseEditionRepository.fromFirebaseEdition(edition));
                        } else {
                            resolve(null);
                        }
                    }
                });
        });
    }

    private static toFirebaseEdition(edition: Edition): FirebaseEdition {
        return {
            id: edition.id,
            token: edition.token,
            name: edition.name,
            handicapsSystem: edition.handicapsSystem,
            mode: edition.mode,
        };
    }

    private static fromFirebaseEdition(firebaseEdition: FirebaseEdition): Edition {
        const mode: RegattaMode = firebaseEdition.mode || RegattaMode.TWW;

        return {
            id: firebaseEdition.id,
            token: firebaseEdition.token,
            name: firebaseEdition.name,
            handicapsSystem:
                firebaseEdition.handicapsSystem === undefined ? '2019_Short' : firebaseEdition.handicapsSystem,
            mode: mode,
        };
    }
}
