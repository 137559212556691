import {Presenter} from '../../../support/with_presenter';
import {AuthProvider} from '../../../business/auth/auth_provider';
import {Edition} from '../../../../models/edition';
import {observable} from 'mobx';

export class TopBarPresenter implements Presenter {
    @observable public edition: Edition | null = null;
    private _authProvider: AuthProvider;

    constructor(authProvider: AuthProvider) {
        this._authProvider = authProvider;
    }

    public async mount(): Promise<void> {
        this.edition = await this._authProvider.edition();
    }

    public unmount(): void {
        /* Noop */
    }

    public async onLogoutClick() {
        await this._authProvider.logout();
        window.location.href = '/login';
    }
}
