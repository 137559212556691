import * as React from 'react';
import {Team} from '../../../models/team';
import {RecordedAs} from '../../../models/recorded_time';
import {HeatWithEdition} from '../../tracking/tracking_controller';
import {RegattaMode} from '../../../models/edition';
import {TimesPerTeam} from './tracking/times_per_team';
import {AllAtOnce} from './tracking/all_at_once';

interface OwnProps {
    recordedAs: RecordedAs;
    heat: HeatWithEdition;
    teams: Team[];
}

export const Tracking = ({recordedAs, teams, heat}: OwnProps) => {
    if (heat.edition.mode === RegattaMode.Sprint && recordedAs === RecordedAs.Start) {
        return <AllAtOnce recordedAs={recordedAs} heat={heat} teams={teams} />;
    }

    return <TimesPerTeam recordedAs={recordedAs} heat={heat} teams={teams} />;
};
