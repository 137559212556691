import {Category, Team} from '../../../../models/team';
import {Tag} from '../../../../models/tag';
import {Grouping} from '../grouping';
import {ResultRequest} from '../../../../models/result_request';
import {HeatRepository} from '../../../persistence/heat/heat_repository';
import {first} from 'rxjs/operators';
import {ResultGrouper} from '../result_builder';

export class DefaultResultGrouper implements ResultGrouper {
    constructor(private heatRepository: HeatRepository) {}

    public async group(resultRequest: ResultRequest, teams: Team[]): Promise<Array<{name: string; teams: Team[]}>> {
        switch (resultRequest.grouping) {
            case Grouping.Tags:
                return this.groupByTags(resultRequest.tags!, teams);
            case Grouping.Category:
                return this.groupByCategory(resultRequest.categories!, teams);
            case Grouping.Heat:
                return this.groupByHeat(resultRequest, teams);
            case Grouping.Overall:
                return this.groupOverall(resultRequest, teams);
        }
    }

    private groupByTags(tags: Tag[], teams: Team[]): Array<{name: string; teams: Team[]}> {
        return teams
            .reduce((p: Tag[], team: Team) => {
                team.tags.forEach(tag => {
                    if (!this.containsTag(p, tag) && this.containsTag(tags, tag)) {
                        p.push(tag);
                    }
                });

                return p;
            }, [])
            .map(tag => {
                return {
                    name: tag.name,
                    teams: teams.filter(team => this.containsTag(team.tags, tag)),
                };
            });
    }

    private containsTag(tags: Tag[], tag: Tag): boolean {
        return tags.some(t => t.name === tag.name);
    }

    private groupByCategory(categories: Category[], teams: Team[]): Array<{name: string; teams: Team[]}> {
        return teams
            .reduce((p: Category[], team: Team) => {
                if (p.indexOf(team.category) === -1 && categories.indexOf(team.category) !== -1) {
                    p.push(team.category);
                }
                return p;
            }, [])
            .map(category => {
                return {
                    name: category,
                    teams: teams.filter(team => team.category === category),
                };
            });
    }

    private async groupByHeat(
        resultsRequest: ResultRequest,
        teams: Team[],
    ): Promise<Array<{name: string; teams: Team[]}>> {
        const heats = await this.heatRepository
            .findByEditionId(resultsRequest.editionId)
            .pipe(first())
            .toPromise();

        return heats
            .filter(heat => resultsRequest.heatIds !== undefined && resultsRequest.heatIds.indexOf(heat.id) !== -1)
            .map(heat => ({
                name: heat.name,
                teams: teams.filter(team => team.heatId === heat.id),
            }));
    }

    private async groupOverall(resultRequest: ResultRequest, teams: Team[]) {
        return [
            {
                name: resultRequest.name,
                teams: teams,
            },
        ];
    }
}
