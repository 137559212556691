import {filter, first, flatMap, map, startWith, tap} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {TemporaryRecordedTimesManager} from '../temporary_recorded_times_manager';
import {RecordedTime} from '../../../models/recorded_time';
import {Observable, Subject} from 'rxjs';
import {RecordTimeRequest} from '../../tracking/models/record_time_request';
import {ServerTimeProvider} from '../../../server_time/server_time_provider';

export class LocalRecorder {
    private recordedTimeSubject = new Subject<RecordedTime[]>();

    constructor(
        private heatId: string,
        private temporaryRecordedTimesManager: TemporaryRecordedTimesManager,
        private serverTimeProvider: ServerTimeProvider,
    ) {}

    public allRecordedTimes(): Observable<RecordedTime[]> {
        return this.temporaryRecordedTimesManager.byHeatId(this.heatId);
    }

    public recordedTimes(teamId: string): Observable<RecordedTime | null> {
        return this.temporaryRecordedTimesManager.byHeatId(this.heatId).pipe(
            map(
                recordedTimes =>
                    recordedTimes
                        .reverse() //Make sure we have the last recorded
                        .find(temporaryRecordedTime => temporaryRecordedTime.context.teamId === teamId) || null,
            ),
            flatMap(recordedTime =>
                this.recordedTimeSubject.pipe(
                    map(newRecordedTimes =>
                        newRecordedTimes.find(newRecordedTime => newRecordedTime.context.teamId === teamId),
                    ),
                    filter(
                        (newRecordedTime: RecordedTime | undefined): newRecordedTime is RecordedTime =>
                            newRecordedTime !== undefined,
                    ),
                    startWith(recordedTime),
                ),
            ),
        );
    }

    public async record(recordTimeRequest: RecordTimeRequest): Promise<RecordedTime[]> {
        return await this.serverTimeProvider
            .getTime()
            .pipe(
                first(),
                map<number, RecordedTime[]>(timeMs => {
                    if (recordTimeRequest.teams.length === 0) {
                        return [
                            {
                                id: uuid(),
                                heatId: recordTimeRequest.heatId,
                                synced: false,
                                timeMs: timeMs,
                                context: {
                                    teamId: null,
                                    recordedAs: recordTimeRequest.recordedAs,
                                },
                            },
                        ];
                    }
                    return recordTimeRequest.teams.map(team => ({
                        id: uuid(),
                        heatId: recordTimeRequest.heatId,
                        synced: false,
                        timeMs: timeMs,
                        context: {
                            teamId: team.id,
                            recordedAs: recordTimeRequest.recordedAs,
                        },
                    }));
                }),
                flatMap(recordedTimes => this.temporaryRecordedTimesManager.store(recordedTimes)),
                tap(recordedTimes => this.recordedTimeSubject.next(recordedTimes)),
            )
            .toPromise();
    }

    public async markSynced(synchronizedRecordedTimes: RecordedTime[]): Promise<void> {
        await Promise.all(
            synchronizedRecordedTimes.map(synchronizedRecordedTime =>
                this.temporaryRecordedTimesManager.destroy(synchronizedRecordedTime),
            ),
        );
    }

    public unsynced(): Promise<RecordedTime[]> {
        return this.temporaryRecordedTimesManager
            .byHeatId(this.heatId)
            .pipe(
                first(),
                map(recordedTimes => recordedTimes.filter(recordedTime => !recordedTime.synced)),
            )
            .toPromise();
    }
}
