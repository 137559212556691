import {Presenter} from '../../../../support/with_presenter';
import {observable} from 'mobx';
import {ParticipantRepository} from '../../../../persistence/participant/participant_repository';
import {TeamRepository} from '../../../../persistence/team/team_repository';
import {Team} from '../../../../../models/team';
import {Subscription} from 'rxjs';
import {Gender} from '../../../../../models/participant';

export class AddParticipantPresenter implements Presenter {
    @observable public loading: boolean = true;
    @observable public name: string = '';
    @observable public birthYear: string = '';
    @observable public membershipCode: string = '';
    @observable public clubCode: string = '';
    @observable public team: Team | null | undefined = undefined;
    @observable public gender: Gender = 'Unknown';
    private _participantRepository: ParticipantRepository;
    private _teamRepository: TeamRepository;
    private _teamId: string;
    private _subscription: Subscription | undefined;

    constructor(teamId: string, teamRepository: TeamRepository, participantRepository: ParticipantRepository) {
        this._teamId = teamId;
        this._teamRepository = teamRepository;
        this._participantRepository = participantRepository;
    }

    public async mount(): Promise<void> {
        this._subscription = this._teamRepository.findById(this._teamId).subscribe((team: Team | null) => {
            this.team = team;
            this.loading = false;
        });
    }

    public unmount(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    public onNameChange(name: string) {
        this.name = name;
    }

    public onBirthYearChange(birthYear: string) {
        this.birthYear = birthYear;
    }

    public onMembershipCodeChange(membershipCode: string) {
        this.membershipCode = membershipCode;
    }

    public onClubCodeChange(clubCode: string) {
        this.clubCode = clubCode;
    }

    public onGenderChange(gender: Gender) {
        this.gender = gender;
    }

    public async onSubmitClicked() {
        if (this.team === null || this.team === undefined) {
            throw new Error('Invalid state, should not be able to click submit without a team');
        }
        this.loading = true;
        try {
            await this._participantRepository.create(
                this.team.editionId,
                this.team.id,
                this.name,
                parseInt(this.birthYear, 10),
                this.membershipCode,
                this.clubCode,
                this.gender,
            );
            window.location.href = '/edition/team/' + this.team.id;
        } finally {
            this.loading = false;
        }
    }
}
