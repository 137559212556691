import {Presenter} from '../../../support/with_presenter';
import {Edition} from '../../../../models/edition';
import {action, computed, observable} from 'mobx';
import {Team} from '../../../../models/team';
import {TeamRepository} from '../../../persistence/team/team_repository';
import {HeatRepository} from '../../../persistence/heat/heat_repository';
import {Heat} from '../../../../models/heat';
import {CompositeSubscription} from '../../../support/composit_subscription';
import {TeamManagePresenter} from './team_manage/team_manage_presenter';
import {TeamsManagementInteractor, TeamState} from '../../../business/manage/teams_management_interactor';
import {ParticipantRepository} from '../../../persistence/participant/participant_repository';
import {Gender, Participant} from '../../../../models/participant';
import {getStrokePosition} from '../../../support/importing/boat_type_map';

export class ManageScreenPresenter implements Presenter {
    @observable public teams: Team[] = [];
    @observable public heats: Heat[] = [];
    @observable public participants: Participant[] = [];
    @observable public teamPresenters: TeamManagePresenter[] = [];
    @observable public teamStates: TeamState[] = [];

    @observable private _teamsLoaded: boolean = false;
    @observable private _heatsLoaded: boolean = false;
    @observable private _participantsLoaded: boolean = false;

    @computed
    public get loading(): boolean {
        return !this._teamsLoaded || !this._heatsLoaded || !this._participantsLoaded;
    }

    private readonly _edition: Edition;
    private readonly _teamRepository: TeamRepository;
    private readonly _heatRepository: HeatRepository;
    private readonly _participantRepository: ParticipantRepository;
    private readonly _teamsManagementInteractor: TeamsManagementInteractor;
    private _subscriptions = new CompositeSubscription();
    private participantById: Map<string, Participant> = new Map();

    constructor(
        edition: Edition,
        teamRepository: TeamRepository,
        heatRepository: HeatRepository,
        participantRepository: ParticipantRepository,
        teamsManagementInteractor: TeamsManagementInteractor,
    ) {
        this._edition = edition;
        this._teamRepository = teamRepository;
        this._heatRepository = heatRepository;
        this._participantRepository = participantRepository;
        this._teamsManagementInteractor = teamsManagementInteractor;
    }

    @action
    private buildTeamStates(): void {
        if (!this.loading) {
            const heatMap: Map<string, Heat> = new Map();
            this.heats.forEach(heat => {
                heatMap.set(heat.id, heat);
            });

            this.teamStates = this.teams.map((team: Team, index: number) => {
                const heat = team.heatId === null ? null : heatMap.get(team.heatId);
                return {
                    heat: heat === undefined ? null : heat,
                    number: team.number,
                    remarks: [],
                    team: team,
                    changed: false,
                    tags: team.tags,
                };
            });
            this._teamsLoaded = true;

            this.participantById.clear();
            this.participants.forEach(participant => {
                this.participantById.set(participant.id, participant);
            });

            this.runValidation();
        }
    }

    public mount(): void {
        this._subscriptions.add(
            this._heatRepository.findByEditionId(this._edition.id).subscribe(heats => {
                this.heats = heats;
                this._heatsLoaded = true;
                this.buildTeamStates();
            }),
        );
        this._subscriptions.add(
            this._teamRepository.findByEditionId(this._edition.id).subscribe(teams => {
                this.teams = teams;
                this._teamsLoaded = true;
                this.buildTeamStates();
            }),
        );
        this._subscriptions.add(
            this._participantRepository.findByEditionId(this._edition.id).subscribe(participants => {
                this.participants = participants;
                this._participantsLoaded = true;
                this.buildTeamStates();
            }),
        );
    }

    public unmount(): void {
        this._subscriptions.clear();
    }

    public runValidation(): void {
        this._teamsManagementInteractor.runValidation(this.teamStates, this.participantById);
    }

    public async saveLocalState() {
        await Promise.all(
            this.teamStates.map((teamState: TeamState) => {
                const team: Team = teamState.team;
                if (teamState.heat !== null) {
                    team.heatId = teamState.heat.id;
                }
                if (teamState.number) {
                    team.number = teamState.number;
                }
                team.tags = teamState.tags;
                teamState.changed = false;
                return this._teamRepository.update(team);
            }),
        );
    }

    public reset(): void {
        this._teamsManagementInteractor.reset(this.teamStates, this.participantById);
    }

    public autoAssignHeat(): void {
        this._teamsManagementInteractor.autoAssignHeat(this.teamStates, this.heats, this.participantById);
    }

    public autoAssignNumber(): void {
        this._teamsManagementInteractor.autoAssignNumber(this.teamStates, this.heats, 1000, this.participantById);
    }

    public teamOrder(a: TeamState, b: TeamState): number {
        if (a.heat !== null && b.heat !== null) {
            if (a.heat.number > b.heat.number) {
                return 1;
            } else if (a.heat.number < b.heat.number) {
                return -1;
            } else {
                if (a.number !== null && b.number !== null) {
                    return a.number - b.number;
                } else if (a.number === null) {
                    return 1;
                } else {
                    return -1;
                }
            }
        } else if (a.heat === null) {
            return 1;
        } else {
            return -1;
        }
    }

    public participantOrder(a: Participant, b: Participant): number {
        return a.name.localeCompare(b.name);
    }

    public getHeatWithId(heatId: string | null): Heat | undefined {
        return this.heats.filter(heat => heat.id === heatId).shift();
    }

    public onGenderChange(participant: Participant, value: Gender): void {
        this._participantRepository.update({...participant, gender: value});
    }

    public getFirstParticipant(team: Team): Participant | undefined {
        const position = getStrokePosition(team.category);
        switch (position) {
            case 8:
                return this.participants.filter(participant => participant.id === team.rower2Id).shift();
            case 4:
                return this.participants.filter(participant => participant.id === team.rower2Id).shift();
            case 3:
                return this.participants.filter(participant => participant.id === team.rower2Id).shift();
            case 2:
                return this.participants.filter(participant => participant.id === team.rower2Id).shift();
            case 1:
            default:
                return this.participants.filter(participant => participant.id === team.rower1Id).shift();
        }
    }
}
