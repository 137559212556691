import {Presenter} from '../../../support/with_presenter';
import {observable} from 'mobx';
import {Tag} from '../../../../models/tag';
import {Category, Team} from '../../../../models/team';
import {CompositeSubscription} from '../../../support/composit_subscription';
import {TeamRepository} from '../../../persistence/team/team_repository';
import {ResultRequest} from '../../../../models/result_request';
import {ResultMode} from '../../../business/results/result_mode';
import {Grouping} from '../../../business/results/grouping';
import {Edition} from '../../../../models/edition';
import {Heat} from '../../../../models/heat';
import {HeatRepository} from '../../../persistence/heat/heat_repository';

export class ResultRequestFormPresenter implements Presenter {
    @observable
    public tags: Tag[] = [];

    @observable
    public categories: Category[] = [];

    @observable
    public heats: Heat[] = [];

    private subscriptions = new CompositeSubscription();

    constructor(
        private resultRequest: ResultRequest,
        private onChange: (resultRequest: ResultRequest) => void,
        private edition: Edition,
        private teamRepository: TeamRepository,
        private heatRepository: HeatRepository,
    ) {}

    public mount(): void {
        this.subscriptions.add(
            this.teamRepository.findByEditionId(this.edition.id).subscribe(teams => {
                this.categories = this.categoriesFromTeams(teams);
                this.tags = this.tagsFromTeams(teams);
            }),
        );
        this.subscriptions.add(
            this.heatRepository.findByEditionId(this.edition.id).subscribe(heats => {
                this.heats = heats;
            }),
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public onNameChange(value: ResultMode) {
        this.resultRequest.name = value;
        this.onChange(this.resultRequest);
    }

    public onIncludeInNarrowcastingChange(checked: boolean) {
        this.resultRequest.includeInNarrowcasting = checked;
        this.onChange(this.resultRequest);
    }

    public onModeChange(value: ResultMode) {
        this.resultRequest.mode = value;
        this.onChange(this.resultRequest);
    }

    public onGroupingChange(value: Grouping) {
        this.resultRequest.grouping = value;
        this.onChange(this.resultRequest);
    }

    public onCategoryCheck(category: Category, checked: boolean) {
        const categories = this.resultRequest.categories || [];
        if (checked) {
            this.resultRequest.categories = [...categories, category];
        } else {
            this.resultRequest.categories = [...categories.filter(c => c !== category)];
        }
        this.onChange(this.resultRequest);
    }

    public onHeatCheck(heat: Heat, checked: boolean) {
        const heatIds = this.resultRequest.heatIds || [];
        if (checked) {
            this.resultRequest.heatIds = [...heatIds, heat.id];
        } else {
            this.resultRequest.heatIds = [...heatIds.filter(c => c !== heat.id)];
        }
        this.onChange(this.resultRequest);
    }

    public onTagCheck(tag: Tag, checked: boolean) {
        const tags = this.resultRequest.tags || [];
        if (checked) {
            this.resultRequest.tags = [...tags, tag];
        } else {
            this.resultRequest.tags = [...tags.filter(t => t.name !== tag.name)];
        }
        this.onChange(this.resultRequest);
    }

    private categoriesFromTeams(teams: Team[]): Category[] {
        return teams.reduce((categories: Category[], team: Team) => {
            if (categories.indexOf(team.category) === -1) {
                categories.push(team.category);
            }
            return categories;
        }, []);
    }

    private tagsFromTeams(teams: Team[]): Tag[] {
        return teams.reduce((tags: Tag[], team: Team) => {
            team.tags.forEach(tag => {
                if (!tags.some(t => t.name === tag.name)) {
                    tags.push(tag);
                }
            });

            return tags;
        }, []);
    }
}
