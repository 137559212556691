import * as React from 'react';
import {observer} from 'mobx-react';

interface OwnProps {
    progress: number;
}

@observer
export class Progress extends React.Component<OwnProps> {
    public render() {
        return (
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 5,
                    backgroundColor: '#000',
                    width: this.props.progress + '%',
                }}
            />
        );
    }
}
