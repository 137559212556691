import {Presenter} from '../../../../support/with_presenter';
import {HeatRepository} from '../../../../persistence/heat/heat_repository';
import {Edition} from '../../../../../models/edition';
import {Heat} from '../../../../../models/heat';
import {observable} from 'mobx';
import {Subscription} from 'rxjs';

export class HeatsPresenter implements Presenter {
    @observable public loading: boolean = true;
    @observable public heats: Heat[] = [];
    private _edition: Edition;
    private _heatRepository: HeatRepository;
    private _subscription: Subscription | undefined;

    constructor(edition: Edition, heatRepository: HeatRepository) {
        this._edition = edition;
        this._heatRepository = heatRepository;
    }

    public mount(): void {
        this._subscription = this._heatRepository
            .findByEditionId(this._edition.id)
            .subscribe(heats => (this.heats = heats));
    }

    public unmount(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
}
