import {HandicapTable} from '../handicap_table';

export class Handicap2019Long extends HandicapTable {
    protected create(): void {
        this.handicaps.set(10, {male: 0.715, female: 0.64});
        this.handicaps.set(11, {male: 0.765, female: 0.685});
        this.handicaps.set(12, {male: 0.814, female: 0.734});
        this.handicaps.set(13, {male: 0.87, female: 0.783});
        this.handicaps.set(14, {male: 0.911, female: 0.82});
        this.handicaps.set(15, {male: 0.937, female: 0.843});
        this.handicaps.set(16, {male: 0.953, female: 0.858});
        this.handicaps.set(17, {male: 0.964, female: 0.868});
        this.handicaps.set(18, {male: 0.972, female: 0.875});
        this.handicaps.set(19, {male: 0.981, female: 0.883});
        this.handicaps.set(20, {male: 0.988, female: 0.889});
        this.handicaps.set(21, {male: 0.994, female: 0.895});
        this.handicaps.set(22, {male: 0.999, female: 0.899});
        this.handicaps.set(23, {male: 1.0, female: 0.9});
        this.handicaps.set(24, {male: 1.0, female: 0.9});
        this.handicaps.set(25, {male: 1.0, female: 0.9});
        this.handicaps.set(26, {male: 1.0, female: 0.9});
        this.handicaps.set(27, {male: 0.997, female: 0.897});
        this.handicaps.set(28, {male: 0.996, female: 0.896});
        this.handicaps.set(29, {male: 0.994, female: 0.895});
        this.handicaps.set(30, {male: 0.993, female: 0.894});
        this.handicaps.set(31, {male: 0.991, female: 0.892});
        this.handicaps.set(32, {male: 0.99, female: 0.891});
        this.handicaps.set(33, {male: 0.988, female: 0.889});
        this.handicaps.set(34, {male: 0.986, female: 0.887});
        this.handicaps.set(35, {male: 0.985, female: 0.887});
        this.handicaps.set(36, {male: 0.983, female: 0.885});
        this.handicaps.set(37, {male: 0.981, female: 0.883});
        this.handicaps.set(38, {male: 0.979, female: 0.881});
        this.handicaps.set(39, {male: 0.977, female: 0.879});
        this.handicaps.set(40, {male: 0.975, female: 0.878});
        this.handicaps.set(41, {male: 0.973, female: 0.876});
        this.handicaps.set(42, {male: 0.97, female: 0.873});
        this.handicaps.set(43, {male: 0.968, female: 0.871});
        this.handicaps.set(44, {male: 0.966, female: 0.869});
        this.handicaps.set(45, {male: 0.964, female: 0.868});
        this.handicaps.set(46, {male: 0.961, female: 0.865});
        this.handicaps.set(47, {male: 0.959, female: 0.863});
        this.handicaps.set(48, {male: 0.956, female: 0.86});
        this.handicaps.set(49, {male: 0.953, female: 0.858});
        this.handicaps.set(50, {male: 0.95, female: 0.855});
        this.handicaps.set(51, {male: 0.947, female: 0.852});
        this.handicaps.set(52, {male: 0.944, female: 0.85});
        this.handicaps.set(53, {male: 0.941, female: 0.847});
        this.handicaps.set(54, {male: 0.938, female: 0.844});
        this.handicaps.set(55, {male: 0.935, female: 0.842});
        this.handicaps.set(56, {male: 0.931, female: 0.838});
        this.handicaps.set(57, {male: 0.927, female: 0.834});
        this.handicaps.set(58, {male: 0.923, female: 0.831});
        this.handicaps.set(59, {male: 0.919, female: 0.827});
        this.handicaps.set(60, {male: 0.915, female: 0.824});
        this.handicaps.set(61, {male: 0.911, female: 0.82});
        this.handicaps.set(62, {male: 0.906, female: 0.815});
        this.handicaps.set(63, {male: 0.901, female: 0.811});
        this.handicaps.set(64, {male: 0.896, female: 0.806});
        this.handicaps.set(65, {male: 0.89, female: 0.801});
        this.handicaps.set(66, {male: 0.884, female: 0.796});
        this.handicaps.set(67, {male: 0.878, female: 0.79});
        this.handicaps.set(68, {male: 0.872, female: 0.785});
        this.handicaps.set(69, {male: 0.865, female: 0.779});
        this.handicaps.set(70, {male: 0.857, female: 0.771});
        this.handicaps.set(71, {male: 0.85, female: 0.765});
        this.handicaps.set(72, {male: 0.842, female: 0.758});
        this.handicaps.set(73, {male: 0.833, female: 0.75});
        this.handicaps.set(74, {male: 0.824, female: 0.742});
        this.handicaps.set(75, {male: 0.814, female: 0.733});
        this.handicaps.set(76, {male: 0.804, female: 0.724});
        this.handicaps.set(77, {male: 0.793, female: 0.714});
        this.handicaps.set(78, {male: 0.782, female: 0.704});
        this.handicaps.set(79, {male: 0.77, female: 0.693});
        this.handicaps.set(80, {male: 0.757, female: 0.681});
        this.handicaps.set(81, {male: 0.743, female: 0.669});
        this.handicaps.set(82, {male: 0.729, female: 0.656});
        this.handicaps.set(83, {male: 0.714, female: 0.643});
        this.handicaps.set(84, {male: 0.698, female: 0.628});
        this.handicaps.set(85, {male: 0.681, female: 0.613});
        this.handicaps.set(86, {male: 0.663, female: 0.597});
        this.handicaps.set(87, {male: 0.645, female: 0.581});
        this.handicaps.set(88, {male: 0.625, female: 0.563});
        this.handicaps.set(89, {male: 0.604, female: 0.544});
        this.handicaps.set(90, {male: 0.582, female: 0.524});
        this.handicaps.set(91, {male: 0.559, female: 0.503});
    }
}
